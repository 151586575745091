import styled from 'styled-components'
import { ScreenSize, useScreenSize } from '@parafin/utils'
import Logo from '@/src/ui/components/Logo'
import { Link } from '@/src/ui/components/Link'
import useTheme from '@/src/ui/theme'
import { RouteName } from '@/src/routes/RouteName'
import { Tab } from '@/src/ui/components/Tab'
import { STATSIG_GATES } from '@/src/providers/experiment'
import { useEffect, useRef } from 'react'
import {
  OrganizationDropdown,
  pageIsOrgSwitcherEnabled,
} from '../generic/PartnerSwitcher'
import { useCore } from '@/src/providers/core'
import { useLocation } from 'react-router-dom'
import { Icon } from '@/src/ui/components/Icon'
import { FlexCenter } from '@/src/ui/components/Flex'
import { useGate } from '@parafin/experimentation'
import { Badge } from '../ui/Badge'

type SidebarProps = {
  show: boolean
  close: () => void
}

export const Sidebar = ({ show, close }: SidebarProps) => {
  const size = useScreenSize()
  const { theme } = useTheme()
  const showPlayground = useGate(STATSIG_GATES.showPlayground)
  const { partners, partner, setPartnerId, organization } = useCore()
  const { pathname } = useLocation()
  const wrapperRef = useRef(null)
  useOutsideClick(wrapperRef, close)

  return (
    <SidebarWrapper ref={wrapperRef} show={show}>
      <FlexCenter>
        <LogoLink id="sidebar-analytics" to={RouteName.ANALYTICS}>
          <Logo data-testid="partner-logo" color={theme.colors.black} />
        </LogoLink>
        {size !== ScreenSize.EXTRA_LARGE && (
          <Icon
            onClick={close}
            type="close"
            size="16"
            padding="16px"
            color="black70"
            hoverColor="black90"
          />
        )}
      </FlexCenter>
      {organization !== undefined && (
        <PartnerDropdownOrLogo>
          <DropdownWrapper>
            <OrganizationDropdown
              partners={partners}
              organization={organization}
              partner={partner}
              setPartnerId={(id) => {
                setPartnerId(id)
              }}
              enabled={pageIsOrgSwitcherEnabled(pathname)}
            />
          </DropdownWrapper>
        </PartnerDropdownOrLogo>
      )}
      <Tab
        label="Getting started"
        route={RouteName.HOME}
        icon="pass"
        exactRoute={true}
        onClick={close}
      />
      <Tab
        label="Analytics"
        route={RouteName.ANALYTICS}
        icon="pieChart"
        onClick={close}
      />
      <Tab
        label="Businesses"
        icon="briefcase"
        route={RouteName.BUSINESSES}
        exactRoute
        subTabs={[
          { label: 'Capital', route: `${RouteName.BUSINESSES_LIST}/capital` },
        ]}
        onClick={close}
      />
      <Tab
        label="Data share"
        route={RouteName.DATA_SHARE}
        icon="stack"
        onClick={close}
      />
      <Tab
        label="Marketing"
        route={`${RouteName.MARKETING}`}
        icon="envelope"
        onClick={close}
      />
      <Tab
        label="Reporting"
        route={RouteName.REPORTING}
        icon="pass"
        onClick={close}
      />
      <Tab
        label="Developer"
        route={`${RouteName.DEVELOPER}`}
        icon="developer"
        onClick={close}
      />
      {showPlayground && (
        <Tab label="Playground" route={`${RouteName.PLAYGROUND}`} icon="cube">
          <Badge variant={'yellow'} size="small">
            Sandbox
          </Badge>
        </Tab>
      )}
      <Tab
        label="Settings"
        route={`${RouteName.SETTINGS}`}
        icon="control"
        onClick={close}
      />
    </SidebarWrapper>
  )
}

const useOutsideClick = (ref: any, callback: () => void) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: { target: any }) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

const SidebarWrapper = styled.div<{ show: boolean }>`
  width: 240px;
  background: ${({ theme }) => theme.colors.mono200};
  transition: all 0.2s;
  min-height: 100vh;
  position: fixed;
  height: 100vh;
  scrollbar-width: none;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: ${({ theme }) => theme.screens.md}) {
    left: ${({ show }) => (show ? '0' : '-240px')};
    ${({ show, theme }) =>
      show && `filter: drop-shadow(0px 0px 10px ${theme.colors.black10});`}
    z-index: 100;
  }
`

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 192px;
  height: 80px;
  display: flex;
  padding-left: 18px;
`

const PartnerDropdownOrLogo = styled.div`
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DropdownWrapper = styled.div`
  width: calc(100% - 40px);
`
