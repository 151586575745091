import { Toaster as HotToaster } from 'react-hot-toast'
import useTheme from '@/src/ui/theme'

const Toaster = () => {
  const { theme } = useTheme()
  return (
    <HotToaster
      position="top-center"
      toastOptions={{
        duration: 3000,
        icon: null,
        style: {
          borderRadius: 8,
          fontSize: '14px',
          lineHeight: '1.3',
          background: theme.colors.accent200,
          boxShadow: 'none',
          color: theme.colors.accent100,
          marginRight: '14px',
        },
        error: {
          style: {
            background: theme.colors.negative100,
            color: theme.colors.accent500,
          },
        },
      }}
    />
  )
}

export default Toaster
