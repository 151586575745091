import * as ToggleGroup from '@radix-ui/react-toggle-group'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { Typography } from '../components/Typography'

export const ToggleRoot = forwardRef<
  ElementRef<typeof ToggleGroup.Root>,
  ComponentPropsWithoutRef<typeof ToggleGroup.Root>
>(({ children, ...props }, ref) => {
  return (
    <StyledToggleRoot ref={ref} {...props}>
      {children}
    </StyledToggleRoot>
  )
})

type ToggleItemVariant = 'small' | 'large'

type ToggleItemProps = ComponentPropsWithoutRef<typeof ToggleGroup.Item> & {
  label?: string
  variant?: ToggleItemVariant
  currentValue: string
}

export const ToggleItem = forwardRef<
  ElementRef<typeof ToggleGroup.Item>,
  ToggleItemProps
>(({ children, label, variant = 'small', currentValue, ...props }, ref) => {
  return (
    <StyledToggleItem ref={ref} variant={variant} {...props}>
      <Typography
        color={currentValue === props.value ? 'basil100' : 'black70'}
        weight={'regular'}
      >
        {label ?? props.value}
      </Typography>
    </StyledToggleItem>
  )
})

export const StyledToggleItem = styled(ToggleGroup.Item)<{
  variant?: ToggleItemVariant
}>`
  ${({ variant }) => variant === 'large' && LargeStyle}
  ${({ variant }) => variant === 'small' && SmallStyle}

  border: 1px solid ${({ theme }) => theme.colors.black30};
  background-color: ${({ theme }) => theme.colors.black30};

  &[data-state='on'] {
    border: 1px solid ${({ theme }) => theme.colors.black40};
    background-color: ${({ theme }) => theme.colors.black0};
  }
`

const SmallStyle = css`
  padding: 4px 8px;
  border-radius: 4px;
`

const LargeStyle = css`
  padding: 8px 12px;
  border-radius: 8px;
`

const StyledToggleRoot = styled(ToggleGroup.Root)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.black30};
`
