import { ReactNode } from 'react'
import { useSelect } from 'downshift'
import { Typography } from '@/src/ui/components/Typography'
import { Icon } from './Icon'
import styled, { css } from 'styled-components'
import { FlexColumn } from './Flex'

export type Item<T> = {
  key: T
  content: any
  isLabel?: boolean
  isDisabled?: boolean
  isPlaceholder?: boolean
}

type DropdownVariants = 'normal' | 'small' | 'flat' | 'field'

type SharedDropdownProps<T> = {
  label?: string
  items: Item<T>[]
  selectedItem?: Item<T> | null
  width?: string
  variant?: DropdownVariants
  hideChevron?: Boolean
  name: string
  disabled?: boolean
  icon?: ReactNode
}

export type DropdownProps<T> = {
  defaultItem?: Item<T>
  onChange?: (input: Item<T>) => void
} & SharedDropdownProps<T>

export const Dropdown = <T extends string>(props: DropdownProps<T>) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items: props.items,
    defaultSelectedItem: props.defaultItem,
    onSelectedItemChange: (changes) => {
      if (changes.selectedItem && props.onChange)
        props.onChange(changes.selectedItem)
    },
    ...(props.selectedItem ? { selectedItem: props.selectedItem } : {}),
  })

  return (
    <DropdownBase
      {...props}
      toggleButtonProps={getToggleButtonProps()}
      labelProps={getLabelProps()}
      menuProps={getMenuProps()}
      getItemProps={getItemProps}
      highlightedIndex={highlightedIndex}
      isOpen={isOpen}
      selectedItem={selectedItem}
    />
  )
}

type DropdownBaseProps<T> = {
  toggleButtonProps: any
  labelProps: any
  menuProps: any
  getItemProps: any
  fieldProps?: any
  isOpen: boolean
  highlightedIndex: number
} & SharedDropdownProps<T>

export const DropdownBase = <T extends string>(props: DropdownBaseProps<T>) => {
  return (
    <Shell>
      <FlexColumn gap="8px">
        {props.label && (
          <FieldLabel>
            <Typography color="black80">{props.label}</Typography>
          </FieldLabel>
        )}
        <Button
          {...props.toggleButtonProps}
          width={props.width || 'auto'}
          variant={props.variant || 'normal'}
          data-testid={props.name ? `dropdown-${props.name}` : undefined}
          disabled={props.disabled}
        >
          <Label
            {...props.labelProps}
            {...(props.fieldProps ? props.fieldProps : {})}
            variant={props.variant || 'normal'}
          >
            {props.icon && props.icon}
            <Typography
              color={props.selectedItem?.isPlaceholder ? 'black80' : 'black100'}
            >
              {props.selectedItem?.content}
            </Typography>
          </Label>
          {!props.hideChevron && (
            <ChevronWrapper open={props.isOpen}>
              <Icon type="downChevron" size="12px" padding="2px" />
            </ChevronWrapper>
          )}
        </Button>
      </FlexColumn>
      <Options open={props.isOpen} {...props.menuProps}>
        {props.items.map((item, index) => (
          <Item
            data-testid={`dropdown-item-${item.key}`}
            highlighted={props.highlightedIndex === index}
            key={`${item}${index}`}
            {...props.getItemProps({
              item,
              index,
              disabled: item.isLabel || item.isDisabled,
            })}
            hoverable={!(item.isLabel || item.isDisabled)}
          >
            <Typography weight={item.isLabel ? 'bold' : 'regular'}>
              {item.content}
            </Typography>
            {props.selectedItem?.key === item.key && (
              <Icon type="checkmark" size="12px" padding="2px" />
            )}
          </Item>
        ))}
      </Options>
    </Shell>
  )
}

const Shell = styled.div`
  display: inline-block;
  position: relative;
`

const FieldLabel = styled.label`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`

const Label = styled.label<{ variant: DropdownVariants }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ variant }) =>
    variant === 'small' || variant === 'flat' ? '4px' : '8px'};
  width: 100%;
`

const Button = styled.div<{
  width: string
  maxWidth?: string
  variant: DropdownVariants
  disabled?: boolean
}>`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-width: ${({ width }) => width};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  ${({ variant }) => variant === 'normal' && NormalButton};
  ${({ variant }) => variant === 'flat' && FlatButton};
  ${({ variant }) => variant === 'small' && SmallButton};
  ${({ variant }) => variant === 'field' && FieldButton}
  ${({ disabled }) => disabled && 'pointer-events: none;'}
  ${({ disabled, theme }) =>
    disabled && `background-color: ${theme.colors.black10};`}
  ${({ disabled, theme }) => disabled && `color: ${theme.colors.black30};`}
  
  cursor: pointer;
  * {
    cursor: pointer;
  }

  &:focus,
  &:hover {
    ${({ variant, theme }) =>
      variant !== 'field' && `background-color: ${theme.colors.black20};`}
  }
  ${({ variant, theme }) =>
    variant !== 'field' && `background-color: ${theme.colors.white};`}
`

const FieldButton = css`
  height: 38px;
  border-radius: 8px;
  padding: 12px;
  background-color: ${({ theme }) => theme.colors.black20};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  &:hover {
    ${({ theme }) => `border-color: ${theme.colors.black40};`}
  }
`

const FlatButton = css`
  padding: 10px 12px 10px 10px;
  border-radius: 8px;
  border: 1px solid transparent;
  width: 100%;
`

const NormalButton = css`
  padding: 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  border-radius: 8px;
`

const SmallButton = css`
  padding: 5px 8px;
  border: 1px solid ${({ theme }) => theme.colors.black30};
  border-radius: 8px;
`

const Options = styled.ul<{ open: boolean }>`
  position: absolute;
  top: calc(100% - 13px);
  flex-direction: column;
  max-height: 450px;
  overflow: scroll;
  border-radius: 8px;
  padding: 4px;
  box-shadow: 0px 4px 12px 0px #00000029;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.white};
  transition: opacity 0.2s, transform 0.2s;
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'all' : 'none')};
  transform: ${({ open }) => (open ? 'translateY(16px)' : 'none')};
`

const Item = styled.li<{ highlighted: boolean; hoverable?: boolean }>`
  min-width: 152px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  gap: 16px;
  background-color: ${({ theme, highlighted }) =>
    highlighted ? theme.colors.mono200 : theme.colors.white};
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    ${({ hoverable }) => hoverable && `cursor: pointer;`}
  }
`

const ChevronWrapper = styled.div<{ open: boolean }>`
  ${({ open }) => open && 'transform: rotate(180deg);'}
  transition: 0.3s;
`
