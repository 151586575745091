import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { Navigate } from '@/src/routes/Navigate'

import { SegmentProvider } from '@parafin/logging'
import { ErrorBoundary } from '@parafin/error-handling'
import { ThemeProvider } from '@/src/providers/theme'
import { QueryProvider } from '@/src/providers/queryProvider'
import { AuthProvider, AuthRootProvider } from '@/src/providers/auth'
import { CoreProvider } from '@/src/providers/core'
import { ExperimentProvider, STATSIG_GATES } from '@/src/providers/experiment'
import { InitializeServices } from '@/src/providers/initServices'
import { FrameProvider } from '@/src/providers/frame'

import PageNotFound from '@/src/ui/components/PageNotFound'
import { RouteName } from '@/src/routes/RouteName'
import Toaster from '@/src/ui/components/Toaster'
import { ErrorDisplay } from '@/src/components/generic/ErrorDisplay'
import { ENVIRONMENT, SEGMENT_KEY } from '@/parafin.config'
import { getSessionId } from '@parafin/utils'
import { useGate } from '@parafin/experimentation'

const Businesses = lazy(() => import('@/src/pages/businesses'))
const Analytics = lazy(() => import('@/src/pages/analytics'))
const Settings = lazy(() => import('@/src/pages/settings'))
const Developer = lazy(() => import('@/src/pages/developer'))
const Datashare = lazy(() => import('@/src/pages/datashare'))
const Marketing = lazy(() => import('@/src/pages/marketing'))
const Reporting = lazy(() => import('@/src/pages/reporting'))
const GetStarted = lazy(() => import('@/src/pages/get-started'))
const Playground = lazy(() => import('@/src/pages/playground'))

const Login = lazy(() => import('@/src/pages/login'))

const AuthenticatedRoutes = () => {
  const showPlayground = useGate(STATSIG_GATES.showPlayground)
  return (
    <AuthProvider>
      <CoreProvider>
        <InitializeServices />
        <FrameProvider>
          <Suspense fallback={<></>}>
            <Routes>
              <Route
                path={`${RouteName.ANALYTICS}/*`}
                element={<Analytics />}
              />
              <Route
                path={`${RouteName.BUSINESSES}/*`}
                element={<Businesses />}
              />
              <Route
                path="customers/*"
                element={
                  <Navigate
                    to={location.pathname.replace('customers', 'businesses')}
                  />
                }
              />
              <Route
                path={`${RouteName.DATA_SHARE}/*`}
                element={<Datashare />}
              />
              <Route path={`${RouteName.SETTINGS}/*`} element={<Settings />} />
              <Route
                path={`${RouteName.DEVELOPER}/*`}
                element={<Developer />}
              />
              <Route
                path={`${RouteName.MARKETING}/*`}
                element={<Marketing />}
              />
              <Route
                path={`${RouteName.REPORTING}/*`}
                element={<Reporting />}
              />
              {showPlayground && (
                <Route
                  path={`${RouteName.PLAYGROUND}/*`}
                  element={<Playground />}
                />
              )}
              <Route index element={<GetStarted />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </FrameProvider>
      </CoreProvider>
    </AuthProvider>
  )
}

export const App = () => {
  return (
    <SegmentProvider
      segmentKey={SEGMENT_KEY}
      environment={ENVIRONMENT}
      sessionId={getSessionId()}
      appName="partner_dashboard"
    >
      <ErrorBoundary fallback={<ErrorDisplay />}>
        <ThemeProvider>
          <BrowserRouter>
            <QueryProvider>
              <AuthRootProvider>
                <ExperimentProvider>
                  <ScrollToTop />
                  <Routes>
                    <Route path={`${RouteName.LOGIN}/*`} element={<Login />} />
                    <Route path="*" element={<AuthenticatedRoutes />} />
                  </Routes>
                  <Toaster />
                </ExperimentProvider>
              </AuthRootProvider>
            </QueryProvider>
          </BrowserRouter>
        </ThemeProvider>
      </ErrorBoundary>
    </SegmentProvider>
  )
}

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
