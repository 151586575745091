import {
  useState,
  useContext,
  createContext,
  useEffect,
  ReactNode,
} from 'react'
import { Auth0Provider, IdToken as Auth0IdToken } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { decodeToken, isExpired } from 'react-jwt'
import { AUTH_URL, AUTH_ID, BASE_URL } from '@/parafin.config'
import { FullScreenLoading } from '@/src/ui/components/Loading'
import { ErrorDisplay } from '@/src/components/generic/ErrorDisplay'
import { SANDBOX_HEADER } from '@/src/providers/core'
import { STATSIG_GATES } from '@/src/providers/experiment'
import { PartnerUser, UserRole } from '@parafin/medici-api'
import { storage } from '@parafin/utils'
import { useGate, useStatsigUser } from '@parafin/experimentation'

type MediciIdToken = {
  aud: string
  authn_user_id: string
  user_id: string
  name?: string
  email: string
  role: UserRole
  fullstoryUserId: string
  metadata?: {
    platform_type: 'partner' | 'organization'
    organization_id?: string
    partner_id?: string
  }

  exp: number
  iss: string
}

type AuthContextMetadataT = {
  organization_id: string | undefined
  partner_id: string | undefined
  platform_type: 'partner' | 'organization'
  role: UserRole
  userId: string
}

type IdToken = {
  metadata: AuthContextMetadataT
  user: PartnerUser
  fullstoryUserId: string
  __raw: string
  name?: string
  email?: string
  aud?: string
  iss?: string
}

type AuthContextType = {
  metadata: AuthContextMetadataT
  user: PartnerUser
  token: string
  authHeader: string
  claims: IdToken
  queryOptions: (
    sandbox: boolean,
    queryKey?: any
  ) => {
    axios: {
      baseURL: string
      headers: { authorization: string }
    }
    query?: {
      queryKey: string[]
    }
  }
  logout: () => void
}

type AuthRootContextT = {
  mediciToken: string | null
  isAuthenticated: boolean
  setAccessToken?: (token: string) => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)
const AuthRootContext = createContext<AuthRootContextT | undefined>(undefined)

export const AuthRootProvider = ({ children }: { children: ReactNode }) => {
  const search = new URLSearchParams(window.location.search)
  const syntheticsToken = search.get('synthetics_token')

  const onRedirect = ({ returnTo }: any) => {
    history.pushState(null, '', returnTo)
  }

  const authHook = useFetchAuth(syntheticsToken)

  return (
    <Auth0Provider
      domain={AUTH_URL}
      clientId={AUTH_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirect}
    >
      <AuthRootContext.Provider
        value={{
          mediciToken: syntheticsToken,
          isAuthenticated: authHook.isAuthenticated,
          setAccessToken: authHook.setAccessToken,
        }}
      >
        {children}
      </AuthRootContext.Provider>
    </Auth0Provider>
  )
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { mediciToken } = useAuthRoot()
  const {
    token,
    isLoading,
    isError,
    logout,
    loginWithRedirect,
    shouldReauthenticate,
  } = useFetchAuth(mediciToken)

  const { updateUserAsync } = useStatsigUser()

  useEffect(() => {
    if (shouldReauthenticate) {
      loginWithRedirect({
        appState: { returnTo: window.location.pathname },
      })
    }
  }, [shouldReauthenticate])

  useEffect(() => {
    updateUserAsync({
      userID: token?.user?.email,
    })
  }, [token])

  if (isLoading || !token) return <FullScreenLoading />
  if (isError) return <ErrorDisplay />

  const authHeader = `Bearer ${token.__raw}`
  // this needs to be its own hook
  const queryOptions = (sandbox: boolean, queryKey?: string[]) => ({
    axios: {
      baseURL: BASE_URL,
      headers: {
        ...{ authorization: authHeader },
        ...(sandbox ? { [SANDBOX_HEADER]: '1' } : {}),
      },
      paramsSerializer: {
        indexes: null,
      },
    },
    ...(queryKey
      ? {
          query: {
            queryKey,
          },
        }
      : {}),
  })

  return (
    <AuthContext.Provider
      value={{
        metadata: token.metadata,
        user: token.user,
        token: token.__raw,
        authHeader,
        claims: token,
        queryOptions,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) throw new Error('Not in AuthProvider')

  return context
}

export const useAuthRoot = (): AuthRootContextT => {
  const context = useContext(AuthRootContext)
  if (context === undefined) throw new Error('Not in AuthRootProvider')
  return context
}

type FetchAuthHookT = {
  token: IdToken | null
  isLoading: boolean
  isError: boolean
  isAuthenticated: boolean
  loginWithRedirect: (params: LoginWithRedirectParams) => void
  shouldReauthenticate: boolean
  setAccessToken?: (token: string) => void
  logout: () => void
}

const useFetchAuth = (syntheticsToken: string | null): FetchAuthHookT => {
  const urlToken = getTokenFromUrl()

  const enableMediciAuth =
    useGate(STATSIG_GATES.enableMediciLogin) || !!syntheticsToken

  const auth0Control = useAuth0AuthWrapper()
  const mediciAuthControl = useMediciAuth(syntheticsToken)

  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    getIdTokenClaims,
    logout,
    setAccessToken,
  } = (() => {
    if (enableMediciAuth && !auth0Control.isAuthenticated) {
      return mediciAuthControl
    }
    return auth0Control
  })()

  const authQuery = useQuery({
    queryKey: ['auth-states'],
    queryFn: async () => {
      const claims = await getIdTokenClaims()
      if (!claims) {
        document.location.reload()
        return null
      }
      return claims
    },
    enabled: isAuthenticated,
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60 * 12,
    refetchOnWindowFocus: true,
  })

  return {
    token: urlToken ?? authQuery.data ?? null,
    isLoading: !urlToken && (isLoading || authQuery.isLoading),
    isError: !!error || !!authQuery.error,
    isAuthenticated,
    loginWithRedirect,
    setAccessToken,
    shouldReauthenticate: !urlToken && !isAuthenticated && !isLoading,
    logout: () => logout({ returnTo: window.location.origin }),
  }
}

type LoginWithRedirectParams = {
  appState?: { returnTo: string }
}

type AuthHook = {
  isLoading: boolean
  isAuthenticated: boolean
  error?: Error
  setAccessToken?: (token: string) => void
  getIdTokenClaims: () => Promise<IdToken | undefined>
  loginWithRedirect: (params: LoginWithRedirectParams) => void
  logout: (params: any) => void
}

const convertAuth0TokenToIdToken = (
  auth0Token: Auth0IdToken,
  rawToken: string
): IdToken => {
  const user_details = (auth0Token as any)[BASE_URL + '/user_authorization']
  const role = user_details?.app_metadata?.role
  const user = {
    name: auth0Token.name ?? '',
    email: auth0Token.email ?? '',
    role,
  }

  return {
    ...auth0Token,
    __raw: rawToken,
    user,
    metadata: user_details?.app_metadata,
    fullstoryUserId: auth0Token.sub,
  }
}

const useAuth0AuthWrapper = (): AuthHook => {
  const auth0Hook = useAuth0()
  return {
    isLoading: auth0Hook.isLoading,
    isAuthenticated: auth0Hook.isAuthenticated,
    error: auth0Hook.error,
    loginWithRedirect: (params: LoginWithRedirectParams) => {
      auth0Hook.loginWithRedirect(params)
    },
    getIdTokenClaims: async (): Promise<IdToken | undefined> => {
      const auth0Token = await auth0Hook.getIdTokenClaims()
      if (!auth0Token) return undefined
      return convertAuth0TokenToIdToken(auth0Token, auth0Token.__raw)
    },
    logout: (params: any) => {
      auth0Hook.logout(params)
    },
  }
}

const useMediciAuth = (syntheticsToken: string | null): AuthHook => {
  const mediciSessionStorageKey = 'auth.medici'

  const initialToken =
    storage.session.get(mediciSessionStorageKey) || syntheticsToken || undefined

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | undefined>()
  const [rawToken, setRawToken] = useState<string | undefined>(initialToken)

  const isAuthenticated = (() => {
    if (!rawToken) return false

    return !isExpired(rawToken)
  })()

  return {
    isLoading,
    isAuthenticated,
    error,
    setAccessToken: (token: string): void => {
      storage.session.set(mediciSessionStorageKey, token)
      setRawToken(token)
    },
    loginWithRedirect: (params: LoginWithRedirectParams) => {
      window.location.href = `${window.location.origin}/login`
    },
    getIdTokenClaims: async (): Promise<IdToken | undefined> => {
      if (!rawToken) return undefined

      const decoded = decodeToken(rawToken) as MediciIdToken | null
      if (!decoded) return undefined

      const user = {
        name: decoded.name ?? '',
        email: decoded.email ?? '',
        role: decoded.role as UserRole,
      }

      const tokenMetadata = decoded.metadata || null
      const metadata: AuthContextMetadataT = {
        organization_id: tokenMetadata?.organization_id,
        partner_id: tokenMetadata?.partner_id,
        platform_type: tokenMetadata?.platform_type || 'partner',
        role: decoded.role,
        userId: decoded.user_id,
      }

      return {
        ...decoded,
        metadata,
        user,
        __raw: rawToken,
      }
    },
    logout: () => {
      setRawToken(undefined)
      storage.session.remove(mediciSessionStorageKey)
    },
  }
}

const getTokenFromUrl = (): IdToken | null => {
  const search = new URLSearchParams(window.location.search)
  const token = search.get('token')
  if (!token) return null
  const decoded = decodeToken(token) as Auth0IdToken | null
  const expired = isExpired(token)
  if (!decoded || expired) return null
  const userDetails = decoded[BASE_URL + '/user_authorization']
  const meta = userDetails?.app_metadata
  if (meta?.userId && meta?.role && decoded.aud === AUTH_ID) {
    return convertAuth0TokenToIdToken(decoded, token)
  } else {
    return null
  }
}
