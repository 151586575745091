import { useQuery as c, useMutation as i } from "@tanstack/react-query";
import o from "axios";
const ml = {
  cash_advance_offer_created: "cash_advance_offer_created",
  cash_advance_offer_accepted: "cash_advance_offer_accepted",
  cash_advance_top_up_eligible: "cash_advance_top_up_eligible",
  cash_advance_paid_off: "cash_advance_paid_off",
  cash_advance_closed: "cash_advance_closed",
  cash_advance_offer_closed: "cash_advance_offer_closed",
  flex_loan_offer_accepted: "flex_loan_offer_accepted",
  flex_loan_closed: "flex_loan_closed",
  flex_loan_offer_created: "flex_loan_offer_created",
  flex_loan_offer_closed: "flex_loan_offer_closed",
  order_updated: "order_updated",
  capital_product_offercreated: "capital_product_offer.created",
  capital_product_offerclosed: "capital_product_offer.closed",
  capital_productcreated: "capital_product.created",
  capital_productclosed: "capital_product.closed",
  capital_product_applicationupdated: "capital_product_application.updated",
  opt_in: "opt_in",
  fis_opt_in_merchant_account: "fis_opt_in_merchant_account",
  fis_opt_in_bank_account: "fis_opt_in_bank_account",
  wallet_applicationapproved: "wallet_application.approved",
  wallet_applicationdenied: "wallet_application.denied",
  wallet_accountactive: "wallet_account.active",
  wallet_accountinactive: "wallet_account.inactive",
  wallet_transactionsucceeded: "wallet_transaction.succeeded",
  wallet_transactionpending: "wallet_transaction.pending",
  wallet_transactioncanceled: "wallet_transaction.canceled",
  wallet_transactionarchived: "wallet_transaction.archived",
  wallet_transactionfailed: "wallet_transaction.failed"
}, ll = {
  normal: "normal",
  sync: "sync"
}, gl = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, ql = {
  enabled: "enabled",
  disabled: "disabled"
}, fl = {
  processing: "processing",
  completed: "completed",
  failed: "failed"
}, Ol = {
  active: "active",
  inactive: "inactive"
}, hl = {
  read: "read",
  unread: "unread"
}, Kl = {
  automatically_verified: "automatically_verified",
  pending_automatic_verification: "pending_automatic_verification",
  pending_manual_verification: "pending_manual_verification",
  manually_verified: "manually_verified",
  verification_expired: "verification_expired",
  verification_failed: "verification_failed",
  database_matched: "database_matched",
  database_insights_pass: "database_insights_pass",
  database_insights_pass_with_caution: "database_insights_pass_with_caution",
  database_insights_fail: "database_insights_fail"
}, Pl = {
  Error: "Error",
  Warning: "Warning"
}, bl = {
  admin: "admin",
  user: "user",
  controller: "controller"
}, kl = {
  pending_underwriting: "pending_underwriting",
  underwriting: "underwriting",
  failed: "failed",
  success: "success"
}, xl = {
  failed_plaid_auth: "failed_plaid_auth"
}, Ml = {
  offer: "offer",
  no_offer: "no_offer",
  manual_review: "manual_review"
}, Fl = {
  pending_kitchen_response: "pending_kitchen_response",
  pending_kitchen_underwriting: "pending_kitchen_underwriting",
  failed: "failed",
  success: "success"
}, Rl = {
  failed_plaid_auth: "failed_plaid_auth",
  no_plaid_dispersement_observed: "no_plaid_dispersement_observed"
}, Bl = {
  pst: "pst",
  est: "est",
  utc: "utc"
}, Cl = {
  terms_of_service: "terms_of_service",
  state_disclosure: "state_disclosure",
  net_top_up_itemization: "net_top_up_itemization"
}, El = {
  payout: "payout",
  balance_transaction: "balance_transaction"
}, Sl = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AS: "AS",
  GU: "GU",
  MP: "MP",
  PR: "PR",
  VI: "VI",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT"
}, vl = {
  mfa: "mfa",
  otp: "otp",
  account_recovery: "account_recovery"
}, Al = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly",
  term: "term"
}, Dl = {
  very_high: "very_high",
  high: "high",
  medium: "medium",
  low: "low"
}, Ul = {
  capital_product_plaid_bank_linking: "capital_product_plaid_bank_linking",
  capital_product_acceptance: "capital_product_acceptance",
  merchant_bank_card_transaction: "merchant_bank_card_transaction",
  merchant_bank_transfer: "merchant_bank_transfer"
}, Nl = {
  pending_response: "pending_response",
  completed: "completed"
}, Gl = {
  before_marketing: "before_marketing",
  after_sign_up: "after_sign_up"
}, Tl = {
  capital_product_offers_table: "capital_product_offers_table",
  capital_product_applications_table: "capital_product_applications_table",
  capital_products_table: "capital_products_table"
}, Il = {
  completed: "completed",
  in_progress: "in_progress",
  not_started: "not_started",
  failed: "failed",
  expired: "expired"
}, wl = {
  shopify: "shopify",
  amazon: "amazon",
  square: "square",
  ebay: "ebay",
  walmart: "walmart",
  etsy: "etsy",
  woo_commerce: "woo_commerce",
  quickbooks: "quickbooks",
  xero: "xero",
  net_suite: "net_suite",
  magneto: "magneto",
  freshbooks: "freshbooks",
  zohobooks: "zohobooks",
  clover: "clover",
  paypal: "paypal"
}, Ql = {
  payments: "payments",
  accounting: "accounting"
}, Ll = {
  pending: "pending",
  available: "available",
  disconnected: "disconnected",
  disabled: "disabled",
  link_error: "link_error",
  error: "error"
}, Wl = {
  duplicate: "duplicate",
  incorrect_amount: "incorrect_amount",
  incorrect_receiving_account: "incorrect_receiving_account",
  date_earlier_than_intended: "date_earlier_than_intended",
  date_later_than_intended: "date_later_than_intended"
}, Hl = {
  R01: "R01",
  R02: "R02",
  R03: "R03",
  R04: "R04",
  R05: "R05",
  R06: "R06",
  R07: "R07",
  R08: "R08",
  R09: "R09",
  R10: "R10",
  R11: "R11",
  R12: "R12",
  R13: "R13",
  R14: "R14",
  R15: "R15",
  R16: "R16",
  R17: "R17",
  R18: "R18",
  R19: "R19",
  R20: "R20",
  R21: "R21",
  R22: "R22",
  R23: "R23",
  R24: "R24",
  R25: "R25",
  R26: "R26",
  R27: "R27",
  R28: "R28",
  R29: "R29",
  R30: "R30",
  R31: "R31",
  R32: "R32",
  R33: "R33",
  R34: "R34",
  R35: "R35",
  R36: "R36",
  R37: "R37",
  R38: "R38",
  R39: "R39",
  R45: "R45",
  C01: "C01",
  C02: "C02",
  C03: "C03",
  C05: "C05",
  C06: "C06",
  C07: "C07",
  C08: "C08",
  C09: "C09",
  C13: "C13",
  C14: "C14",
  NUMBER_900: 900,
  NUMBER_901: 901,
  NUMBER_902: 902,
  NUMBER_903: 903,
  NUMBER_904: 904,
  NUMBER_905: 905,
  NUMBER_907: 907,
  NUMBER_908: 908,
  NUMBER_909: 909,
  NUMBER_910: 910,
  NUMBER_911: 911,
  NUMBER_912: 912,
  NUMBER_914: 914,
  NUMBER_915: 915,
  NUMBER_916: 916,
  NUMBER_917: 917,
  NUMBER_918: 918,
  NUMBER_919: 919,
  NUMBER_920: 920,
  NUMBER_921: 921,
  NUMBER_922: 922,
  NUMBER_990: 990
}, Vl = {
  prevent_business_onboarding: "prevent_business_onboarding",
  suspend_card_account: "suspend_card_account"
}, zl = {
  manual: "manual",
  auto_expiration: "auto_expiration",
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  lift_repeated_failed_nsf_repayments: "lift_repeated_failed_nsf_repayments",
  unverified_bank_account: "unverified_bank_account",
  lift_unverified_bank_account: "lift_unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  lift_recent_non_nsf_repayment_returns: "lift_recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  lift_first_repayment_returned_nsf: "lift_first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  lift_first_auto_repayment_returned_nsf: "lift_first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  lift_no_recent_sales: "lift_no_recent_sales",
  plaid_transaction_review: "plaid_transaction_review",
  generic_application_denial: "generic_application_denial"
}, $l = {
  merchant_behavior_adjustment: "merchant_behavior_adjustment",
  unverified_bank_account: "unverified_bank_account",
  recent_non_nsf_repayment_returns: "recent_non_nsf_repayment_returns",
  first_repayment_returned_nsf: "first_repayment_returned_nsf",
  first_auto_repayment_returned_nsf: "first_auto_repayment_returned_nsf",
  no_recent_sales: "no_recent_sales",
  repeated_failed_nsf_repayments: "repeated_failed_nsf_repayments",
  fraud: "fraud",
  bankruptcy: "bankruptcy",
  active_payment_plan_high_risk: "active_payment_plan_high_risk",
  payment_processor_transition: "payment_processor_transition",
  shared_email_across_platforms: "shared_email_across_platforms",
  bank_account_high_servicing_risk: "bank_account_high_servicing_risk",
  servicing_risk: "servicing_risk",
  generic_application_denial: "generic_application_denial"
}, Yl = {
  unverified_bank_account: "unverified_bank_account",
  repayment_returns: "repayment_returns",
  other: "other"
}, Xl = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, Zl = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, jl = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  manual: "manual",
  sales_based_adjustment: "sales_based_adjustment",
  minimum_amount: "minimum_amount"
}, Jl = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, tg = {
  complete: "complete",
  deferred: "deferred",
  failed: "failed",
  pending: "pending",
  refunded: "refunded",
  double_refunded: "double_refunded"
}, eg = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  manual: "manual",
  sales_based_adjustment: "sales_based_adjustment",
  minimum_amount: "minimum_amount"
}, ng = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly"
}, rg = {
  cx: "cx",
  pending_funding: "pending_funding",
  too_many_missed_repayments: "too_many_missed_repayments",
  administrative_ach_return: "administrative_ach_return",
  awaiting_retry_non_nsf_return: "awaiting_retry_non_nsf_return",
  pending_reversal_completion: "pending_reversal_completion"
}, sg = {
  gross_sales: "gross_sales",
  net_payouts: "net_payouts"
}, ag = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, og = {
  pending: "pending",
  approved: "approved",
  denied: "denied",
  manual_review: "manual_review"
}, ug = {
  other: "other",
  disbursements: "disbursements",
  serviceability: "serviceability"
}, ig = {
  mfa_phone_number: "mfa_phone_number",
  unverified_phone_number: "unverified_phone_number"
}, cg = {
  NUMBER_93: "93",
  NUMBER_355: "355",
  NUMBER_213: "213",
  NUMBER_1684: "1684",
  NUMBER_376: "376",
  NUMBER_244: "244",
  NUMBER_1264: "1264",
  NUMBER_672: "672",
  NUMBER_1268: "1268",
  NUMBER_54: "54",
  NUMBER_374: "374",
  NUMBER_297: "297",
  NUMBER_61: "61",
  NUMBER_43: "43",
  NUMBER_994: "994",
  NUMBER_1242: "1242",
  NUMBER_973: "973",
  NUMBER_880: "880",
  NUMBER_1246: "1246",
  NUMBER_375: "375",
  NUMBER_32: "32",
  NUMBER_501: "501",
  NUMBER_229: "229",
  NUMBER_1441: "1441",
  NUMBER_975: "975",
  NUMBER_591: "591",
  NUMBER_387: "387",
  NUMBER_267: "267",
  NUMBER_55: "55",
  NUMBER_246: "246",
  NUMBER_1284: "1284",
  NUMBER_673: "673",
  NUMBER_359: "359",
  NUMBER_226: "226",
  NUMBER_257: "257",
  NUMBER_855: "855",
  NUMBER_237: "237",
  NUMBER_1: "1",
  NUMBER_238: "238",
  NUMBER_1345: "1345",
  NUMBER_236: "236",
  NUMBER_235: "235",
  NUMBER_56: "56",
  NUMBER_86: "86",
  NUMBER_57: "57",
  NUMBER_269: "269",
  NUMBER_682: "682",
  NUMBER_506: "506",
  NUMBER_385: "385",
  NUMBER_53: "53",
  NUMBER_599: "599",
  NUMBER_357: "357",
  NUMBER_420: "420",
  NUMBER_243: "243",
  NUMBER_45: "45",
  NUMBER_253: "253",
  "1-767": "1-767",
  NUMBER_1809: "1809",
  NUMBER_1829: "1829",
  NUMBER_1849: "1849",
  NUMBER_670: "670",
  NUMBER_593: "593",
  NUMBER_20: "20",
  NUMBER_503: "503",
  NUMBER_240: "240",
  NUMBER_291: "291",
  NUMBER_372: "372",
  NUMBER_251: "251",
  NUMBER_500: "500",
  NUMBER_298: "298",
  NUMBER_679: "679",
  NUMBER_358: "358",
  NUMBER_33: "33",
  NUMBER_689: "689",
  NUMBER_241: "241",
  NUMBER_220: "220",
  NUMBER_995: "995",
  NUMBER_49: "49",
  NUMBER_233: "233",
  NUMBER_350: "350",
  NUMBER_30: "30",
  NUMBER_299: "299",
  NUMBER_1473: "1473",
  NUMBER_1671: "1671",
  NUMBER_502: "502",
  NUMBER_441481: "441481",
  NUMBER_224: "224",
  NUMBER_245: "245",
  NUMBER_592: "592",
  NUMBER_509: "509",
  NUMBER_504: "504",
  NUMBER_852: "852",
  NUMBER_36: "36",
  NUMBER_354: "354",
  NUMBER_91: "91",
  NUMBER_62: "62",
  NUMBER_98: "98",
  NUMBER_964: "964",
  NUMBER_353: "353",
  NUMBER_441624: "441624",
  NUMBER_972: "972",
  NUMBER_39: "39",
  NUMBER_225: "225",
  NUMBER_1876: "1876",
  NUMBER_81: "81",
  NUMBER_441534: "441534",
  NUMBER_962: "962",
  NUMBER_7: "7",
  NUMBER_254: "254",
  NUMBER_686: "686",
  NUMBER_383: "383",
  NUMBER_965: "965",
  NUMBER_996: "996",
  NUMBER_856: "856",
  NUMBER_371: "371",
  NUMBER_961: "961",
  NUMBER_266: "266",
  NUMBER_231: "231",
  NUMBER_218: "218",
  NUMBER_423: "423",
  NUMBER_370: "370",
  NUMBER_352: "352",
  NUMBER_853: "853",
  NUMBER_389: "389",
  NUMBER_261: "261",
  NUMBER_265: "265",
  NUMBER_60: "60",
  NUMBER_960: "960",
  NUMBER_223: "223",
  NUMBER_356: "356",
  NUMBER_692: "692",
  NUMBER_222: "222",
  NUMBER_230: "230",
  NUMBER_262: "262",
  NUMBER_52: "52",
  NUMBER_691: "691",
  NUMBER_373: "373",
  NUMBER_377: "377",
  NUMBER_976: "976",
  NUMBER_382: "382",
  "1-664": "1-664",
  NUMBER_212: "212",
  NUMBER_258: "258",
  NUMBER_95: "95",
  NUMBER_264: "264",
  NUMBER_674: "674",
  NUMBER_977: "977",
  NUMBER_31: "31",
  NUMBER_687: "687",
  NUMBER_64: "64",
  NUMBER_505: "505",
  NUMBER_227: "227",
  NUMBER_234: "234",
  NUMBER_683: "683",
  NUMBER_850: "850",
  NUMBER_1670: "1670",
  NUMBER_47: "47",
  NUMBER_968: "968",
  NUMBER_92: "92",
  NUMBER_680: "680",
  NUMBER_970: "970",
  NUMBER_507: "507",
  NUMBER_675: "675",
  NUMBER_595: "595",
  NUMBER_51: "51",
  NUMBER_63: "63",
  NUMBER_48: "48",
  NUMBER_351: "351",
  NUMBER_1787: "1787",
  NUMBER_1939: "1939",
  NUMBER_974: "974",
  NUMBER_242: "242",
  NUMBER_40: "40",
  NUMBER_250: "250",
  NUMBER_590: "590",
  NUMBER_290: "290",
  NUMBER_1869: "1869",
  NUMBER_1758: "1758",
  NUMBER_508: "508",
  NUMBER_1784: "1784",
  NUMBER_685: "685",
  NUMBER_378: "378",
  NUMBER_239: "239",
  NUMBER_966: "966",
  NUMBER_221: "221",
  NUMBER_381: "381",
  NUMBER_248: "248",
  NUMBER_232: "232",
  NUMBER_65: "65",
  NUMBER_1721: "1721",
  NUMBER_421: "421",
  NUMBER_386: "386",
  NUMBER_677: "677",
  NUMBER_252: "252",
  NUMBER_27: "27",
  NUMBER_82: "82",
  NUMBER_211: "211",
  NUMBER_34: "34",
  NUMBER_94: "94",
  NUMBER_249: "249",
  NUMBER_597: "597",
  NUMBER_268: "268",
  NUMBER_46: "46",
  NUMBER_41: "41",
  NUMBER_963: "963",
  NUMBER_886: "886",
  NUMBER_992: "992",
  NUMBER_255: "255",
  NUMBER_66: "66",
  NUMBER_228: "228",
  NUMBER_690: "690",
  NUMBER_676: "676",
  NUMBER_1868: "1868",
  NUMBER_216: "216",
  NUMBER_90: "90",
  NUMBER_993: "993",
  NUMBER_1649: "1649",
  NUMBER_688: "688",
  NUMBER_1340: "1340",
  NUMBER_256: "256",
  NUMBER_380: "380",
  NUMBER_971: "971",
  NUMBER_44: "44",
  NUMBER_598: "598",
  NUMBER_998: "998",
  NUMBER_678: "678",
  NUMBER_379: "379",
  NUMBER_58: "58",
  NUMBER_84: "84",
  NUMBER_681: "681",
  NUMBER_967: "967",
  NUMBER_260: "260",
  NUMBER_263: "263"
}, yg = {
  BUSINESS: "BUSINESS",
  BUSINESSSUPPLEMENTALDOCUMENTS: "BUSINESSSUPPLEMENTALDOCUMENTS",
  GOVID: "GOVID",
  ADHOCBUSINESSNAMEDOCUMENT: "ADHOCBUSINESSNAMEDOCUMENT",
  ADHOCBUSINESSADDRESSDOCUMENT: "ADHOCBUSINESSADDRESSDOCUMENT",
  ADHOCBUSINESSSTATUSDOCUMENT: "ADHOCBUSINESSSTATUSDOCUMENT",
  ADHOCHOMEADDRESSDOCUMENT: "ADHOCHOMEADDRESSDOCUMENT",
  ADHOCEINDOCUMENT: "ADHOCEINDOCUMENT",
  ADHOCIDENTITYDOCUMENT: "ADHOCIDENTITYDOCUMENT"
}, dg = {
  s3_datashare_upload: "s3_datashare_upload",
  manual_datashare_upload: "manual_datashare_upload",
  missing_sales_datashare: "missing_sales_datashare",
  missing_bank_account_datashare: "missing_bank_account_datashare",
  api_errors: "api_errors",
  crm_errors: "crm_errors",
  webhook_errors: "webhook_errors",
  user_added: "user_added",
  send_otp: "send_otp"
}, pg = {
  merchant_cash_advance: "merchant_cash_advance",
  card: "card",
  flex_loan: "flex_loan",
  merchant_bank: "merchant_bank"
}, _g = {
  opened: "opened",
  accepted: "accepted",
  canceled: "canceled",
  declined: "declined",
  finalized: "finalized"
}, mg = {
  in_progress: "in_progress",
  completed: "completed",
  failed: "failed"
}, lg = {
  void_cash_advance: "void_cash_advance"
}, gg = {
  basic_offer_generation: "basic_offer_generation",
  underwriting: "underwriting",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  kyc_retry: "kyc_retry",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  kyc_document_upload: "kyc_document_upload",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  introductory_offer_preview: "introductory_offer_preview",
  introductory_offer_underwriting: "introductory_offer_underwriting",
  introductory_offer_underwriting_rate_limit_exceeded: "introductory_offer_underwriting_rate_limit_exceeded",
  offer_acceptance: "offer_acceptance",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  manual_review: "manual_review",
  offer_accepted: "offer_accepted",
  offer_preview: "offer_preview",
  capital_product: "capital_product",
  denied: "denied",
  abandoned: "abandoned",
  merchant_bank_pre_onboarding: "merchant_bank_pre_onboarding",
  merchant_bank_created: "merchant_bank_created",
  contact_verification: "contact_verification"
}, qg = {
  merchant_cash_advance: "merchant_cash_advance",
  loan: "loan",
  capital: "capital",
  merchant_bank: "merchant_bank"
}, fg = {
  basic_offer_generation: "basic_offer_generation",
  boost_link: "boost_link",
  accounting_integration: "accounting_integration",
  kyc_retry: "kyc_retry",
  boost_offer_generation: "boost_offer_generation",
  kyc: "kyc",
  kyc_submitted: "kyc_submitted",
  bank_link: "bank_link",
  bank_link_with_statements: "bank_link_with_statements",
  bank_pad_agreement: "bank_pad_agreement",
  bank_verification: "bank_verification",
  bank_verification_with_statements: "bank_verification_with_statements",
  bank_reverification: "bank_reverification",
  amazon_bank_verification: "amazon_bank_verification",
  amazon_bank_reverification: "amazon_bank_reverification",
  amazon_bank_reverification_with_statements: "amazon_bank_reverification_with_statements",
  offer_acceptance: "offer_acceptance",
  kyc_document_upload: "kyc_document_upload",
  lien_info: "lien_info",
  debt_info: "debt_info",
  financial_review: "financial_review",
  tax_record: "tax_record",
  officer_subsidiary_record: "officer_subsidiary_record",
  start_application: "start_application",
  offer_preview: "offer_preview"
}, Og = {
  revenue_share: "revenue_share",
  parafin_funded: "parafin_funded"
}, hg = {
  top_up_reminder: "top_up_reminder",
  fast_payouts_notification_card: "fast_payouts_notification_card"
}, Kg = {
  enter_gmv: "enter_gmv",
  upload_csv: "upload_csv",
  generate_offers: "generate_offers",
  awaiting_review: "awaiting_review",
  map_emails: "map_emails",
  email_campaign: "email_campaign",
  finished: "finished"
}, Pg = {
  active: "active",
  inactive: "inactive"
}, bg = {
  minimum: "minimum",
  sales_based: "sales_based"
}, kg = {
  unverified: "unverified",
  posted: "posted",
  reversed: "reversed",
  verified: "verified",
  failed: "failed"
}, xg = {
  ach: "ach",
  same_day_ach: "same_day_ach",
  rtp: "rtp",
  wire: "wire"
}, Mg = {
  inner_transfer: "inner_transfer",
  card_transaction: "card_transaction",
  recipient_transfer: "recipient_transfer",
  external_bank_transfer: "external_bank_transfer",
  received_transfer: "received_transfer",
  transfer_return: "transfer_return",
  transfer_reversal: "transfer_reversal",
  repayment: "repayment"
}, Fg = {
  succeeded: "succeeded",
  failed: "failed",
  pending: "pending",
  cancelled: "cancelled",
  archived: "archived"
}, Rg = {
  money_in: "money_in",
  money_out: "money_out"
}, Bg = {
  merchant_bank_account: "merchant_bank_account",
  recipient_account: "recipient_account",
  external_bank_account: "external_bank_account"
}, Cg = {
  manual: "manual",
  payout: "payout"
}, Eg = {
  electrical: "electrical",
  wire: "wire"
}, Sg = {
  normal: "normal",
  micro_deposit: "micro_deposit",
  partner_payout: "partner_payout"
}, vg = {
  credit_limit: "credit_limit"
}, Ag = {
  checking: "checking",
  savings: "savings"
}, Dg = {
  active: "active",
  unlinked: "unlinked",
  archived_due_to_update: "archived_due_to_update",
  unverified_micro_deposit: "unverified_micro_deposit",
  failed_micro_deposit: "failed_micro_deposit"
}, Ug = {
  physical: "physical",
  virtual: "virtual"
}, Ng = {
  damaged: "damaged",
  lost: "lost",
  stolen: "stolen",
  never_received: "never_received"
}, Gg = {
  cash: "cash",
  credit: "credit"
}, Tg = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, Ig = {
  did_not_purchase: "did_not_purchase",
  charged_for_cancelled_or_returned_purchase: "charged_for_cancelled_or_returned_purchase",
  incorrect_amount: "incorrect_amount",
  never_received: "never_received",
  expecting_credit: "expecting_credit",
  not_as_described_or_defective: "not_as_described_or_defective",
  other: "other"
}, wg = {
  contact_verification: "contact_verification",
  kyc: "kyc",
  kyc_document_upload: "kyc_document_upload",
  kyc_submitted: "kyc_submitted",
  kyc_approved: "kyc_approved",
  manual_review: "manual_review",
  merchant_bank_created: "merchant_bank_created",
  denied: "denied"
}, Qg = {
  checking: "checking",
  savings: "savings"
}, Lg = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, Wg = {
  pre_launch_email: "pre_launch_email",
  capital_product_offer_email: "capital_product_offer_email"
}, Hg = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  statement_upload: "statement_upload",
  plaid_investigation: "plaid_investigation",
  manual_review: "manual_review",
  liens_pending: "liens_pending"
}, Vg = {
  funded: "funded",
  funding_initiated: "funding_initiated",
  offer_accepted: "offer_accepted",
  in_progress: "in_progress",
  pending_verification: "pending_verification",
  preapproved: "preapproved",
  no_offers: "no_offers",
  funding_denied: "funding_denied"
}, zg = {
  ucc: "ucc",
  federal: "federal",
  state: "state"
}, $g = {
  open: "open",
  closed: "closed",
  unknown: "unknown"
}, Yg = {
  pending_user: "pending_user",
  user_reviewed: "user_reviewed",
  do_not_need_user_review: "do_not_need_user_review"
}, Xg = {
  date_of_birth: "date_of_birth",
  address: "address",
  name: "name",
  tin: "tin"
}, Zg = {
  address: "address",
  name: "name",
  tin: "tin"
}, jg = {
  incomplete: "incomplete",
  approved: "approved",
  denied: "denied",
  under_review: "under_review",
  pending: "pending"
}, Jg = {
  checking_to_savings: "checking_to_savings",
  savings_to_checking: "savings_to_checking"
}, tq = {
  not_started: "not_started",
  pending: "pending",
  success: "success",
  failed: "failed"
}, eq = {
  irrelevant: "irrelevant",
  incomplete: "incomplete",
  complete: "complete"
}, nq = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, rq = {
  llc: "llc",
  corporation: "corporation",
  sole_proprietorship: "sole_proprietorship",
  partnership: "partnership",
  cooperative: "cooperative",
  business_trust: "business_trust",
  joint_venture: "joint_venture",
  other: "other"
}, sq = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, aq = {
  AL: "AL",
  AK: "AK",
  AZ: "AZ",
  AR: "AR",
  CA: "CA",
  CO: "CO",
  CT: "CT",
  DE: "DE",
  DC: "DC",
  FL: "FL",
  GA: "GA",
  HI: "HI",
  ID: "ID",
  IL: "IL",
  IN: "IN",
  IA: "IA",
  KS: "KS",
  KY: "KY",
  LA: "LA",
  ME: "ME",
  MD: "MD",
  MA: "MA",
  MI: "MI",
  MN: "MN",
  MS: "MS",
  MO: "MO",
  MT: "MT",
  NE: "NE",
  NV: "NV",
  NH: "NH",
  NJ: "NJ",
  NM: "NM",
  NY: "NY",
  NC: "NC",
  ND: "ND",
  OH: "OH",
  OK: "OK",
  OR: "OR",
  PA: "PA",
  PR: "PR",
  RI: "RI",
  SC: "SC",
  SD: "SD",
  TN: "TN",
  TX: "TX",
  UT: "UT",
  VT: "VT",
  VA: "VA",
  WA: "WA",
  WV: "WV",
  WI: "WI",
  WY: "WY",
  AB: "AB",
  BC: "BC",
  MB: "MB",
  NB: "NB",
  NL: "NL",
  NT: "NT",
  NS: "NS",
  NU: "NU",
  QC: "QC",
  ON: "ON",
  PE: "PE",
  SK: "SK",
  YT: "YT",
  Federal: "Federal"
}, oq = {
  all_merchants: "all_merchants",
  post_opt_in: "post_opt_in",
  post_opt_in_sync_webhook: "post_opt_in_sync_webhook",
  never: "never"
}, uq = {
  upcoming_repayment: "upcoming_repayment",
  scheduled_repayment: "scheduled_repayment"
}, iq = {
  manual: "manual",
  rutter: "rutter"
}, cq = {
  string: "string",
  decimal: "decimal",
  int: "int",
  date: "date",
  object: "object",
  currency_code: "currency_code",
  boolean: "boolean"
}, yq = {
  business: "business",
  account: "account"
}, dq = {
  system: "system",
  user: "user"
}, pq = {
  active: "active",
  inactive: "inactive"
}, _q = {
  not_started: "not_started",
  completed: "completed",
  in_progress: "in_progress",
  failed: "failed"
}, mq = {
  partner_datashare: "partner_datashare",
  manual_edit: "manual_edit",
  kyc: "kyc",
  auto_opt_in: "auto_opt_in",
  auto_opt_in_backfill: "auto_opt_in_backfill"
}, lq = {
  BUSINESS_GROUP_ADMIN: "BUSINESS GROUP ADMIN",
  ADMIN: "ADMIN",
  "N/A": "N/A",
  MANAGER: "MANAGER",
  STORE_OPERATOR: "STORE OPERATOR"
}, gq = {
  kyc: "kyc",
  bank_verification: "bank_verification",
  financial_review: "financial_review",
  tax_record: "tax_record",
  intro_offer: "intro_offer"
}, qq = {
  user_submitted: "user_submitted",
  parafin_rejected: "parafin_rejected",
  parafin_pending: "parafin_pending",
  parafin_submitted: "parafin_submitted",
  marqeta_rejected: "marqeta_rejected",
  marqeta_chargeback_won: "marqeta_chargeback_won",
  marqeta_chargeback_lost: "marqeta_chargeback_lost",
  parafin_credited: "parafin_credited",
  parafin_not_credited: "parafin_not_credited"
}, fq = {
  partner_wide_program: "partner_wide_program",
  business_level_program: "business_level_program"
}, Oq = {
  apply: "apply",
  lift: "lift"
}, hq = {
  underwriting: "underwriting",
  kyc: "kyc",
  funding_checklist: "funding_checklist",
  location: "location"
}, Kq = {
  pending: "pending",
  under_review: "under_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  error: "error"
}, Pq = {
  sunday: "sunday",
  monday: "monday",
  tuesday: "tuesday",
  wednesday: "wednesday",
  thursday: "thursday",
  friday: "friday",
  saturday: "saturday"
}, bq = {
  file_feed: "file_feed",
  manual_upload: "manual_upload",
  standard_s3: "standard_s3",
  api: "api",
  custom_legacy: "custom_legacy",
  opt_in: "opt_in"
}, kq = {
  pending: "pending",
  success: "success",
  failed: "failed"
}, xq = {
  business: "business",
  sale_record: "sale_record",
  bank_account_info: "bank_account_info",
  person: "person",
  person_business_relationship: "person_business_relationship"
}, Mq = {
  irrelevant: "irrelevant",
  optional: "optional",
  required: "required"
}, Fq = {
  AED: "AED",
  AFN: "AFN",
  ALL: "ALL",
  AMD: "AMD",
  ANG: "ANG",
  AOA: "AOA",
  ARS: "ARS",
  AUD: "AUD",
  AWG: "AWG",
  AZN: "AZN",
  BAM: "BAM",
  BBD: "BBD",
  BDT: "BDT",
  BGN: "BGN",
  BHD: "BHD",
  BIF: "BIF",
  BMD: "BMD",
  BND: "BND",
  BOB: "BOB",
  BOV: "BOV",
  BRL: "BRL",
  BSD: "BSD",
  BTN: "BTN",
  BWP: "BWP",
  BYN: "BYN",
  BZD: "BZD",
  CAD: "CAD",
  CDF: "CDF",
  CHE: "CHE",
  CHF: "CHF",
  CHW: "CHW",
  CLF: "CLF",
  CLP: "CLP",
  CNY: "CNY",
  COP: "COP",
  COU: "COU",
  CRC: "CRC",
  CUC: "CUC",
  CUP: "CUP",
  CVE: "CVE",
  CZK: "CZK",
  DJF: "DJF",
  DKK: "DKK",
  DOP: "DOP",
  DZD: "DZD",
  EGP: "EGP",
  ERN: "ERN",
  ETB: "ETB",
  EUR: "EUR",
  FJD: "FJD",
  FKP: "FKP",
  GBP: "GBP",
  GEL: "GEL",
  GHS: "GHS",
  GIP: "GIP",
  GMD: "GMD",
  GNF: "GNF",
  GTQ: "GTQ",
  GYD: "GYD",
  HKD: "HKD",
  HNL: "HNL",
  HTG: "HTG",
  HUF: "HUF",
  IDR: "IDR",
  ILS: "ILS",
  INR: "INR",
  IQD: "IQD",
  IRR: "IRR",
  ISK: "ISK",
  JMD: "JMD",
  JOD: "JOD",
  JPY: "JPY",
  KES: "KES",
  KGS: "KGS",
  KHR: "KHR",
  KMF: "KMF",
  KPW: "KPW",
  KRW: "KRW",
  KWD: "KWD",
  KYD: "KYD",
  KZT: "KZT",
  LAK: "LAK",
  LBP: "LBP",
  LKR: "LKR",
  LRD: "LRD",
  LSL: "LSL",
  LYD: "LYD",
  MAD: "MAD",
  MDL: "MDL",
  MGA: "MGA",
  MKD: "MKD",
  MMK: "MMK",
  MNT: "MNT",
  MOP: "MOP",
  MRU: "MRU",
  MUR: "MUR",
  MVR: "MVR",
  MWK: "MWK",
  MXN: "MXN",
  MXV: "MXV",
  MYR: "MYR",
  MZN: "MZN",
  NAD: "NAD",
  NGN: "NGN",
  NIO: "NIO",
  NOK: "NOK",
  NPR: "NPR",
  NZD: "NZD",
  OMR: "OMR",
  PAB: "PAB",
  PEN: "PEN",
  PGK: "PGK",
  PHP: "PHP",
  PKR: "PKR",
  PLN: "PLN",
  PYG: "PYG",
  QAR: "QAR",
  RON: "RON",
  RSD: "RSD",
  RUB: "RUB",
  RWF: "RWF",
  SAR: "SAR",
  SBD: "SBD",
  SCR: "SCR",
  SDG: "SDG",
  SEK: "SEK",
  SGD: "SGD",
  SHP: "SHP",
  SLE: "SLE",
  SOS: "SOS",
  SRD: "SRD",
  SSP: "SSP",
  STN: "STN",
  SVC: "SVC",
  SYP: "SYP",
  SZL: "SZL",
  THB: "THB",
  TJS: "TJS",
  TMT: "TMT",
  TND: "TND",
  TOP: "TOP",
  TRY: "TRY",
  TTD: "TTD",
  TWD: "TWD",
  TZS: "TZS",
  UAH: "UAH",
  UGX: "UGX",
  USD: "USD",
  USN: "USN",
  UYI: "UYI",
  UYU: "UYU",
  UYW: "UYW",
  UZS: "UZS",
  VED: "VED",
  VES: "VES",
  VND: "VND",
  VUV: "VUV",
  WST: "WST",
  XAF: "XAF",
  XAG: "XAG",
  XAU: "XAU",
  XBA: "XBA",
  XBB: "XBB",
  XBC: "XBC",
  XBD: "XBD",
  XCD: "XCD",
  XDR: "XDR",
  XOF: "XOF",
  XPD: "XPD",
  XPF: "XPF",
  XPT: "XPT",
  XSU: "XSU",
  XTS: "XTS",
  XUA: "XUA",
  XXX: "XXX",
  YER: "YER",
  ZAR: "ZAR",
  ZMW: "ZMW",
  ZWL: "ZWL"
}, Rq = {
  USD: "USD",
  CAD: "CAD"
}, Bq = {
  UNSUPPORTEDCURRENCY: "UNSUPPORTEDCURRENCY",
  USD: "USD",
  CAD: "CAD",
  USDFOURTHDECIMAL: "USDFOURTHDECIMAL"
}, Cq = {
  hubspot: "hubspot",
  marketo: "marketo",
  salesforce: "salesforce"
}, Eq = {
  offer_summary: "offer_summary"
}, Sq = {
  pending: "pending",
  succeeded: "succeeded",
  failed: "failed"
}, vq = {
  US: "US",
  CA: "CA"
}, Aq = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Dq = {
  non_payment: "non_payment",
  slow_payment: "slow_payment",
  platform_exit: "platform_exit",
  bankruptcy: "bankruptcy",
  death: "death",
  fraud: "fraud",
  error: "error"
}, Uq = {
  fulfillmentdigitally_presented: "fulfillment.digitally_presented",
  fulfillmentissued: "fulfillment.issued",
  fulfillmentordered: "fulfillment.ordered",
  fulfillmentrejected: "fulfillment.rejected",
  fulfillmentreordered: "fulfillment.reordered",
  fulfillmentshipped: "fulfillment.shipped",
  stateactivated: "state.activated",
  statereinstated: "state.reinstated",
  statesuspended: "state.suspended",
  stateterminated: "state.terminated",
  pinchanged: "pin.changed",
  pinset: "pin.set",
  pinreveal: "pin.reveal"
}, Nq = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNACTIVATED: "UNACTIVATED"
}, Gq = {
  NUMBER_00: "00",
  NUMBER_01: "01",
  NUMBER_02: "02",
  NUMBER_03: "03",
  NUMBER_04: "04",
  NUMBER_05: "05",
  NUMBER_06: "06",
  NUMBER_07: "07",
  NUMBER_08: "08",
  NUMBER_09: "09",
  NUMBER_10: "10",
  NUMBER_11: "11",
  NUMBER_12: "12",
  NUMBER_13: "13",
  NUMBER_14: "14",
  NUMBER_15: "15",
  NUMBER_16: "16",
  NUMBER_17: "17",
  NUMBER_18: "18",
  NUMBER_19: "19",
  NUMBER_20: "20",
  NUMBER_21: "21",
  NUMBER_22: "22",
  NUMBER_23: "23",
  NUMBER_24: "24",
  NUMBER_25: "25",
  NUMBER_26: "26",
  NUMBER_27: "27",
  NUMBER_28: "28",
  NUMBER_29: "29",
  NUMBER_30: "30",
  NUMBER_31: "31"
}, Tq = {
  ADMIN: "ADMIN",
  API: "API",
  FRAUD: "FRAUD",
  IVR: "IVR",
  SYSTEM: "SYSTEM"
}, Iq = {
  chargeback: "chargeback",
  normal: "normal"
}, wq = {
  LOCAL_MAIL: "LOCAL_MAIL",
  GROUND: "GROUND",
  TWO_DAY: "TWO_DAY",
  OVERNIGHT: "OVERNIGHT",
  INTERNATIONAL: "INTERNATIONAL",
  FEDEX_EXPEDITED: "FEDEX_EXPEDITED",
  FEDEX_REGULAR: "FEDEX_REGULAR",
  UPS_EXPEDITED: "UPS_EXPEDITED",
  UPS_REGULAR: "UPS_REGULAR",
  USPS_EXPEDITED: "USPS_EXPEDITED",
  USPS_REGULAR: "USPS_REGULAR"
}, Qq = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Lq = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Wq = {
  ACTIVE: "ACTIVE",
  SUSPENDED: "SUSPENDED",
  TERMINATED: "TERMINATED",
  UNSUPPORTED: "UNSUPPORTED",
  UNACTIVATED: "UNACTIVATED",
  LIMITED: "LIMITED"
}, Hq = {
  ISSUED: "ISSUED",
  ORDERED: "ORDERED",
  REORDERED: "REORDERED",
  REJECTED: "REJECTED",
  SHIPPED: "SHIPPED",
  DELIVERED: "DELIVERED",
  DIGITALLY_PRESENTED: "DIGITALLY_PRESENTED"
}, Vq = {
  physical: "physical",
  virtual: "virtual"
}, zq = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, $q = {
  physical: "physical",
  virtual: "virtual"
}, Yq = {
  no_opt_in: "no_opt_in",
  opt_in: "opt_in",
  onboarding: "onboarding",
  offer_expired: "offer_expired",
  kyc_review: "kyc_review",
  kyc_failed: "kyc_failed",
  verify_bank: "verify_bank",
  reverify_bank: "reverify_bank",
  no_transaction: "no_transaction",
  complete: "complete",
  account_closed: "account_closed"
}, Xq = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan"
}, Zq = {
  merchant_cash_advance: "merchant_cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, jq = {
  seen_accounting_integrations: "seen_accounting_integrations"
}, Jq = {
  outstanding: "outstanding",
  paid: "paid",
  abandoned: "abandoned",
  cancelled: "cancelled",
  waived: "waived",
  void: "void"
}, tf = {
  "1month": "1month",
  "2month": "2month",
  "3month": "3month",
  "4month": "4month",
  "5month": "5month",
  "6month": "6month",
  "7month": "7month",
  "8month": "8month",
  "9month": "9month",
  "10month": "10month",
  "11month": "11month",
  "12month": "12month",
  "13month": "13month",
  "14month": "14month",
  "15month": "15month",
  "16month": "16month",
  "17month": "17month",
  "18month": "18month",
  "19month": "19month",
  "20month": "20month",
  "21month": "21month",
  "22month": "22month",
  "23month": "23month",
  "24month": "24month"
}, ef = {
  sales_based: "sales_based",
  fixed_amount: "fixed_amount",
  minimum_amount: "minimum_amount"
}, nf = {
  healthy: "healthy",
  unhealthy: "unhealthy"
}, rf = {
  merchant_cash_advance: "merchant_cash_advance",
  amazon_mca: "amazon_mca",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl"
}, sf = {
  application_created: "application_created",
  application_progress: "application_progress",
  manual_review: "manual_review",
  denied: "denied",
  multiple_manual_review: "multiple_manual_review",
  underwriting_manual_review: "underwriting_manual_review",
  underwriting_denial: "underwriting_denial",
  multiple_denied_reasons: "multiple_denied_reasons",
  kyc_manual_review: "kyc_manual_review",
  kyc_denial: "kyc_denial",
  bank_statement_upload_manual_review: "bank_statement_upload_manual_review",
  plaid_investigation_manual_review: "plaid_investigation_manual_review",
  funding_check_manual_review: "funding_check_manual_review",
  funding_check_denial: "funding_check_denial",
  auto_approval: "auto_approval",
  manual_review_approval: "manual_review_approval",
  manual_review_denial: "manual_review_denial",
  offer_refreshed: "offer_refreshed",
  offer_accepted: "offer_accepted",
  funding_completed: "funding_completed",
  expired: "expired",
  abandoned: "abandoned",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_pulled: "offer_pulled",
  consistency: "consistency",
  unsupported: "unsupported",
  metadata_update: "metadata_update"
}, af = {
  created: "created",
  submitted: "submitted",
  approved: "approved",
  denied: "denied",
  abandoned: "abandoned",
  confirmed: "confirmed",
  funded: "funded"
}, of = {
  in_progress: "in_progress",
  manual_review: "manual_review",
  approved: "approved",
  denied: "denied",
  expired: "expired",
  cancelled: "cancelled",
  deactivate_for_modified_offer: "deactivate_for_modified_offer",
  withdrawn: "withdrawn",
  offer_accepted: "offer_accepted",
  funded: "funded"
}, uf = {
  principal: "principal",
  fee: "fee",
  payment: "payment",
  minimum_payment: "minimum_payment",
  automatic_payment: "automatic_payment",
  manual_payment: "manual_payment",
  generic: "generic",
  payment_adjustment: "payment_adjustment"
}, cf = {
  card: "card",
  cash_advance: "cash_advance",
  flex_loan: "flex_loan",
  term_loan: "term_loan",
  bnpl: "bnpl",
  merchant_bank: "merchant_bank"
}, yf = {
  new: "new",
  verified: "verified"
}, df = {
  full: "full",
  partial: "partial",
  none: "none",
  partner_does_not_have: "partner_does_not_have"
}, pf = {
  bankruptcy: "bankruptcy",
  excessive_credit_obligations: "excessive_credit_obligations",
  fraud: "fraud",
  insufficient_sales: "insufficient_sales",
  recent_sales_volatility: "recent_sales_volatility",
  liens: "liens",
  problematic_bank_account: "problematic_bank_account",
  problematic_payment_history: "problematic_payment_history",
  too_many_liens: "too_many_liens",
  unpaid_prior_account: "unpaid_prior_account",
  unserviceable_bank_account: "unserviceable_bank_account",
  kyc: "kyc",
  unlicensed_business: "unlicensed_business",
  program_terminated: "program_terminated",
  problematic_payment_history_with_others: "problematic_payment_history_with_others",
  collection_action_or_judgement: "collection_action_or_judgement",
  "": ""
}, _f = {
  HOME: "HOME",
  WORK: "WORK",
  LEGAL: "LEGAL",
  SHIPPING: "SHIPPING",
  PHYSICAL: "PHYSICAL"
}, mf = {
  celtic_bank: "celtic_bank",
  parafin: "parafin",
  parafin_spv_1: "parafin_spv_1",
  parafin_spv_2: "parafin_spv_2",
  parafin_spv_3: "parafin_spv_3"
}, d = (t, e) => o.get(
  "/business_cores",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), p = (t) => ["/business_cores", ...t ? [t] : []], _ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? p(t), queryFn: ({ signal: u }) => d(t, { signal: u, ...r }), ...n };
};
function lf(t, e) {
  const n = _(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const m = (t, e) => o.post(
  "/business_cores",
  t,
  e
), l = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return m(a, n);
  }, ...e };
}, gf = (t) => {
  const e = l(t);
  return i(e);
}, g = (t, e) => o.get(
  "/business_annotations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), q = (t) => ["/business_annotations", ...t ? [t] : []], f = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? q(t), queryFn: ({ signal: u }) => g(t, { signal: u, ...r }), ...n };
};
function qf(t, e) {
  const n = f(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const O = (t, e) => o.post(
  "/business_annotations",
  t,
  e
), h = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return O(a, n);
  }, ...e };
}, ff = (t) => {
  const e = h(t);
  return i(e);
}, K = (t, e) => o.get(
  "/cash_advance_charge_offs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), P = (t) => ["/cash_advance_charge_offs", ...t ? [t] : []], b = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? P(t), queryFn: ({ signal: u }) => K(t, { signal: u, ...r }), ...n };
};
function Of(t, e) {
  const n = b(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const k = (t, e) => o.post(
  "/cash_advance_charge_offs",
  t,
  e
), x = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return k(a, n);
  }, ...e };
}, hf = (t) => {
  const e = x(t);
  return i(e);
}, M = (t, e) => o.get(
  "/partners/doordash/admin_users",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), F = (t) => ["/partners/doordash/admin_users", ...t ? [t] : []], R = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? F(t), queryFn: ({ signal: u }) => M(t, { signal: u, ...r }), ...n };
};
function Kf(t, e) {
  const n = R(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const B = (t, e) => o.get(
  "/cash_advance_pauses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), C = (t) => ["/cash_advance_pauses", ...t ? [t] : []], E = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? C(t), queryFn: ({ signal: u }) => B(t, { signal: u, ...r }), ...n };
};
function Pf(t, e) {
  const n = E(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const S = (t, e) => o.post(
  "/cash_advance_pauses",
  t,
  e
), v = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return S(a, n);
  }, ...e };
}, bf = (t) => {
  const e = v(t);
  return i(e);
}, A = (t, e) => o.delete(
  `/cash_advance_pauses/${t}`,
  e
), D = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a } = s ?? {};
    return A(a, n);
  }, ...e };
}, kf = (t) => {
  const e = D(t);
  return i(e);
}, U = (t, e, n) => o.patch(
  `/cash_advance_pauses/${t}`,
  e,
  n
), N = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { p1: a, data: u } = s ?? {};
    return U(a, u, n);
  }, ...e };
}, xf = (t) => {
  const e = N(t);
  return i(e);
}, G = (t, e) => o.get(
  "/repayment_plan_parameters",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), T = (t) => ["/repayment_plan_parameters", ...t ? [t] : []], I = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? T(t), queryFn: ({ signal: u }) => G(t, { signal: u, ...r }), ...n };
};
function Mf(t, e) {
  const n = I(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const w = (t, e) => o.get(
  "/future_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Q = (t) => ["/future_activities", ...t ? [t] : []], L = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Q(t), queryFn: ({ signal: u }) => w(t, { signal: u, ...r }), ...n };
};
function Ff(t, e) {
  const n = L(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const W = (t, e) => o.get(
  "/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), H = (t) => ["/partners", ...t ? [t] : []], V = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? H(t), queryFn: ({ signal: u }) => W(t, { signal: u, ...r }), ...n };
};
function Rf(t, e) {
  const n = V(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const z = (t, e) => o.post(
  "/partners",
  t,
  e
), $ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return z(a, n);
  }, ...e };
}, Bf = (t) => {
  const e = $(t);
  return i(e);
}, Y = (t) => o.get(
  "/partners/underwriting",
  t
), X = () => ["/partners/underwriting"], Z = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? X(), queryFn: ({ signal: a }) => Y({ signal: a, ...n }), ...e };
};
function Cf(t) {
  const e = Z(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const j = (t, e, n) => o.patch(
  `/partners/${t}`,
  e,
  n
), J = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return j(a, u, n);
  }, ...e };
}, Ef = (t) => {
  const e = J(t);
  return i(e);
}, tt = (t, e) => o.get(
  "/partner/api_keys",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), et = (t) => ["/partner/api_keys", ...t ? [t] : []], nt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? et(t), queryFn: ({ signal: u }) => tt(t, { signal: u, ...r }), ...n };
};
function Sf(t, e) {
  const n = nt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const rt = (t, e) => o.post(
  "/partner/api_keys",
  t,
  e
), st = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return rt(a, n);
  }, ...e };
}, vf = (t) => {
  const e = st(t);
  return i(e);
}, at = (t) => o.get(
  "/partner/organizations",
  t
), ot = () => ["/partner/organizations"], ut = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ot(), queryFn: ({ signal: a }) => at({ signal: a, ...n }), ...e };
};
function Af(t) {
  const e = ut(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const it = (t, e) => o.post(
  "/partner/organizations",
  t,
  e
), ct = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return it(a, n);
  }, ...e };
}, Df = (t) => {
  const e = ct(t);
  return i(e);
}, yt = (t, e) => o.post(
  "/partner/organizations/link_partner",
  t,
  e
), dt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return yt(a, n);
  }, ...e };
}, Uf = (t) => {
  const e = dt(t);
  return i(e);
}, pt = (t, e) => o.get(
  "/partner/organizations/partners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _t = (t) => ["/partner/organizations/partners", ...t ? [t] : []], mt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _t(t), queryFn: ({ signal: u }) => pt(t, { signal: u, ...r }), ...n };
};
function Nf(t, e) {
  const n = mt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const lt = (t, e) => o.post(
  "/slack/authorize",
  t,
  e
), gt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return lt(a, n);
  }, ...e };
}, Gf = (t) => {
  const e = gt(t);
  return i(e);
}, qt = (t) => o.get(
  "/slack/channels",
  t
), ft = () => ["/slack/channels"], Ot = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ft(), queryFn: ({ signal: a }) => qt({ signal: a, ...n }), ...e };
};
function Tf(t) {
  const e = Ot(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ht = (t, e) => o.delete(
  `/slack/channels/${t}`,
  e
), Kt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return ht(a, n);
  }, ...e };
}, If = (t) => {
  const e = Kt(t);
  return i(e);
}, Pt = (t) => o.get(
  "/partner/users",
  t
), bt = () => ["/partner/users"], kt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? bt(), queryFn: ({ signal: a }) => Pt({ signal: a, ...n }), ...e };
};
function wf(t) {
  const e = kt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const xt = (t, e) => o.post(
  "/partner/users",
  t,
  e
), Mt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xt(a, n);
  }, ...e };
}, Qf = (t) => {
  const e = Mt(t);
  return i(e);
}, Ft = (t, e) => o.patch(
  "/partner/users",
  t,
  e
), Rt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ft(a, n);
  }, ...e };
}, Lf = (t) => {
  const e = Rt(t);
  return i(e);
}, Bt = (t, e) => o.delete(
  "/partner/delete_users",
  { data: t, ...e }
), Ct = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bt(a, n);
  }, ...e };
}, Wf = (t) => {
  const e = Ct(t);
  return i(e);
}, Et = (t, e) => o.delete(
  "/organization/delete_users",
  { data: t, ...e }
), St = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Et(a, n);
  }, ...e };
}, Hf = (t) => {
  const e = St(t);
  return i(e);
}, vt = (t, e) => o.get(
  "/partner/public_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), At = (t) => ["/partner/public_configs", ...t ? [t] : []], Dt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? At(t), queryFn: ({ signal: u }) => vt(t, { signal: u, ...r }), ...n };
};
function Vf(t, e) {
  const n = Dt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ut = (t, e) => o.get(
  "/partner/configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Nt = (t) => ["/partner/configs", ...t ? [t] : []], Gt = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Nt(t), queryFn: ({ signal: u }) => Ut(t, { signal: u, ...r }), ...n };
};
function zf(t, e) {
  const n = Gt(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Tt = (t, e, n) => o.patch(
  `/partner/configs/${t}`,
  e,
  n
), It = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Tt(a, u, n);
  }, ...e };
}, $f = (t) => {
  const e = It(t);
  return i(e);
}, wt = (t) => o.get(
  "/partner/sync_webhooks",
  t
), Qt = () => ["/partner/sync_webhooks"], Lt = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Qt(), queryFn: ({ signal: a }) => wt({ signal: a, ...n }), ...e };
};
function Yf(t) {
  const e = Lt(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Wt = (t, e) => o.post(
  "/partner/sync_webhooks",
  t,
  e
), Ht = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Wt(a, n);
  }, ...e };
}, Xf = (t) => {
  const e = Ht(t);
  return i(e);
}, Vt = (t, e) => o.delete(
  `/partner/sync_webhooks/${t}`,
  e
), zt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Vt(a, n);
  }, ...e };
}, Zf = (t) => {
  const e = zt(t);
  return i(e);
}, $t = (t, e, n) => o.patch(
  `/partner/sync_webhooks/${t}`,
  e,
  n
), Yt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return $t(a, u, n);
  }, ...e };
}, jf = (t) => {
  const e = Yt(t);
  return i(e);
}, Xt = (t, e) => o.post(
  "/auth/tokens",
  t,
  e
), Zt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Xt(a, n);
  }, ...e };
}, Jf = (t) => {
  const e = Zt(t);
  return i(e);
}, jt = (t, e) => o.post(
  "/hackathon/partners",
  t,
  e
), Jt = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return jt(a, n);
  }, ...e };
}, tO = (t) => {
  const e = Jt(t);
  return i(e);
}, te = (t) => o.post(
  "/hackathon/create_pilot",
  void 0,
  t
), ee = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => te(n), ...e };
}, eO = (t) => {
  const e = ee(t);
  return i(e);
}, ne = (t) => o.get(
  "/hackathon/get_pilot",
  t
), re = () => ["/hackathon/get_pilot"], se = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? re(), queryFn: ({ signal: a }) => ne({ signal: a, ...n }), ...e };
};
function nO(t) {
  const e = se(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ae = (t) => o.post(
  "/hackathon/generate_offers",
  void 0,
  t
), oe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ae(n), ...e };
}, rO = (t) => {
  const e = oe(t);
  return i(e);
}, ue = (t) => o.post(
  "/hackathon/await_parafin_representative",
  void 0,
  t
), ie = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ue(n), ...e };
}, sO = (t) => {
  const e = ie(t);
  return i(e);
}, ce = (t) => o.post(
  "/hackathon/send_offers",
  void 0,
  t
), ye = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => ce(n), ...e };
}, aO = (t) => {
  const e = ye(t);
  return i(e);
}, de = (t, e) => o.post(
  "/hackathon/gmv",
  t,
  e
), pe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return de(a, n);
  }, ...e };
}, oO = (t) => {
  const e = pe(t);
  return i(e);
}, _e = (t) => o.post(
  "/hackathon/reset",
  void 0,
  t
), me = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => _e(n), ...e };
}, uO = (t) => {
  const e = me(t);
  return i(e);
}, le = (t) => o.post(
  "/hackathon/map_offers_to_emails",
  void 0,
  t
), ge = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => le(n), ...e };
}, iO = (t) => {
  const e = ge(t);
  return i(e);
}, qe = (t) => o.post(
  "/hackathon/upload_sales_csv",
  void 0,
  t
), fe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => qe(n), ...e };
}, cO = (t) => {
  const e = fe(t);
  return i(e);
}, Oe = (t, e) => o.get(
  "/statements_v2",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), he = (t) => ["/statements_v2", ...t ? [t] : []], Ke = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? he(t), queryFn: ({ signal: u }) => Oe(t, { signal: u, ...r }), ...n };
};
function yO(t, e) {
  const n = Ke(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pe = (t, e) => o.post(
  "/marketing/review",
  t,
  e
), be = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Pe(a, n);
  }, ...e };
}, dO = (t) => {
  const e = be(t);
  return i(e);
}, ke = (t) => o.get(
  "/marketing/integrations",
  t
), xe = () => ["/marketing/integrations"], Me = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? xe(), queryFn: ({ signal: a }) => ke({ signal: a, ...n }), ...e };
};
function pO(t) {
  const e = Me(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Fe = (t, e) => o.post(
  "/marketing/integrations",
  t,
  e
), Re = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fe(a, n);
  }, ...e };
}, _O = (t) => {
  const e = Re(t);
  return i(e);
}, Be = (t, e) => o.delete(
  `/marketing/integrations/${t}`,
  e
), Ce = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { vendor: a } = s ?? {};
    return Be(a, n);
  }, ...e };
}, mO = (t) => {
  const e = Ce(t);
  return i(e);
}, Ee = (t) => o.get(
  "/marketing/gtm_toolkit",
  t
), Se = () => ["/marketing/gtm_toolkit"], ve = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Se(), queryFn: ({ signal: a }) => Ee({ signal: a, ...n }), ...e };
};
function lO(t) {
  const e = ve(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ae = (t) => o.get(
  "/marketing/paragon_token",
  t
), De = () => ["/marketing/paragon_token"], Ue = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? De(), queryFn: ({ signal: a }) => Ae({ signal: a, ...n }), ...e };
};
function gO(t) {
  const e = Ue(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ne = (t, e) => o.get(
  "/beneficial_owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ge = (t) => ["/beneficial_owners", ...t ? [t] : []], Te = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ge(t), queryFn: ({ signal: u }) => Ne(t, { signal: u, ...r }), ...n };
};
function qO(t, e) {
  const n = Te(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ie = (t, e) => o.post(
  "/beneficial_owners",
  t,
  e
), we = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ie(a, n);
  }, ...e };
}, fO = (t) => {
  const e = we(t);
  return i(e);
}, Qe = (t, e) => o.delete(
  `/beneficial_owners/${t}`,
  e
), Le = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Qe(a, n);
  }, ...e };
}, OO = (t) => {
  const e = Le(t);
  return i(e);
}, We = (t, e, n) => o.patch(
  `/beneficial_owners/${t}`,
  e,
  n
), He = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return We(a, u, n);
  }, ...e };
}, hO = (t) => {
  const e = He(t);
  return i(e);
}, Ve = (t, e) => o.get(
  "/owner_is_beneficial_owner",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ze = (t) => ["/owner_is_beneficial_owner", ...t ? [t] : []], $e = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ze(t), queryFn: ({ signal: u }) => Ve(t, { signal: u, ...r }), ...n };
};
function KO(t, e) {
  const n = $e(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ye = (t, e) => o.patch(
  "/owner_is_beneficial_owner",
  t,
  e
), Xe = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ye(a, n);
  }, ...e };
}, PO = (t) => {
  const e = Xe(t);
  return i(e);
}, Ze = (t, e) => o.get(
  "/banks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), je = (t) => ["/banks", ...t ? [t] : []], Je = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? je(t), queryFn: ({ signal: u }) => Ze(t, { signal: u, ...r }), ...n };
};
function bO(t, e) {
  const n = Je(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const tn = (t, e) => o.get(
  "/business_table_rows/product_agnostic",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), en = (t) => ["/business_table_rows/product_agnostic", ...t ? [t] : []], nn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? en(t), queryFn: ({ signal: u }) => tn(t, { signal: u, ...r }), ...n };
};
function kO(t, e) {
  const n = nn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const rn = (t, e) => o.get(
  "/business_table_rows/mca",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sn = (t) => ["/business_table_rows/mca", ...t ? [t] : []], an = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sn(t), queryFn: ({ signal: u }) => rn(t, { signal: u, ...r }), ...n };
};
function xO(t, e) {
  const n = an(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const on = (t, e) => o.get(
  "/business_table_rows/loan",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), un = (t) => ["/business_table_rows/loan", ...t ? [t] : []], cn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? un(t), queryFn: ({ signal: u }) => on(t, { signal: u, ...r }), ...n };
};
function MO(t, e) {
  const n = cn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yn = (t, e) => o.get(
  "/business_table_rows/card",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dn = (t) => ["/business_table_rows/card", ...t ? [t] : []], pn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dn(t), queryFn: ({ signal: u }) => yn(t, { signal: u, ...r }), ...n };
};
function FO(t, e) {
  const n = pn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _n = (t, e) => o.get(
  "/business/officers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), mn = (t) => ["/business/officers", ...t ? [t] : []], ln = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? mn(t), queryFn: ({ signal: u }) => _n(t, { signal: u, ...r }), ...n };
};
function RO(t, e) {
  const n = ln(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gn = (t, e) => o.post(
  "/business/officers",
  t,
  e
), qn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return gn(a, n);
  }, ...e };
}, BO = (t) => {
  const e = qn(t);
  return i(e);
}, fn = (t, e) => o.delete(
  `/business/officers/${t}`,
  e
), On = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return fn(a, n);
  }, ...e };
}, CO = (t) => {
  const e = On(t);
  return i(e);
}, hn = (t, e, n) => o.patch(
  `/business/officers/${t}`,
  e,
  n
), Kn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return hn(a, u, n);
  }, ...e };
}, EO = (t) => {
  const e = Kn(t);
  return i(e);
}, Pn = (t) => o.post(
  "/dataingestion/oneschema/generate_jwt",
  void 0,
  t
), bn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Pn(n), ...e };
}, SO = (t) => {
  const e = bn(t);
  return i(e);
}, kn = (t, e) => o.get(
  "/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xn = (t) => ["/dataset_uploads", ...t ? [t] : []], Mn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xn(t), queryFn: ({ signal: u }) => kn(t, { signal: u, ...r }), ...n };
};
function vO(t, e) {
  const n = Mn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Fn = (t, e) => o.get(
  "/dataingestion/manual_uploads/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Rn = (t) => ["/dataingestion/manual_uploads/dataset_upload_validation_results", ...t ? [t] : []], Bn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rn(t), queryFn: ({ signal: u }) => Fn(t, { signal: u, ...r }), ...n };
};
function AO(t, e) {
  const n = Bn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cn = (t, e) => o.get(
  "/dataingestion/s3/dataset_uploads",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), En = (t) => ["/dataingestion/s3/dataset_uploads", ...t ? [t] : []], Sn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? En(t), queryFn: ({ signal: u }) => Cn(t, { signal: u, ...r }), ...n };
};
function DO(t, e) {
  const n = Sn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vn = (t, e) => o.get(
  "/dataingestion/s3/dataset_upload_validation_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), An = (t) => ["/dataingestion/s3/dataset_upload_validation_results", ...t ? [t] : []], Dn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? An(t), queryFn: ({ signal: u }) => vn(t, { signal: u, ...r }), ...n };
};
function UO(t, e) {
  const n = Dn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Un = (t, e) => o.get(
  "/dataingestion/s3/dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Nn = (t) => ["/dataingestion/s3/dataset_integrations", ...t ? [t] : []], Gn = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Nn(t), queryFn: ({ signal: u }) => Un(t, { signal: u, ...r }), ...n };
};
function NO(t, e) {
  const n = Gn(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Tn = (t, e) => o.post(
  "/dataingestion/s3/dataset_integrations",
  t,
  e
), In = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Tn(a, n);
  }, ...e };
}, GO = (t) => {
  const e = In(t);
  return i(e);
}, wn = (t, e) => o.get(
  "/dataingestion/s3/org_dataset_integrations",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qn = (t) => ["/dataingestion/s3/org_dataset_integrations", ...t ? [t] : []], Ln = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qn(t), queryFn: ({ signal: u }) => wn(t, { signal: u, ...r }), ...n };
};
function TO(t, e) {
  const n = Ln(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wn = (t, e) => o.post(
  "/dataingestion/s3/org_dataset_integrations",
  t,
  e
), Hn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Wn(a, n);
  }, ...e };
}, IO = (t) => {
  const e = Hn(t);
  return i(e);
}, Vn = (t, e) => o.get(
  "/dataingestion/s3/dataset_ingestion_configs",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zn = (t) => ["/dataingestion/s3/dataset_ingestion_configs", ...t ? [t] : []], $n = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zn(t), queryFn: ({ signal: u }) => Vn(t, { signal: u, ...r }), ...n };
};
function wO(t, e) {
  const n = $n(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yn = (t, e) => o.put(
  "/dataingestion/s3/dataset_ingestion_configs",
  t,
  e
), Xn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yn(a, n);
  }, ...e };
}, QO = (t) => {
  const e = Xn(t);
  return i(e);
}, Zn = (t, e) => o.post(
  "/dataingestion/s3/run_dataset_ingestion",
  t,
  e
), jn = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zn(a, n);
  }, ...e };
}, LO = (t) => {
  const e = jn(t);
  return i(e);
}, Jn = (t, e) => o.get(
  "/dataingestion/schema",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tr = (t) => ["/dataingestion/schema", ...t ? [t] : []], er = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tr(t), queryFn: ({ signal: u }) => Jn(t, { signal: u, ...r }), ...n };
};
function WO(t, e) {
  const n = er(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const nr = (t, e) => o.get(
  "/dataingestion/stripe_ingestions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rr = (t) => ["/dataingestion/stripe_ingestions", ...t ? [t] : []], sr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rr(t), queryFn: ({ signal: u }) => nr(t, { signal: u, ...r }), ...n };
};
function HO(t, e) {
  const n = sr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ar = (t, e) => o.post(
  "/dataingestion/stripe_ingestions",
  t,
  e
), or = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ar(a, n);
  }, ...e };
}, VO = (t) => {
  const e = or(t);
  return i(e);
}, ur = (t, e) => o.patch(
  "/dataingestion/stripe_ingestions",
  t,
  e
), ir = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ur(a, n);
  }, ...e };
}, zO = (t) => {
  const e = ir(t);
  return i(e);
}, cr = (t, e) => o.get(
  "/onboarding/business_requirements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), yr = (t) => ["/onboarding/business_requirements", ...t ? [t] : []], dr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yr(t), queryFn: ({ signal: u }) => cr(t, { signal: u, ...r }), ...n };
};
function $O(t, e) {
  const n = dr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const pr = (t, e) => o.get(
  "/capital_product_prospective_terms_of_service",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _r = (t) => ["/capital_product_prospective_terms_of_service", ...t ? [t] : []], mr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _r(t), queryFn: ({ signal: u }) => pr(t, { signal: u, ...r }), ...n };
};
function YO(t, e) {
  const n = mr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const lr = (t, e) => o.get(
  "/capital_products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gr = (t) => ["/capital_products", ...t ? [t] : []], qr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gr(t), queryFn: ({ signal: u }) => lr(t, { signal: u, ...r }), ...n };
};
function XO(t, e) {
  const n = qr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const fr = (t, e) => o.post(
  "/capital_products",
  t,
  e
), Or = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return fr(a, n);
  }, ...e };
}, ZO = (t) => {
  const e = Or(t);
  return i(e);
}, hr = (t, e, n) => o.patch(
  `/capital_products/${t}`,
  e,
  n
), Kr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return hr(a, u, n);
  }, ...e };
}, jO = (t) => {
  const e = Kr(t);
  return i(e);
}, Pr = (t, e) => o.get(
  "/capital_product_offer_collections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), br = (t) => ["/capital_product_offer_collections", ...t ? [t] : []], kr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? br(t), queryFn: ({ signal: u }) => Pr(t, { signal: u, ...r }), ...n };
};
function JO(t, e) {
  const n = kr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const xr = (t, e) => o.post(
  "/capital_product_offer_collections",
  t,
  e
), Mr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xr(a, n);
  }, ...e };
}, th = (t) => {
  const e = Mr(t);
  return i(e);
}, Fr = (t, e) => o.get(
  "/capital_product_activities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Rr = (t) => ["/capital_product_activities", ...t ? [t] : []], Br = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rr(t), queryFn: ({ signal: u }) => Fr(t, { signal: u, ...r }), ...n };
};
function eh(t, e) {
  const n = Br(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cr = (t, e) => o.get(
  "/capital_product_applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Er = (t) => ["/capital_product_applications", ...t ? [t] : []], Sr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Er(t), queryFn: ({ signal: u }) => Cr(t, { signal: u, ...r }), ...n };
};
function nh(t, e) {
  const n = Sr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vr = (t, e) => o.post(
  "/capital_product_applications",
  t,
  e
), Ar = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return vr(a, n);
  }, ...e };
}, rh = (t) => {
  const e = Ar(t);
  return i(e);
}, Dr = (t, e) => o.get(
  "/capital_product_application_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ur = (t) => ["/capital_product_application_snapshots", ...t ? [t] : []], Nr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ur(t), queryFn: ({ signal: u }) => Dr(t, { signal: u, ...r }), ...n };
};
function sh(t, e) {
  const n = Nr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gr = (t, e) => o.post(
  "/net_top_up",
  t,
  e
), Tr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gr(a, n);
  }, ...e };
}, ah = (t) => {
  const e = Tr(t);
  return i(e);
}, Ir = (t, e) => o.get(
  "/capital_product_net_top_up_itemization",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), wr = (t) => ["/capital_product_net_top_up_itemization", ...t ? [t] : []], Qr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? wr(t), queryFn: ({ signal: u }) => Ir(t, { signal: u, ...r }), ...n };
};
function oh(t, e) {
  const n = Qr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Lr = (t, e) => o.get(
  "/capital_product_state_disclosure",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wr = (t) => ["/capital_product_state_disclosure", ...t ? [t] : []], Hr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wr(t), queryFn: ({ signal: u }) => Lr(t, { signal: u, ...r }), ...n };
};
function uh(t, e) {
  const n = Hr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Vr = (t) => o.get(
  "/benchmark_capital_products_ownerships",
  t
), zr = () => ["/benchmark_capital_products_ownerships"], $r = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? zr(), queryFn: ({ signal: a }) => Vr({ signal: a, ...n }), ...e };
};
function ih(t) {
  const e = $r(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Yr = (t, e) => o.get(
  "/capital_product_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xr = (t) => ["/capital_product_repayment_schedule", ...t ? [t] : []], Zr = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xr(t), queryFn: ({ signal: u }) => Yr(t, { signal: u, ...r }), ...n };
};
function ch(t, e) {
  const n = Zr(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jr = (t, e) => o.post(
  "/offered_capital_product_discount",
  t,
  e
), Jr = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return jr(a, n);
  }, ...e };
}, yh = (t) => {
  const e = Jr(t);
  return i(e);
}, ts = (t, e) => o.get(
  "/capital_product_intents",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), es = (t) => ["/capital_product_intents", ...t ? [t] : []], ns = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? es(t), queryFn: ({ signal: u }) => ts(t, { signal: u, ...r }), ...n };
};
function dh(t, e) {
  const n = ns(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const rs = (t, e) => o.post(
  "/capital_product_intents",
  t,
  e
), ss = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return rs(a, n);
  }, ...e };
}, ph = (t) => {
  const e = ss(t);
  return i(e);
}, as = (t, e, n) => o.patch(
  `/capital_product_intents/${t}`,
  e,
  n
), os = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return as(a, u, n);
  }, ...e };
}, _h = (t) => {
  const e = os(t);
  return i(e);
}, us = (t, e) => o.get(
  "/counteroffer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), is = (t) => ["/counteroffer", ...t ? [t] : []], cs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? is(t), queryFn: ({ signal: u }) => us(t, { signal: u, ...r }), ...n };
};
function mh(t, e) {
  const n = cs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ys = (t, e) => o.post(
  "/capital_product_super_applications",
  t,
  e
), ds = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ys(a, n);
  }, ...e };
}, lh = (t) => {
  const e = ds(t);
  return i(e);
}, ps = (t, e) => o.post(
  "/capital_product_super_application_actions",
  t,
  e
), _s = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ps(a, n);
  }, ...e };
}, gh = (t) => {
  const e = _s(t);
  return i(e);
}, ms = (t, e) => o.get(
  "/flex_loan_minimum_repayment_schedule",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ls = (t) => ["/flex_loan_minimum_repayment_schedule", ...t ? [t] : []], gs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ls(t), queryFn: ({ signal: u }) => ms(t, { signal: u, ...r }), ...n };
};
function qh(t, e) {
  const n = gs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qs = (t, e) => o.post(
  "/trigger_run_celtics_checklist",
  t,
  e
), fs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qs(a, n);
  }, ...e };
}, fh = (t) => {
  const e = fs(t);
  return i(e);
}, Os = (t, e) => o.get(
  "/run_ofac_screening_results",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hs = (t) => ["/run_ofac_screening_results", ...t ? [t] : []], Ks = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hs(t), queryFn: ({ signal: u }) => Os(t, { signal: u, ...r }), ...n };
};
function Oh(t, e) {
  const n = Ks(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ps = (t, e, n) => o.patch(
  `/clear_watchlist_hits/${t}`,
  e,
  n
), bs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ps(a, u, n);
  }, ...e };
}, hh = (t) => {
  const e = bs(t);
  return i(e);
}, ks = (t, e) => o.get(
  "/term_loan_prospective_repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), xs = (t) => ["/term_loan_prospective_repayments", ...t ? [t] : []], Ms = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? xs(t), queryFn: ({ signal: u }) => ks(t, { signal: u, ...r }), ...n };
};
function Kh(t, e) {
  const n = Ms(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Fs = (t, e) => o.get(
  "/funding/bank_account_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Rs = (t) => ["/funding/bank_account_info", ...t ? [t] : []], Bs = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Rs(t), queryFn: ({ signal: u }) => Fs(t, { signal: u, ...r }), ...n };
};
function Ph(t, e) {
  const n = Bs(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Cs = (t, e) => o.post(
  "/funding/bank_account_info",
  t,
  e
), Es = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cs(a, n);
  }, ...e };
}, bh = (t) => {
  const e = Es(t);
  return i(e);
}, Ss = (t, e, n) => o.patch(
  `/funding/bank_account_info/${t}`,
  e,
  n
), vs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ss(a, u, n);
  }, ...e };
}, kh = (t) => {
  const e = vs(t);
  return i(e);
}, As = (t, e) => o.get(
  "/funding/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ds = (t) => ["/funding/accounts", ...t ? [t] : []], Us = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ds(t), queryFn: ({ signal: u }) => As(t, { signal: u, ...r }), ...n };
};
function xh(t, e) {
  const n = Us(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ns = (t, e) => o.post(
  "/funding/accounts",
  t,
  e
), Gs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ns(a, n);
  }, ...e };
}, Mh = (t) => {
  const e = Gs(t);
  return i(e);
}, Ts = (t, e) => o.get(
  "/funding/plaid_linkage_with_investigation",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Is = (t) => ["/funding/plaid_linkage_with_investigation", ...t ? [t] : []], ws = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Is(t), queryFn: ({ signal: u }) => Ts(t, { signal: u, ...r }), ...n };
};
function Fh(t, e) {
  const n = ws(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Qs = (t, e) => o.post(
  "/funding/plaid_linkage_with_investigation",
  t,
  e
), Ls = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qs(a, n);
  }, ...e };
}, Rh = (t) => {
  const e = Ls(t);
  return i(e);
}, Ws = (t, e, n) => o.patch(
  `/persona_fallback_inquiries/${t}`,
  e,
  n
), Hs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ws(a, u, n);
  }, ...e };
}, Bh = (t) => {
  const e = Hs(t);
  return i(e);
}, Vs = (t, e) => o.get(
  "/kyc_retry_fields",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zs = (t) => ["/kyc_retry_fields", ...t ? [t] : []], $s = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zs(t), queryFn: ({ signal: u }) => Vs(t, { signal: u, ...r }), ...n };
};
function Ch(t, e) {
  const n = $s(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ys = (t, e) => o.post(
  "/kyc_retry",
  t,
  e
), Xs = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ys(a, n);
  }, ...e };
}, Eh = (t) => {
  const e = Xs(t);
  return i(e);
}, Zs = (t, e) => o.get(
  "/onboarding/states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), js = (t) => ["/onboarding/states", ...t ? [t] : []], Js = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? js(t), queryFn: ({ signal: u }) => Zs(t, { signal: u, ...r }), ...n };
};
function Sh(t, e) {
  const n = Js(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ta = (t, e) => o.get(
  "/onboarding/info_needed",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ea = (t) => ["/onboarding/info_needed", ...t ? [t] : []], na = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ea(t), queryFn: ({ signal: u }) => ta(t, { signal: u, ...r }), ...n };
};
function vh(t, e) {
  const n = na(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ra = (t, e) => o.get(
  "/business_identities",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), sa = (t) => ["/business_identities", ...t ? [t] : []], aa = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? sa(t), queryFn: ({ signal: u }) => ra(t, { signal: u, ...r }), ...n };
};
function Ah(t, e) {
  const n = aa(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const oa = (t, e, n) => o.patch(
  `/business_identities/${t}`,
  e,
  n
), ua = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return oa(a, u, n);
  }, ...e };
}, Dh = (t) => {
  const e = ua(t);
  return i(e);
}, ia = (t, e) => o.get(
  "/owners",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ca = (t) => ["/owners", ...t ? [t] : []], ya = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ca(t), queryFn: ({ signal: u }) => ia(t, { signal: u, ...r }), ...n };
};
function Uh(t, e) {
  const n = ya(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const da = (t, e, n) => o.patch(
  `/owners/${t}`,
  e,
  n
), pa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return da(a, u, n);
  }, ...e };
}, Nh = (t) => {
  const e = pa(t);
  return i(e);
}, _a = (t, e) => o.post(
  "/business",
  t,
  e
), ma = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return _a(a, n);
  }, ...e };
}, Gh = (t) => {
  const e = ma(t);
  return i(e);
}, la = (t, e) => o.post(
  "/merchant_bank/business",
  t,
  e
), ga = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return la(a, n);
  }, ...e };
}, Th = (t) => {
  const e = ga(t);
  return i(e);
}, qa = (t) => o.post(
  "/amazon_redirect_url",
  void 0,
  t
), fa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => qa(n), ...e };
}, Ih = (t) => {
  const e = fa(t);
  return i(e);
}, Oa = (t, e) => o.post(
  "/spd",
  t,
  e
), ha = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Oa(a, n);
  }, ...e };
}, wh = (t) => {
  const e = ha(t);
  return i(e);
}, Ka = (t, e) => o.post(
  "/capital_product_business",
  t,
  e
), Pa = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ka(a, n);
  }, ...e };
}, Qh = (t) => {
  const e = Pa(t);
  return i(e);
}, ba = (t, e) => o.patch(
  "/flex_loan_age",
  t,
  e
), ka = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ba(a, n);
  }, ...e };
}, Lh = (t) => {
  const e = ka(t);
  return i(e);
}, xa = (t, e) => o.patch(
  "/flex_loan_ownership",
  t,
  e
), Ma = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return xa(a, n);
  }, ...e };
}, Wh = (t) => {
  const e = Ma(t);
  return i(e);
}, Fa = (t, e) => o.patch(
  "/flex_loan_fund",
  t,
  e
), Ra = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Fa(a, n);
  }, ...e };
}, Hh = (t) => {
  const e = Ra(t);
  return i(e);
}, Ba = (t, e) => o.post(
  "/setup_amazon_top_up",
  void 0,
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ca = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { params: a } = s ?? {};
    return Ba(a, n);
  }, ...e };
}, Vh = (t) => {
  const e = Ca(t);
  return i(e);
}, Ea = (t, e) => o.get(
  "/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Sa = (t) => ["/cards", ...t ? [t] : []], va = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sa(t), queryFn: ({ signal: u }) => Ea(t, { signal: u, ...r }), ...n };
};
function zh(t, e) {
  const n = va(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Aa = (t, e) => o.post(
  "/cards",
  t,
  e
), Da = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Aa(a, n);
  }, ...e };
}, $h = (t) => {
  const e = Da(t);
  return i(e);
}, Ua = (t, e) => o.get(
  "/businesses/products",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Na = (t) => ["/businesses/products", ...t ? [t] : []], Ga = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Na(t), queryFn: ({ signal: u }) => Ua(t, { signal: u, ...r }), ...n };
};
function Yh(t, e) {
  const n = Ga(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ta = (t, e) => o.post(
  "/business/auth_link",
  t,
  e
), Ia = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ta(a, n);
  }, ...e };
}, Xh = (t) => {
  const e = Ia(t);
  return i(e);
}, wa = (t, e) => o.get(
  "/document_upload/group",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qa = (t) => ["/document_upload/group", ...t ? [t] : []], La = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qa(t), queryFn: ({ signal: u }) => wa(t, { signal: u, ...r }), ...n };
};
function Zh(t, e) {
  const n = La(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wa = (t, e) => o.post(
  "/document_upload/group",
  t,
  e
), Ha = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Wa(a, n);
  }, ...e };
}, jh = (t) => {
  const e = Ha(t);
  return i(e);
}, Va = (t, e) => o.get(
  "/document_upload/list",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), za = (t) => ["/document_upload/list", ...t ? [t] : []], $a = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? za(t), queryFn: ({ signal: u }) => Va(t, { signal: u, ...r }), ...n };
};
function Jh(t, e) {
  const n = $a(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ya = (t, e) => o.get(
  "/restriction",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xa = (t) => ["/restriction", ...t ? [t] : []], Za = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xa(t), queryFn: ({ signal: u }) => Ya(t, { signal: u, ...r }), ...n };
};
function tK(t, e) {
  const n = Za(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ja = (t, e) => o.post(
  "/restriction",
  t,
  e
), Ja = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ja(a, n);
  }, ...e };
}, eK = (t) => {
  const e = Ja(t);
  return i(e);
}, to = (t, e) => o.get(
  "/restrictions/details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), eo = (t) => ["/restrictions/details", ...t ? [t] : []], no = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? eo(t), queryFn: ({ signal: u }) => to(t, { signal: u, ...r }), ...n };
};
function nK(t, e) {
  const n = no(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ro = (t, e) => o.get(
  "/liens",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), so = (t) => ["/liens", ...t ? [t] : []], ao = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? so(t), queryFn: ({ signal: u }) => ro(t, { signal: u, ...r }), ...n };
};
function rK(t, e) {
  const n = ao(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const oo = (t, e, n) => o.patch(
  `/liens/${t}`,
  e,
  n
), uo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return oo(a, u, n);
  }, ...e };
}, sK = (t) => {
  const e = uo(t);
  return i(e);
}, io = (t, e) => o.post(
  "/opt_ins/trigger",
  t,
  e
), co = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return io(a, n);
  }, ...e };
}, aK = (t) => {
  const e = co(t);
  return i(e);
}, yo = (t, e) => o.get(
  "/businesses/daily_sales_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), po = (t) => ["/businesses/daily_sales_records", ...t ? [t] : []], _o = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? po(t), queryFn: ({ signal: u }) => yo(t, { signal: u, ...r }), ...n };
};
function oK(t, e) {
  const n = _o(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const mo = (t, e) => o.get(
  "/parafinder/businesses",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), lo = (t) => ["/parafinder/businesses", ...t ? [t] : []], go = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? lo(t), queryFn: ({ signal: u }) => mo(t, { signal: u, ...r }), ...n };
};
function uK(t, e) {
  const n = go(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const qo = (t) => o.get(
  "/parafinder/business_types",
  t
), fo = () => ["/parafinder/business_types"], Oo = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? fo(), queryFn: ({ signal: a }) => qo({ signal: a, ...n }), ...e };
};
function iK(t) {
  const e = Oo(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const ho = (t, e) => o.get(
  "/parafinder/summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ko = (t) => ["/parafinder/summary", ...t ? [t] : []], Po = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ko(t), queryFn: ({ signal: u }) => ho(t, { signal: u, ...r }), ...n };
};
function cK(t, e) {
  const n = Po(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const bo = (t, e) => o.get(
  "/parafinderV2/capital_info_summary",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ko = (t) => ["/parafinderV2/capital_info_summary", ...t ? [t] : []], xo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ko(t), queryFn: ({ signal: u }) => bo(t, { signal: u, ...r }), ...n };
};
function yK(t, e) {
  const n = xo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Mo = (t, e) => o.get(
  `/merchant_bank/accounts/${t}`,
  e
), Fo = (t) => [`/merchant_bank/accounts/${t}`], Ro = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fo(t), queryFn: ({ signal: u }) => Mo(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function dK(t, e) {
  const n = Ro(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Bo = (t, e) => o.get(
  `/merchant_bank/accounts/${t}/balances`,
  e
), Co = (t) => [`/merchant_bank/accounts/${t}/balances`], Eo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Co(t), queryFn: ({ signal: u }) => Bo(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function pK(t, e) {
  const n = Eo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const So = (t, e) => o.get(
  "/merchant_bank/accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), vo = (t) => ["/merchant_bank/accounts", ...t ? [t] : []], Ao = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? vo(t), queryFn: ({ signal: u }) => So(t, { signal: u, ...r }), ...n };
};
function _K(t, e) {
  const n = Ao(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Do = (t, e) => o.post(
  "/merchant_bank/accounts",
  t,
  e
), Uo = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Do(a, n);
  }, ...e };
}, mK = (t) => {
  const e = Uo(t);
  return i(e);
}, No = (t, e) => o.post(
  "/merchant_bank/inner_transfers",
  t,
  e
), Go = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return No(a, n);
  }, ...e };
}, lK = (t) => {
  const e = Go(t);
  return i(e);
}, To = (t, e, n) => o.get(
  `/merchant_bank/transactions/${t}`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Io = (t, e) => [`/merchant_bank/transactions/${t}`, ...e ? [e] : []], wo = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Io(t, e), queryFn: ({ signal: y }) => To(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function gK(t, e, n) {
  const r = wo(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Qo = (t, e) => o.get(
  "/merchant_bank/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lo = (t) => ["/merchant_bank/transactions", ...t ? [t] : []], Wo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lo(t), queryFn: ({ signal: u }) => Qo(t, { signal: u, ...r }), ...n };
};
function qK(t, e) {
  const n = Wo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ho = (t) => o.get(
  "/merchant_bank/transfer_fees",
  t
), Vo = () => ["/merchant_bank/transfer_fees"], zo = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Vo(), queryFn: ({ signal: a }) => Ho({ signal: a, ...n }), ...e };
};
function fK(t) {
  const e = zo(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const $o = (t, e) => o.get(
  "/merchant_bank/transfer_limits",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Yo = (t) => ["/merchant_bank/transfer_limits", ...t ? [t] : []], Xo = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Yo(t), queryFn: ({ signal: u }) => $o(t, { signal: u, ...r }), ...n };
};
function OK(t, e) {
  const n = Xo(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Zo = (t) => o.get(
  "/merchant_bank/interest_details",
  t
), jo = () => ["/merchant_bank/interest_details"], Jo = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? jo(), queryFn: ({ signal: a }) => Zo({ signal: a, ...n }), ...e };
};
function hK(t) {
  const e = Jo(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const tu = (t, e) => o.get(
  "/merchant_bank/recipients",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), eu = (t) => ["/merchant_bank/recipients", ...t ? [t] : []], nu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? eu(t), queryFn: ({ signal: u }) => tu(t, { signal: u, ...r }), ...n };
};
function KK(t, e) {
  const n = nu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ru = (t, e) => o.post(
  "/merchant_bank/recipients",
  t,
  e
), su = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ru(a, n);
  }, ...e };
}, PK = (t) => {
  const e = su(t);
  return i(e);
}, au = (t, e) => o.get(
  `/merchant_bank/recipients/${t}`,
  e
), ou = (t) => [`/merchant_bank/recipients/${t}`], uu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ou(t), queryFn: ({ signal: u }) => au(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function bK(t, e) {
  const n = uu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const iu = (t, e) => o.delete(
  `/merchant_bank/recipients/${t}`,
  e
), cu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return iu(a, n);
  }, ...e };
}, kK = (t) => {
  const e = cu(t);
  return i(e);
}, yu = (t, e, n) => o.patch(
  `/merchant_bank/recipients/${t}`,
  e,
  n
), du = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return yu(a, u, n);
  }, ...e };
}, xK = (t) => {
  const e = du(t);
  return i(e);
}, pu = (t, e, n) => o.post(
  `/merchant_bank/recipients/${t}/payment_rails`,
  e,
  n
), _u = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return pu(a, u, n);
  }, ...e };
}, MK = (t) => {
  const e = _u(t);
  return i(e);
}, mu = (t, e) => o.post(
  "/merchant_bank/recipient_transfers",
  t,
  e
), lu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return mu(a, n);
  }, ...e };
}, FK = (t) => {
  const e = lu(t);
  return i(e);
}, gu = (t, e, n) => o.patch(
  `/merchant_bank/recipient_transfers/${t}`,
  e,
  n
), qu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return gu(a, u, n);
  }, ...e };
}, RK = (t) => {
  const e = qu(t);
  return i(e);
}, fu = (t, e) => o.get(
  "/merchant_bank/cards",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ou = (t) => ["/merchant_bank/cards", ...t ? [t] : []], hu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ou(t), queryFn: ({ signal: u }) => fu(t, { signal: u, ...r }), ...n };
};
function BK(t, e) {
  const n = hu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ku = (t, e) => o.post(
  "/merchant_bank/cards",
  t,
  e
), Pu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ku(a, n);
  }, ...e };
}, CK = (t) => {
  const e = Pu(t);
  return i(e);
}, bu = (t, e, n) => o.patch(
  `/merchant_bank/card/cardholders/${t}`,
  e,
  n
), ku = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return bu(a, u, n);
  }, ...e };
}, EK = (t) => {
  const e = ku(t);
  return i(e);
}, xu = (t, e) => o.get(
  "/merchant_bank/card/cardholders",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Mu = (t) => ["/merchant_bank/card/cardholders", ...t ? [t] : []], Fu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mu(t), queryFn: ({ signal: u }) => xu(t, { signal: u, ...r }), ...n };
};
function SK(t, e) {
  const n = Fu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ru = (t, e) => o.post(
  "/merchant_bank/card/activate",
  t,
  e
), Bu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ru(a, n);
  }, ...e };
}, vK = (t) => {
  const e = Bu(t);
  return i(e);
}, Cu = (t, e) => o.get(
  "/merchant_bank/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Eu = (t) => ["/merchant_bank/card/access_token", ...t ? [t] : []], Su = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Eu(t), queryFn: ({ signal: u }) => Cu(t, { signal: u, ...r }), ...n };
};
function AK(t, e) {
  const n = Su(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const vu = (t, e) => o.post(
  "/merchant_bank/card/apple_pay",
  t,
  e
), Au = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return vu(a, n);
  }, ...e };
}, DK = (t) => {
  const e = Au(t);
  return i(e);
}, Du = (t, e) => o.get(
  "/merchant_bank/card/cardholder_access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Uu = (t) => ["/merchant_bank/card/cardholder_access_token", ...t ? [t] : []], Nu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Uu(t), queryFn: ({ signal: u }) => Du(t, { signal: u, ...r }), ...n };
};
function UK(t, e) {
  const n = Nu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Gu = (t, e) => o.post(
  "/merchant_bank/card/state_transition",
  t,
  e
), Tu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gu(a, n);
  }, ...e };
}, NK = (t) => {
  const e = Tu(t);
  return i(e);
}, Iu = (t, e) => o.post(
  "/merchant_bank/card/google_pay",
  t,
  e
), wu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Iu(a, n);
  }, ...e };
}, GK = (t) => {
  const e = wu(t);
  return i(e);
}, Qu = (t, e) => o.get(
  "/merchant_bank/card/disputes",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Lu = (t) => ["/merchant_bank/card/disputes", ...t ? [t] : []], Wu = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Lu(t), queryFn: ({ signal: u }) => Qu(t, { signal: u, ...r }), ...n };
};
function TK(t, e) {
  const n = Wu(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hu = (t, e) => o.post(
  "/merchant_bank/card/disputes",
  t,
  e
), Vu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hu(a, n);
  }, ...e };
}, IK = (t) => {
  const e = Vu(t);
  return i(e);
}, zu = (t, e) => o.post(
  "/merchant_bank/card/dispute/state_transition",
  t,
  e
), $u = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return zu(a, n);
  }, ...e };
}, wK = (t) => {
  const e = $u(t);
  return i(e);
}, Yu = (t, e) => o.post(
  "/merchant_bank/card/dispute/chargeback_credit",
  t,
  e
), Xu = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yu(a, n);
  }, ...e };
}, QK = (t) => {
  const e = Xu(t);
  return i(e);
}, Zu = (t, e) => o.get(
  "/merchant_bank/external_accounts/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ju = (t) => ["/merchant_bank/external_accounts/plaid_link_token", ...t ? [t] : []], Ju = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ju(t), queryFn: ({ signal: u }) => Zu(t, { signal: u, ...r }), ...n };
};
function LK(t, e) {
  const n = Ju(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ti = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}/plaid_link_token`,
  e
), ei = (t) => [`/merchant_bank/external_accounts/${t}/plaid_link_token`], ni = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ei(t), queryFn: ({ signal: u }) => ti(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function WK(t, e) {
  const n = ni(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ri = (t, e) => o.get(
  "/merchant_bank/external_accounts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), si = (t) => ["/merchant_bank/external_accounts", ...t ? [t] : []], ai = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? si(t), queryFn: ({ signal: u }) => ri(t, { signal: u, ...r }), ...n };
};
function HK(t, e) {
  const n = ai(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const oi = (t, e) => o.post(
  "/merchant_bank/external_accounts",
  t,
  e
), ui = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return oi(a, n);
  }, ...e };
}, VK = (t) => {
  const e = ui(t);
  return i(e);
}, ii = (t, e) => o.get(
  `/merchant_bank/external_accounts/${t}`,
  e
), ci = (t) => [`/merchant_bank/external_accounts/${t}`], yi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ci(t), queryFn: ({ signal: u }) => ii(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function zK(t, e) {
  const n = yi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const di = (t, e) => o.delete(
  `/merchant_bank/external_accounts/${t}`,
  e
), pi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return di(a, n);
  }, ...e };
}, $K = (t) => {
  const e = pi(t);
  return i(e);
}, _i = (t, e, n) => o.patch(
  `/merchant_bank/external_accounts/${t}`,
  e,
  n
), mi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return _i(a, u, n);
  }, ...e };
}, YK = (t) => {
  const e = mi(t);
  return i(e);
}, li = (t, e) => o.post(
  "/merchant_bank/external_bank_transfers",
  t,
  e
), gi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return li(a, n);
  }, ...e };
}, XK = (t) => {
  const e = gi(t);
  return i(e);
}, qi = (t, e, n) => o.patch(
  `/merchant_bank/external_bank_transfers/${t}`,
  e,
  n
), fi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return qi(a, u, n);
  }, ...e };
}, ZK = (t) => {
  const e = fi(t);
  return i(e);
}, Oi = (t, e) => o.get(
  "/debts",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hi = (t) => ["/debts", ...t ? [t] : []], Ki = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hi(t), queryFn: ({ signal: u }) => Oi(t, { signal: u, ...r }), ...n };
};
function jK(t, e) {
  const n = Ki(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pi = (t, e) => o.post(
  "/debts",
  t,
  e
), bi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Pi(a, n);
  }, ...e };
}, JK = (t) => {
  const e = bi(t);
  return i(e);
}, ki = (t, e) => o.post(
  "/debts/check_complete",
  t,
  e
), xi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ki(a, n);
  }, ...e };
}, tP = (t) => {
  const e = xi(t);
  return i(e);
}, Mi = (t, e) => o.get(
  "/debt_checks",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Fi = (t) => ["/debt_checks", ...t ? [t] : []], Ri = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Fi(t), queryFn: ({ signal: u }) => Mi(t, { signal: u, ...r }), ...n };
};
function eP(t, e) {
  const n = Ri(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Bi = (t, e) => o.post(
  "/card/close_account",
  t,
  e
), Ci = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Bi(a, n);
  }, ...e };
}, nP = (t) => {
  const e = Ci(t);
  return i(e);
}, Ei = (t, e) => o.get(
  "/financial_reviews",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Si = (t) => ["/financial_reviews", ...t ? [t] : []], vi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Si(t), queryFn: ({ signal: u }) => Ei(t, { signal: u, ...r }), ...n };
};
function rP(t, e) {
  const n = vi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ai = (t, e) => o.post(
  "/financial_reviews",
  t,
  e
), Di = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ai(a, n);
  }, ...e };
}, sP = (t) => {
  const e = Di(t);
  return i(e);
}, Ui = (t, e) => o.get(
  "/accounting_info",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Ni = (t) => ["/accounting_info", ...t ? [t] : []], Gi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ni(t), queryFn: ({ signal: u }) => Ui(t, { signal: u, ...r }), ...n };
};
function aP(t, e) {
  const n = Gi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ti = (t, e) => o.post(
  "/accounting_info",
  t,
  e
), Ii = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ti(a, n);
  }, ...e };
}, oP = (t) => {
  const e = Ii(t);
  return i(e);
}, wi = (t, e) => o.post(
  "/tax_records",
  t,
  e
), Qi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return wi(a, n);
  }, ...e };
}, uP = (t) => {
  const e = Qi(t);
  return i(e);
}, Li = (t, e) => o.get(
  "/officer_subsidiary_records",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Wi = (t) => ["/officer_subsidiary_records", ...t ? [t] : []], Hi = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wi(t), queryFn: ({ signal: u }) => Li(t, { signal: u, ...r }), ...n };
};
function iP(t, e) {
  const n = Hi(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Vi = (t, e) => o.post(
  "/officer_subsidiary_records",
  t,
  e
), zi = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Vi(a, n);
  }, ...e };
}, cP = (t) => {
  const e = zi(t);
  return i(e);
}, $i = (t) => o.get(
  "/analytics/cube_token",
  t
), Yi = () => ["/analytics/cube_token"], Xi = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Yi(), queryFn: ({ signal: a }) => $i({ signal: a, ...n }), ...e };
};
function yP(t) {
  const e = Xi(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Zi = (t, e) => o.get(
  "/logs/api_request",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ji = (t) => ["/logs/api_request", ...t ? [t] : []], Ji = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ji(t), queryFn: ({ signal: u }) => Zi(t, { signal: u, ...r }), ...n };
};
function dP(t, e) {
  const n = Ji(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const tc = (t) => o.get(
  "/marketing/offer_csvs",
  t
), ec = () => ["/marketing/offer_csvs"], nc = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ec(), queryFn: ({ signal: a }) => tc({ signal: a, ...n }), ...e };
};
function pP(t) {
  const e = nc(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const rc = (t) => o.post(
  "/marketing/offer_csvs/generate",
  void 0,
  t
), sc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => rc(n), ...e };
}, _P = (t) => {
  const e = sc(t);
  return i(e);
}, ac = (t, e) => o.get(
  "/marketing/emails/batch",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), oc = (t) => ["/marketing/emails/batch", ...t ? [t] : []], uc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? oc(t), queryFn: ({ signal: u }) => ac(t, { signal: u, ...r }), ...n };
};
function mP(t, e) {
  const n = uc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ic = (t, e) => o.post(
  "/marketing/emails/batch",
  t,
  e
), cc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ic(a, n);
  }, ...e };
}, lP = (t) => {
  const e = cc(t);
  return i(e);
}, yc = (t, e) => o.get(
  "/marketing/emails/scheduled_marketing_config",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dc = (t) => ["/marketing/emails/scheduled_marketing_config", ...t ? [t] : []], pc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dc(t), queryFn: ({ signal: u }) => yc(t, { signal: u, ...r }), ...n };
};
function gP(t, e) {
  const n = pc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _c = (t, e) => o.post(
  "/marketing/emails/scheduled_marketing_config",
  t,
  e
), mc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return _c(a, n);
  }, ...e };
}, qP = (t) => {
  const e = mc(t);
  return i(e);
}, lc = (t, e) => o.get(
  "/businesses/notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), gc = (t) => ["/businesses/notifications", ...t ? [t] : []], qc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? gc(t), queryFn: ({ signal: u }) => lc(t, { signal: u, ...r }), ...n };
};
function fP(t, e) {
  const n = qc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const fc = (t, e) => o.post(
  "/businesses/notifications",
  t,
  e
), Oc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return fc(a, n);
  }, ...e };
}, OP = (t) => {
  const e = Oc(t);
  return i(e);
}, hc = (t, e) => o.post(
  "/terms_of_service_mark_shown",
  t,
  e
), Kc = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return hc(a, n);
  }, ...e };
}, hP = (t) => {
  const e = Kc(t);
  return i(e);
}, Pc = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/applications`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), bc = (t, e) => [`/partner_dashboard/business_details_page/${t}/applications`, ...e ? [e] : []], kc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? bc(t, e), queryFn: ({ signal: y }) => Pc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function KP(t, e, n) {
  const r = kc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const xc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/bank_info`,
  e
), Mc = (t) => [`/partner_dashboard/business_details_page/${t}/bank_info`], Fc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Mc(t), queryFn: ({ signal: u }) => xc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function PP(t, e) {
  const n = Fc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Rc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/business_info`,
  e
), Bc = (t) => [`/partner_dashboard/business_details_page/${t}/business_info`], Cc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Bc(t), queryFn: ({ signal: u }) => Rc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function bP(t, e) {
  const n = Cc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ec = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_products`,
  e
), Sc = (t) => [`/partner_dashboard/business_details_page/${t}/funded_products`], vc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Sc(t), queryFn: ({ signal: u }) => Ec(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function kP(t, e) {
  const n = vc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ac = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/funded_product_details`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Dc = (t, e) => [`/partner_dashboard/business_details_page/${t}/funded_product_details`, ...e ? [e] : []], Uc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Dc(t, e), queryFn: ({ signal: y }) => Ac(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function xP(t, e, n) {
  const r = Uc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Nc = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/payment_progress`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), Gc = (t, e) => [`/partner_dashboard/business_details_page/${t}/payment_progress`, ...e ? [e] : []], Tc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? Gc(t, e), queryFn: ({ signal: y }) => Nc(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function MP(t, e, n) {
  const r = Tc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Ic = (t, e, n) => o.get(
  `/partner_dashboard/business_details_page/${t}/offers`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), wc = (t, e) => [`/partner_dashboard/business_details_page/${t}/offers`, ...e ? [e] : []], Qc = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? wc(t, e), queryFn: ({ signal: y }) => Ic(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function FP(t, e, n) {
  const r = Qc(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const Lc = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/owner_info`,
  e
), Wc = (t) => [`/partner_dashboard/business_details_page/${t}/owner_info`], Hc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Wc(t), queryFn: ({ signal: u }) => Lc(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function RP(t, e) {
  const n = Hc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Vc = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows/count", ...t ? [t] : []], $c = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zc(t), queryFn: ({ signal: u }) => Vc(t, { signal: u, ...r }), ...n };
};
function BP(t, e) {
  const n = $c(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yc = (t, e) => o.get(
  "/partner_dashboard/businesses_page/businesses_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xc = (t) => ["/partner_dashboard/businesses_page/businesses_table_rows", ...t ? [t] : []], Zc = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xc(t), queryFn: ({ signal: u }) => Yc(t, { signal: u, ...r }), ...n };
};
function CP(t, e) {
  const n = Zc(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jc = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Jc = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows/count", ...t ? [t] : []], ty = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jc(t), queryFn: ({ signal: u }) => jc(t, { signal: u, ...r }), ...n };
};
function EP(t, e) {
  const n = ty(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ey = (t, e) => o.get(
  "/partner_dashboard/capital_tab/funded_products_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ny = (t) => ["/partner_dashboard/capital_tab/funded_products_table_rows", ...t ? [t] : []], ry = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ny(t), queryFn: ({ signal: u }) => ey(t, { signal: u, ...r }), ...n };
};
function SP(t, e) {
  const n = ry(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const sy = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), ay = (t) => ["/partner_dashboard/capital_tab/applications_table_rows/count", ...t ? [t] : []], oy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ay(t), queryFn: ({ signal: u }) => sy(t, { signal: u, ...r }), ...n };
};
function vP(t, e) {
  const n = oy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const uy = (t, e) => o.get(
  "/partner_dashboard/capital_tab/applications_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), iy = (t) => ["/partner_dashboard/capital_tab/applications_table_rows", ...t ? [t] : []], cy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? iy(t), queryFn: ({ signal: u }) => uy(t, { signal: u, ...r }), ...n };
};
function AP(t, e) {
  const n = cy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yy = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows/count",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dy = (t) => ["/partner_dashboard/capital_tab/offers_table_rows/count", ...t ? [t] : []], py = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dy(t), queryFn: ({ signal: u }) => yy(t, { signal: u, ...r }), ...n };
};
function DP(t, e) {
  const n = py(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _y = (t, e) => o.get(
  "/partner_dashboard/capital_tab/offers_table_rows",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), my = (t) => ["/partner_dashboard/capital_tab/offers_table_rows", ...t ? [t] : []], ly = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? my(t), queryFn: ({ signal: u }) => _y(t, { signal: u, ...r }), ...n };
};
function UP(t, e) {
  const n = ly(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const gy = (t) => o.get(
  "/partner_dashboard/platform_tab/partner_support_info",
  t
), qy = () => ["/partner_dashboard/platform_tab/partner_support_info"], fy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? qy(), queryFn: ({ signal: a }) => gy({ signal: a, ...n }), ...e };
};
function NP(t) {
  const e = fy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Oy = (t, e) => o.get(
  `/partner_dashboard/business_details_page/${t}/offer_url`,
  e
), hy = (t) => [`/partner_dashboard/business_details_page/${t}/offer_url`], Ky = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hy(t), queryFn: ({ signal: u }) => Oy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function GP(t, e) {
  const n = Ky(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Py = (t) => o.get(
  "/partner_dashboard/partner_event/subscriptions/list_user_subscriptions",
  t
), by = () => ["/partner_dashboard/partner_event/subscriptions/list_user_subscriptions"], ky = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? by(), queryFn: ({ signal: a }) => Py({ signal: a, ...n }), ...e };
};
function TP(t) {
  const e = ky(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const xy = (t, e) => o.get(
  "/partner_dashboard/partner_event/web_notifications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), My = (t) => ["/partner_dashboard/partner_event/web_notifications", ...t ? [t] : []], Fy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? My(t), queryFn: ({ signal: u }) => xy(t, { signal: u, ...r }), ...n };
};
function IP(t, e) {
  const n = Fy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Ry = (t, e) => o.post(
  "/partner_dashboard/partner_event/web_notifications/mark_all_read",
  t,
  e
), By = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ry(a, n);
  }, ...e };
}, wP = (t) => {
  const e = By(t);
  return i(e);
}, Cy = (t, e) => o.post(
  "/partner_dashboard/partner_event/web_notifications/mark_one_read",
  t,
  e
), Ey = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cy(a, n);
  }, ...e };
}, QP = (t) => {
  const e = Ey(t);
  return i(e);
}, Sy = (t, e) => o.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions",
  t,
  e
), vy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Sy(a, n);
  }, ...e };
}, LP = (t) => {
  const e = vy(t);
  return i(e);
}, Ay = (t, e) => o.patch(
  "/partner_dashboard/partner_event/subscriptions/update_user_subscriptions_for_event_type",
  t,
  e
), Dy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Ay(a, n);
  }, ...e };
}, WP = (t) => {
  const e = Dy(t);
  return i(e);
}, Uy = (t) => o.get(
  "/partner_dashboard/crm/integration",
  t
), Ny = () => ["/partner_dashboard/crm/integration"], Gy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Ny(), queryFn: ({ signal: a }) => Uy({ signal: a, ...n }), ...e };
};
function HP(t) {
  const e = Gy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Ty = (t) => o.get(
  "/partner_dashboard/valid_object_id_prefixes",
  t
), Iy = () => ["/partner_dashboard/valid_object_id_prefixes"], wy = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Iy(), queryFn: ({ signal: a }) => Ty({ signal: a, ...n }), ...e };
};
function VP(t) {
  const e = wy(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Qy = (t, e) => o.get(
  `/partner_dashboard/business/owner_auth/${t}`,
  e
), Ly = (t) => [`/partner_dashboard/business/owner_auth/${t}`], Wy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Ly(t), queryFn: ({ signal: u }) => Qy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function zP(t, e) {
  const n = Wy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Hy = (t, e) => o.post(
  "/v1/sandbox/generate_event/capital_product_offer/created",
  t,
  e
), Vy = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Hy(a, n);
  }, ...e };
}, $P = (t) => {
  const e = Vy(t);
  return i(e);
}, zy = (t, e) => o.post(
  "/v1/sandbox/generate_event/capital_product_offer/closed",
  t,
  e
), $y = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return zy(a, n);
  }, ...e };
}, YP = (t) => {
  const e = $y(t);
  return i(e);
}, Yy = (t, e) => o.get(
  "/partner_dashboard/webhook_logs/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Xy = (t) => ["/partner_dashboard/webhook_logs/events", ...t ? [t] : []], Zy = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Xy(t), queryFn: ({ signal: u }) => Yy(t, { signal: u, ...r }), ...n };
};
function XP(t, e) {
  const n = Zy(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const jy = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}`,
  e
), Jy = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}`], td = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Jy(t), queryFn: ({ signal: u }) => jy(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ZP(t, e) {
  const n = td(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ed = (t, e, n) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), nd = (t, e) => [`/partner_dashboard/webhook_logs/event_info/${t}/attempts`, ...e ? [e] : []], rd = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? nd(t, e), queryFn: ({ signal: y }) => ed(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function jP(t, e, n) {
  const r = rd(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const sd = (t, e) => o.get(
  `/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`,
  e
), ad = (t) => [`/partner_dashboard/webhook_logs/event_info/${t}/delivery_attempts`], od = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? ad(t), queryFn: ({ signal: u }) => sd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function JP(t, e) {
  const n = od(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ud = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend",
  t,
  e
), id = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return ud(a, n);
  }, ...e };
}, tb = (t) => {
  const e = id(t);
  return i(e);
}, cd = (t, e) => o.post(
  "/partner_dashboard/webhook_logs/resend_event",
  t,
  e
), yd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return cd(a, n);
  }, ...e };
}, eb = (t) => {
  const e = yd(t);
  return i(e);
}, dd = (t, e) => o.get(
  "/partner_dashboard/crm/events",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), pd = (t) => ["/partner_dashboard/crm/events", ...t ? [t] : []], _d = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? pd(t), queryFn: ({ signal: u }) => dd(t, { signal: u, ...r }), ...n };
};
function nb(t, e) {
  const n = _d(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const md = (t, e, n) => o.get(
  `/partner_dashboard/crm/event_info/${t}/attempts`,
  {
    ...n,
    params: { ...e, ...n == null ? void 0 : n.params }
  }
), ld = (t, e) => [`/partner_dashboard/crm/event_info/${t}/attempts`, ...e ? [e] : []], gd = (t, e, n) => {
  const { query: r, axios: s } = n ?? {};
  return { queryKey: (r == null ? void 0 : r.queryKey) ?? ld(t, e), queryFn: ({ signal: y }) => md(t, e, { signal: y, ...s }), enabled: !!t, ...r };
};
function rb(t, e, n) {
  const r = gd(t, e, n), s = c(r);
  return s.queryKey = r.queryKey, s;
}
const qd = (t, e) => o.post(
  "/partner_dashboard/crm/resend",
  t,
  e
), fd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qd(a, n);
  }, ...e };
}, sb = (t) => {
  const e = fd(t);
  return i(e);
}, Od = (t, e) => o.get(
  `/partner_dashboard/crm/event_info/${t}`,
  e
), hd = (t) => [`/partner_dashboard/crm/event_info/${t}`], Kd = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hd(t), queryFn: ({ signal: u }) => Od(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function ab(t, e) {
  const n = Kd(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pd = (t) => o.post(
  "/partner_dashboard/crm/trigger_sync",
  void 0,
  t
), bd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Pd(n), ...e };
}, ob = (t) => {
  const e = bd(t);
  return i(e);
}, kd = (t) => o.get(
  "/partner_dashboard/crm/sync",
  t
), xd = () => ["/partner_dashboard/crm/sync"], Md = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? xd(), queryFn: ({ signal: a }) => kd({ signal: a, ...n }), ...e };
};
function ub(t) {
  const e = Md(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Fd = (t) => o.post(
  "/partner_dashboard/crm/sync",
  void 0,
  t
), Rd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => Fd(n), ...e };
}, ib = (t) => {
  const e = Rd(t);
  return i(e);
}, Bd = (t, e) => o.get(
  "/partner_dashboard/webhook_endpoints",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cd = (t) => ["/partner_dashboard/webhook_endpoints", ...t ? [t] : []], Ed = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cd(t), queryFn: ({ signal: u }) => Bd(t, { signal: u, ...r }), ...n };
};
function cb(t, e) {
  const n = Ed(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Sd = (t, e) => o.post(
  "/partner_dashboard/webhook_endpoints",
  t,
  e
), vd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Sd(a, n);
  }, ...e };
}, yb = (t) => {
  const e = vd(t);
  return i(e);
}, Ad = (t, e) => o.delete(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e
), Dd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a } = s ?? {};
    return Ad(a, n);
  }, ...e };
}, db = (t) => {
  const e = Dd(t);
  return i(e);
}, Ud = (t, e, n) => o.patch(
  `/partner_dashboard/webhook_endpoints/${t}`,
  e,
  n
), Nd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { id: a, data: u } = s ?? {};
    return Ud(a, u, n);
  }, ...e };
}, pb = (t) => {
  const e = Nd(t);
  return i(e);
}, Gd = (t, e) => o.get(
  `/partner_dashboard/capital_tab/csv_export/${t}`,
  e
), Td = (t) => [`/partner_dashboard/capital_tab/csv_export/${t}`], Id = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Td(t), queryFn: ({ signal: u }) => Gd(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function _b(t, e) {
  const n = Id(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const wd = (t, e) => o.get(
  "/partner_dashboard/capital_tab/csv_export",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qd = (t) => ["/partner_dashboard/capital_tab/csv_export", ...t ? [t] : []], Ld = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qd(t), queryFn: ({ signal: u }) => wd(t, { signal: u, ...r }), ...n };
};
function mb(t, e) {
  const n = Ld(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wd = (t, e) => o.post(
  "/partner_dashboard/capital_tab/csv_export",
  t,
  e
), Hd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Wd(a, n);
  }, ...e };
}, lb = (t) => {
  const e = Hd(t);
  return i(e);
}, Vd = (t, e) => o.get(
  "/rutter_connections",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), zd = (t) => ["/rutter_connections", ...t ? [t] : []], $d = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? zd(t), queryFn: ({ signal: u }) => Vd(t, { signal: u, ...r }), ...n };
};
function gb(t, e) {
  const n = $d(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Yd = (t, e) => o.post(
  "/rutter_connections",
  t,
  e
), Xd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Yd(a, n);
  }, ...e };
}, qb = (t) => {
  const e = Xd(t);
  return i(e);
}, Zd = (t, e) => o.post(
  "/mark_accounting_integration_seen",
  t,
  e
), jd = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Zd(a, n);
  }, ...e };
}, fb = (t) => {
  const e = jd(t);
  return i(e);
}, Jd = (t) => o.get(
  "/sardine/session_id",
  t
), tp = () => ["/sardine/session_id"], ep = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? tp(), queryFn: ({ signal: a }) => Jd({ signal: a, ...n }), ...e };
};
function Ob(t) {
  const e = ep(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const np = (t, e) => o.post(
  "/sardine/session_id",
  t,
  e
), rp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return np(a, n);
  }, ...e };
}, hb = (t) => {
  const e = rp(t);
  return i(e);
}, sp = (t) => o.get(
  "/partner/widget_configs",
  t
), ap = () => ["/partner/widget_configs"], op = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? ap(), queryFn: ({ signal: a }) => sp({ signal: a, ...n }), ...e };
};
function Kb(t) {
  const e = op(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const up = (t, e) => o.patch(
  "/partner/widget_configs",
  t,
  e
), ip = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return up(a, n);
  }, ...e };
}, Pb = (t) => {
  const e = ip(t);
  return i(e);
}, cp = (t, e) => o.get(
  `/merchant_bank/applications/${t}`,
  e
), yp = (t) => [`/merchant_bank/applications/${t}`], dp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? yp(t), queryFn: ({ signal: u }) => cp(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function bb(t, e) {
  const n = dp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const pp = (t, e) => o.get(
  "/merchant_bank/applications",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), _p = (t) => ["/merchant_bank/applications", ...t ? [t] : []], mp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? _p(t), queryFn: ({ signal: u }) => pp(t, { signal: u, ...r }), ...n };
};
function kb(t, e) {
  const n = mp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const lp = (t, e) => o.post(
  "/merchant_bank/applications",
  t,
  e
), gp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return lp(a, n);
  }, ...e };
}, xb = (t) => {
  const e = gp(t);
  return i(e);
}, qp = (t, e) => o.post(
  "/webhooks/plaid/general",
  t,
  e
), fp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return qp(a, n);
  }, ...e };
}, Mb = (t) => {
  const e = fp(t);
  return i(e);
}, Op = (t, e) => o.get(
  "/amazon/funding/account_lock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), hp = (t) => ["/amazon/funding/account_lock", ...t ? [t] : []], Kp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? hp(t), queryFn: ({ signal: u }) => Op(t, { signal: u, ...r }), ...n };
};
function Fb(t, e) {
  const n = Kp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Pp = (t) => o.get(
  "/naics",
  t
), bp = () => ["/naics"], kp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? bp(), queryFn: ({ signal: a }) => Pp({ signal: a, ...n }), ...e };
};
function Rb(t) {
  const e = kp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const xp = (t, e, n, r) => o.post(
  `/mfa/otp/send/${t}/${e}`,
  n,
  r
), Mp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return xp(a, u, y, n);
  }, ...e };
}, Bb = (t) => {
  const e = Mp(t);
  return i(e);
}, Fp = (t, e, n, r) => o.post(
  `/mfa/otp/verify/${t}/${e}`,
  n,
  r
), Rp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { personId: a, phoneNumberType: u, data: y } = s ?? {};
    return Fp(a, u, y, n);
  }, ...e };
}, Cb = (t) => {
  const e = Rp(t);
  return i(e);
}, Bp = (t, e) => o.get(
  "/funding/pad_agreement",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Cp = (t) => ["/funding/pad_agreement", ...t ? [t] : []], Ep = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Cp(t), queryFn: ({ signal: u }) => Bp(t, { signal: u, ...r }), ...n };
};
function Eb(t, e) {
  const n = Ep(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Sp = (t, e) => o.post(
  "/funding/mark_pad_agreement_signed",
  t,
  e
), vp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Sp(a, n);
  }, ...e };
}, Sb = (t) => {
  const e = vp(t);
  return i(e);
}, Ap = (t, e) => o.get(
  "/templates",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Dp = (t) => ["/templates", ...t ? [t] : []], Up = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Dp(t), queryFn: ({ signal: u }) => Ap(t, { signal: u, ...r }), ...n };
};
function vb(t, e) {
  const n = Up(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Np = (t, e) => o.post(
  "/templates",
  t,
  e
), Gp = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Np(a, n);
  }, ...e };
}, Ab = (t) => {
  const e = Gp(t);
  return i(e);
}, Tp = (t, e) => o.patch(
  "/templates",
  t,
  e
), Ip = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Tp(a, n);
  }, ...e };
}, Db = (t) => {
  const e = Ip(t);
  return i(e);
}, wp = (t, e) => o.get(
  "/template_mock",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Qp = (t) => ["/template_mock", ...t ? [t] : []], Lp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Qp(t), queryFn: ({ signal: u }) => wp(t, { signal: u, ...r }), ...n };
};
function Ub(t, e) {
  const n = Lp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Wp = (t, e) => o.get(
  "/template_snapshots",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hp = (t) => ["/template_snapshots", ...t ? [t] : []], Vp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hp(t), queryFn: ({ signal: u }) => Wp(t, { signal: u, ...r }), ...n };
};
function Nb(t, e) {
  const n = Vp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const zp = (t, e) => o.get(
  "/template_snapshot_urls",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $p = (t) => ["/template_snapshot_urls", ...t ? [t] : []], Yp = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $p(t), queryFn: ({ signal: u }) => zp(t, { signal: u, ...r }), ...n };
};
function Gb(t, e) {
  const n = Yp(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Xp = (t) => o.get(
  "/template_types",
  t
), Zp = () => ["/template_types"], jp = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? Zp(), queryFn: ({ signal: a }) => Xp({ signal: a, ...n }), ...e };
};
function Tb(t) {
  const e = jp(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const Jp = (t, e) => o.post(
  "/template_types",
  t,
  e
), t_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Jp(a, n);
  }, ...e };
}, Ib = (t) => {
  const e = t_(t);
  return i(e);
}, e_ = (t, e) => o.post(
  "/manual_repayment",
  t,
  e
), n_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return e_(a, n);
  }, ...e };
}, wb = (t) => {
  const e = n_(t);
  return i(e);
}, r_ = (t, e) => o.post(
  "/forgive_capital_fee",
  t,
  e
), s_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return r_(a, n);
  }, ...e };
}, Qb = (t) => {
  const e = s_(t);
  return i(e);
}, a_ = (t, e) => o.post(
  "/mfa/verify_account_details",
  t,
  e
), o_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return a_(a, n);
  }, ...e };
}, Lb = (t) => {
  const e = o_(t);
  return i(e);
}, u_ = (t) => o.patch(
  "/mfa/extend_session",
  void 0,
  t
), i_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: () => u_(n), ...e };
}, Wb = (t) => {
  const e = i_(t);
  return i(e);
}, c_ = (t, e) => o.get(
  "/merchant_bank/offers",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), y_ = (t) => ["/merchant_bank/offers", ...t ? [t] : []], d_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? y_(t), queryFn: ({ signal: u }) => c_(t, { signal: u, ...r }), ...n };
};
function Hb(t, e) {
  const n = d_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const p_ = (t, e) => o.post(
  "/merchant_bank/offers",
  t,
  e
), __ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return p_(a, n);
  }, ...e };
}, Vb = (t) => {
  const e = __(t);
  return i(e);
}, m_ = (t, e) => o.get(
  "/internal_api/sardine/score_details",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), l_ = (t) => ["/internal_api/sardine/score_details", ...t ? [t] : []], g_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? l_(t), queryFn: ({ signal: u }) => m_(t, { signal: u, ...r }), ...n };
};
function zb(t, e) {
  const n = g_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const q_ = (t, e) => o.patch(
  "/merchant_bank/merchant_config",
  t,
  e
), f_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return q_(a, n);
  }, ...e };
}, $b = (t) => {
  const e = f_(t);
  return i(e);
}, O_ = (t, e) => o.post(
  "/auth/login/validate_otp",
  t,
  e
), h_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return O_(a, n);
  }, ...e };
}, Yb = (t) => {
  const e = h_(t);
  return i(e);
}, K_ = (t, e) => o.post(
  "/auth/login/start",
  t,
  e
), P_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return K_(a, n);
  }, ...e };
}, Xb = (t) => {
  const e = P_(t);
  return i(e);
}, b_ = (t, e) => o.post(
  "/auth/token/exchange",
  t,
  e
), k_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return b_(a, n);
  }, ...e };
}, Zb = (t) => {
  const e = k_(t);
  return i(e);
}, x_ = (t, e) => o.get(
  `/auth/management/${t}/saml`,
  e
), M_ = (t) => [`/auth/management/${t}/saml`], F_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? M_(t), queryFn: ({ signal: u }) => x_(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function jb(t, e) {
  const n = F_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const R_ = (t, e, n) => o.post(
  `/auth/management/${t}/saml`,
  e,
  n
), B_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: a, data: u } = s ?? {};
    return R_(a, u, n);
  }, ...e };
}, Jb = (t) => {
  const e = B_(t);
  return i(e);
}, C_ = (t, e, n) => o.delete(
  `/auth/management/${t}/saml/${e}`,
  n
), E_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: a, samlConfigId: u } = s ?? {};
    return C_(a, u, n);
  }, ...e };
}, tk = (t) => {
  const e = E_(t);
  return i(e);
}, S_ = (t, e, n, r) => o.patch(
  `/auth/management/${t}/saml/${e}`,
  n,
  r
), v_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { userId: a, samlConfigId: u, data: y } = s ?? {};
    return S_(a, u, y, n);
  }, ...e };
}, ek = (t) => {
  const e = v_(t);
  return i(e);
}, A_ = (t, e) => o.post(
  "/auth/email_otp/verify",
  t,
  e
), D_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return A_(a, n);
  }, ...e };
}, nk = (t) => {
  const e = D_(t);
  return i(e);
}, U_ = (t, e) => o.get(
  `/introductory_offer/underwrite/${t}`,
  e
), N_ = (t) => [`/introductory_offer/underwrite/${t}`], G_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? N_(t), queryFn: ({ signal: u }) => U_(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function rk(t, e) {
  const n = G_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const T_ = (t, e) => o.post(
  "/introductory_offer/underwrite",
  t,
  e
), I_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return T_(a, n);
  }, ...e };
}, sk = (t) => {
  const e = I_(t);
  return i(e);
}, w_ = (t, e) => o.get(
  "/card/balances",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Q_ = (t) => ["/card/balances", ...t ? [t] : []], L_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Q_(t), queryFn: ({ signal: u }) => w_(t, { signal: u, ...r }), ...n };
};
function ak(t, e) {
  const n = L_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const W_ = (t) => o.get(
  "/cardholders",
  t
), H_ = () => ["/cardholders"], V_ = (t) => {
  const { query: e, axios: n } = t ?? {};
  return { queryKey: (e == null ? void 0 : e.queryKey) ?? H_(), queryFn: ({ signal: a }) => W_({ signal: a, ...n }), ...e };
};
function ok(t) {
  const e = V_(t), n = c(e);
  return n.queryKey = e.queryKey, n;
}
const z_ = (t, e, n) => o.patch(
  `/cardholders/${t}`,
  e,
  n
), $_ = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { cardholderId: a, data: u } = s ?? {};
    return z_(a, u, n);
  }, ...e };
}, uk = (t) => {
  const e = $_(t);
  return i(e);
}, Y_ = (t, e) => o.get(
  "/card/access_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), X_ = (t) => ["/card/access_token", ...t ? [t] : []], Z_ = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? X_(t), queryFn: ({ signal: u }) => Y_(t, { signal: u, ...r }), ...n };
};
function ik(t, e) {
  const n = Z_(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const j_ = (t, e) => o.get(
  "/card/lifecycle_states",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), J_ = (t) => ["/card/lifecycle_states", ...t ? [t] : []], tm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? J_(t), queryFn: ({ signal: u }) => j_(t, { signal: u, ...r }), ...n };
};
function ck(t, e) {
  const n = tm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const em = (t, e) => o.get(
  "/card/transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), nm = (t) => ["/card/transactions", ...t ? [t] : []], rm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? nm(t), queryFn: ({ signal: u }) => em(t, { signal: u, ...r }), ...n };
};
function yk(t, e) {
  const n = rm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const sm = (t, e) => o.get(
  "/card/pending_transactions",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), am = (t) => ["/card/pending_transactions", ...t ? [t] : []], om = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? am(t), queryFn: ({ signal: u }) => sm(t, { signal: u, ...r }), ...n };
};
function dk(t, e) {
  const n = om(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const um = (t, e) => o.get(
  `/orders/${t}`,
  e
), im = (t) => [`/orders/${t}`], cm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? im(t), queryFn: ({ signal: u }) => um(t, { signal: u, ...r }), enabled: !!t, ...n };
};
function pk(t, e) {
  const n = cm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const ym = (t, e) => o.get(
  "/opt_ins",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), dm = (t) => ["/opt_ins", ...t ? [t] : []], pm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? dm(t), queryFn: ({ signal: u }) => ym(t, { signal: u, ...r }), ...n };
};
function _k(t, e) {
  const n = pm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const _m = (t, e) => o.post(
  "/opt_ins",
  t,
  e
), mm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return _m(a, n);
  }, ...e };
}, mk = (t) => {
  const e = mm(t);
  return i(e);
}, lm = (t, e) => o.post(
  "/bulk_opt_in",
  t,
  e
), gm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return lm(a, n);
  }, ...e };
}, lk = (t) => {
  const e = gm(t);
  return i(e);
}, qm = (t, e) => o.get(
  "/opt_out_eligibility",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), fm = (t) => ["/opt_out_eligibility", ...t ? [t] : []], Om = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? fm(t), queryFn: ({ signal: u }) => qm(t, { signal: u, ...r }), ...n };
};
function gk(t, e) {
  const n = Om(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const hm = (t, e) => o.post(
  "/opt_out",
  t,
  e
), Km = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return hm(a, n);
  }, ...e };
}, qk = (t) => {
  const e = Km(t);
  return i(e);
}, Pm = (t, e) => o.put(
  "/sandbox/capital_product_test_offer",
  t,
  e
), bm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Pm(a, n);
  }, ...e };
}, fk = (t) => {
  const e = bm(t);
  return i(e);
}, km = (t, e) => o.patch(
  "/sandbox/capital_product_fund",
  t,
  e
), xm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return km(a, n);
  }, ...e };
}, Ok = (t) => {
  const e = xm(t);
  return i(e);
}, Mm = (t, e) => o.patch(
  "/sandbox/capital_product_funding_checklist",
  t,
  e
), Fm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Mm(a, n);
  }, ...e };
}, hk = (t) => {
  const e = Fm(t);
  return i(e);
}, Rm = (t, e) => o.patch(
  "/sandbox/capital_product_top_up",
  t,
  e
), Bm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Rm(a, n);
  }, ...e };
}, Kk = (t) => {
  const e = Bm(t);
  return i(e);
}, Cm = (t, e) => o.post(
  "/sandbox/capital_product_pay_off",
  t,
  e
), Em = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Cm(a, n);
  }, ...e };
}, Pk = (t) => {
  const e = Em(t);
  return i(e);
}, Sm = (t, e) => o.post(
  "/sandbox/amazon_lock_bank_account",
  t,
  e
), vm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Sm(a, n);
  }, ...e };
}, bk = (t) => {
  const e = vm(t);
  return i(e);
}, Am = (t, e) => o.post(
  "/sandbox/break_bank_account",
  t,
  e
), Dm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Am(a, n);
  }, ...e };
}, kk = (t) => {
  const e = Dm(t);
  return i(e);
}, Um = (t, e) => o.post(
  "/sandbox/repayments",
  t,
  e
), Nm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Um(a, n);
  }, ...e };
}, xk = (t) => {
  const e = Nm(t);
  return i(e);
}, Gm = (t, e) => o.patch(
  "/sandbox/repayments",
  t,
  e
), Tm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Gm(a, n);
  }, ...e };
}, Mk = (t) => {
  const e = Tm(t);
  return i(e);
}, Im = (t, e) => o.post(
  "/sandbox/merchant_bank/setup_business",
  t,
  e
), wm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Im(a, n);
  }, ...e };
}, Fk = (t) => {
  const e = wm(t);
  return i(e);
}, Qm = (t, e) => o.put(
  "/sandbox/fee_discount",
  t,
  e
), Lm = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return Qm(a, n);
  }, ...e };
}, Rk = (t) => {
  const e = Lm(t);
  return i(e);
}, Wm = (t, e) => o.get(
  "/repayments",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Hm = (t) => ["/repayments", ...t ? [t] : []], Vm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Hm(t), queryFn: ({ signal: u }) => Wm(t, { signal: u, ...r }), ...n };
};
function Bk(t, e) {
  const n = Vm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const zm = (t, e) => o.get(
  "/businesses/show_top_up_offer",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), $m = (t) => ["/businesses/show_top_up_offer", ...t ? [t] : []], Ym = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? $m(t), queryFn: ({ signal: u }) => zm(t, { signal: u, ...r }), ...n };
};
function Ck(t, e) {
  const n = Ym(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Xm = (t, e) => o.get(
  "/funding/plaid_link_token",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), Zm = (t) => ["/funding/plaid_link_token", ...t ? [t] : []], jm = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? Zm(t), queryFn: ({ signal: u }) => Xm(t, { signal: u, ...r }), ...n };
};
function Ek(t, e) {
  const n = jm(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const Jm = (t, e) => o.get(
  "/statements",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), tl = (t) => ["/statements", ...t ? [t] : []], el = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? tl(t), queryFn: ({ signal: u }) => Jm(t, { signal: u, ...r }), ...n };
};
function Sk(t, e) {
  const n = el(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const nl = (t, e) => o.get(
  "/early_manual_repayment_whitelist",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), rl = (t) => ["/early_manual_repayment_whitelist", ...t ? [t] : []], sl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? rl(t), queryFn: ({ signal: u }) => nl(t, { signal: u, ...r }), ...n };
};
function vk(t, e) {
  const n = sl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const al = (t, e) => o.patch(
  "/",
  t,
  e
), ol = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return al(a, n);
  }, ...e };
}, Ak = (t) => {
  const e = ol(t);
  return i(e);
}, ul = (t, e) => o.get(
  "/bnpl/underwrite",
  {
    ...e,
    params: { ...t, ...e == null ? void 0 : e.params }
  }
), il = (t) => ["/bnpl/underwrite", ...t ? [t] : []], cl = (t, e) => {
  const { query: n, axios: r } = e ?? {};
  return { queryKey: (n == null ? void 0 : n.queryKey) ?? il(t), queryFn: ({ signal: u }) => ul(t, { signal: u, ...r }), ...n };
};
function Dk(t, e) {
  const n = cl(t, e), r = c(n);
  return r.queryKey = n.queryKey, r;
}
const yl = (t, e) => o.post(
  "/bnpl/underwrite",
  t,
  e
), dl = (t) => {
  const { mutation: e, axios: n } = t ?? {};
  return { mutationFn: (s) => {
    const { data: a } = s ?? {};
    return yl(a, n);
  }, ...e };
}, Uk = (t) => {
  const e = dl(t);
  return i(e);
}, Nk = (t) => i({
  mutationFn: (e) => o.post("/beneficial_owners", e, t == null ? void 0 : t.axios)
}), Gk = (t) => i({
  mutationFn: ({
    id: e,
    ...n
  }) => o.patch(`/beneficial_owners/${e}`, n, t == null ? void 0 : t.axios)
}), Tk = (t) => i({
  mutationFn: (e) => o.delete(`/beneficial_owners/${e}`, t == null ? void 0 : t.axios)
}), Ik = (t) => i({
  mutationFn: (e) => o.post(`/business/${e.business_id}/kyc`, e, t == null ? void 0 : t.axios)
}), wk = (t) => i({
  mutationFn: (e) => o.post("/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), Qk = (t) => i({
  mutationFn: (e) => o.post("/amazon/funding/bank_account_info", e, t == null ? void 0 : t.axios)
}), Lk = (t) => i({
  mutationFn: (e) => o.post("/funding/bank_statement_upload", e, t == null ? void 0 : t.axios)
}), Wk = (t) => i({
  mutationFn: (e) => o.post("/underwriting_attempts", { context: e }, t == null ? void 0 : t.axios)
}), Hk = (t) => i({
  mutationFn: (e) => o.post(
    "/amazonlending/create_or_update_amazon_resources_pre_login",
    e,
    t == null ? void 0 : t.axios
  )
}), Vk = (t) => i({
  mutationFn: (e) => o.patch("/boost_complete", e, t == null ? void 0 : t.axios)
}), zk = (t) => i({
  mutationFn: (e) => o.post("/card/disputes", e, t == null ? void 0 : t.axios)
}), $k = (t) => i({
  mutationFn: (e) => o.post(
    "/funding/create_unverified_bank_accounts_from_plaid_linkage",
    e,
    t == null ? void 0 : t.axios
  )
}), Yk = (t) => i({
  mutationFn: (e) => o.post("/amazon/funding/verify_bank_account", e, t == null ? void 0 : t.axios)
}), Xk = (t) => i({
  mutationFn: (e) => o.post("/funding/plaid_linkages", e, t == null ? void 0 : t.axios)
}), Zk = (t) => i({
  mutationFn: (e) => o.post("/tickets", e, t == null ? void 0 : t.axios)
}), jk = [
  {
    path: "/v1/auth/redeem_token",
    method: "post",
    name: "Redeem Auth Token"
  },
  {
    path: "/v1/bank_accounts",
    method: "get",
    name: "List Bank Accounts"
  },
  {
    path: "/v1/bank_accounts",
    method: "post",
    name: "Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/batch_create",
    method: "post",
    name: "Batch Create Bank Account"
  },
  {
    path: "/v1/bank_accounts/{id}",
    method: "get",
    name: "Get Bank Account"
  },
  {
    path: "/v1/businesses",
    method: "get",
    name: "List Businesses"
  },
  {
    path: "/v1/businesses",
    method: "post",
    name: "Create Business"
  },
  {
    path: "/v1/businesses/batch_create",
    method: "post",
    name: "Batch Create Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "get",
    name: "Get Business"
  },
  {
    path: "/v1/businesses/{id}",
    method: "patch",
    name: "Update Business"
  },
  {
    path: "/v1/capital_product_applications",
    method: "get",
    name: "List Capital Product Applications"
  },
  {
    path: "/v1/capital_product_applications/{id}",
    method: "get",
    name: "Get Capital Product Application"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/approve",
    method: "post",
    name: "Approve Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_application/{id}/deny",
    method: "post",
    name: "Deny Capital Product Application in Manual Review (Sandbox)"
  },
  {
    path: "/v1/capital_product_offers/{id}",
    method: "get",
    name: "Get Capital Product Offer"
  },
  {
    path: "/v1/capital_product_offers",
    method: "get",
    name: "List Capital Product Offers"
  },
  {
    path: "/v1/capital_product_payments",
    method: "get",
    name: "List Capital Product Payments"
  },
  {
    path: "/v1/capital_products/{id}/loan_minimum_repayment",
    method: "get",
    name: "Get Loan Minimum Repayment Details For A Capital Product"
  },
  {
    path: "/v1/capital_products/{id}",
    method: "get",
    name: "Get Capital Product"
  },
  {
    path: "/v1/capital_products",
    method: "get",
    name: "List Capital Products"
  },
  {
    path: "/v1/daily_sales_records",
    method: "get",
    name: "List Daily Sales Records"
  },
  {
    path: "/v1/daily_sales_records",
    method: "post",
    name: "Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/batch_create",
    method: "post",
    name: "Batch Create Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/bulk_ingest",
    method: "post",
    name: "Bulk Ingest Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "get",
    name: "Get Daily Sale Record"
  },
  {
    path: "/v1/daily_sales_records/{id}",
    method: "patch",
    name: "Update Daily Sale Record"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}",
    method: "get",
    name: "Get Net Settlement Payment Request"
  },
  {
    path: "/v1/net_settlement_payment_requests",
    method: "get",
    name: "List Net Settlement Payment Requests"
  },
  {
    path: "/v1/net_settlement_payment_requests/{id}/fulfill",
    method: "patch",
    name: "Fulfill Net Settlement Payment Request"
  },
  {
    path: "/v1/sandbox/net_settlement_payment_requests",
    method: "post",
    name: "Create Net Settlement Payment Request"
  },
  {
    path: "/v1/persons",
    method: "get",
    name: "List Persons"
  },
  {
    path: "/v1/persons",
    method: "post",
    name: "Create Person"
  },
  {
    path: "/v1/persons/batch_create",
    method: "post",
    name: "Batch Create Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "get",
    name: "Get Person"
  },
  {
    path: "/v1/persons/{id}",
    method: "patch",
    name: "Update Person"
  },
  {
    path: "/v1/person_business_relationships",
    method: "get",
    name: "List Person Business Relationships"
  },
  {
    path: "/v1/person_business_relationships",
    method: "post",
    name: "Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/batch_create",
    method: "post",
    name: "Batch Create Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/{id}",
    method: "get",
    name: "Get Person Business Relationship"
  },
  {
    path: "/v1/person_business_relationships/change_representative",
    method: "post",
    name: "Change Representative"
  },
  {
    path: "/v1/sandbox/trigger_webhook",
    method: "post",
    name: "Trigger Webhook"
  },
  {
    path: "/v1/sandbox/fund_capital_product",
    method: "post",
    name: "Fund Capital Product"
  },
  {
    path: "/v1/sandbox/capital_product_offer",
    method: "post",
    name: "[deprecated] Generate Capital Product Offer"
  },
  {
    path: "/v1/sandbox/wallets/balance",
    method: "post",
    name: "Add Business Wallet Balance"
  },
  {
    path: "/v1/sandbox/capital_product_payments",
    method: "post",
    name: "Create Capital Product Payment (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers",
    method: "post",
    name: "Create Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/capital_product_offers/{id}/close",
    method: "post",
    name: "Close Capital Product Offer (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/created",
    method: "post",
    name: "[Experimental] Capital Product Offer Created (Sandbox)"
  },
  {
    path: "/v1/sandbox/generate_event/capital_product_offer/closed",
    method: "post",
    name: "[Experimental] Capital Product Offer Closed (Sandbox)"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "get",
    name: "Get Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints/{id}",
    method: "patch",
    name: "Update Webhook Endpoint"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "get",
    name: "List Webhook Endpoints"
  },
  {
    path: "/v1/webhook_endpoints",
    method: "post",
    name: "Create Webhook Endpoint"
  }
];
export {
  mf as AccountOwner,
  _f as AddressType,
  pf as AdverseActionReason,
  df as BankAccountInfoDataShare,
  yf as BankAccountStatus,
  cf as BusinessProductType,
  uf as CapitalProductActivityType,
  of as CapitalProductApplicationStatus,
  af as CapitalProductApplicationStatus1,
  sf as CapitalProductApplicationTransitionReason,
  rf as CapitalProductApplicationType,
  nf as CapitalProductHealthStatus,
  ef as CapitalProductInternalRepaymentPlanType,
  tf as CapitalProductOfferLabel,
  Jq as CapitalProductState,
  jq as CapitalProductSuperApplicationActionType,
  Zq as CapitalProductType,
  Xq as CapitalProductType1,
  Yq as CardBusinessTableRowStatus,
  $q as CardDeserializerCardType,
  zq as CardLifecycleStateSerializerLifecycleState,
  Lq as CardSerializer1FulfillmentStatus,
  Qq as CardSerializer1State,
  Vq as CardSerializerCardType,
  Hq as CardSerializerFulfillmentStatus,
  Wq as CardSerializerState,
  wq as CardShippingMethod,
  Iq as CardTransactionType,
  Tq as CardTransitionChannel,
  Gq as CardTransitionReasonCode,
  Nq as CardTransitionState,
  Uq as CardTransitionType,
  Dq as CashAdvanceChargeOffSerializerChargeOffType,
  Aq as ChargeOffInputSerializerChargeOffType,
  vq as CountryCode,
  Sq as CrmDeliveryAttemptState,
  Eq as CrmEventType,
  Cq as CrmVendor,
  Bq as Currency,
  Rq as Currency1,
  Fq as CurrencyCode,
  Mq as DataRequirement,
  xq as DatasetType,
  kq as DatasetUploadStatus,
  bq as DatashareMethod,
  Pq as DayOfWeek,
  Kq as DecisionStatus,
  hq as DeniedReason,
  Oq as Direction,
  Og as DiscountProgramFundingType,
  fq as DiscountProgramType,
  qq as DisputeState,
  gq as DocumentUploadPurpose,
  lq as DoordashAdminUserMxRole,
  mq as EditSource,
  _q as EmailBatchState,
  pq as EmailSubscriptionState,
  dq as EmailTriggerSource,
  yq as EntityType,
  cq as FieldType,
  iq as FinancialRecordDocType,
  uq as FutureActivityType,
  oq as IdentityDataShare,
  aq as IncorporationJurisdiction,
  sq as IncorporationJurisdiction1,
  rq as IncorporationType,
  nq as IncorporationType1,
  eq as InfoNeededStatus,
  tq as IngestionStatus,
  Jg as InnerTransferType,
  jg as KycApplicationStatus,
  Zg as KycBusinessReportFieldType,
  Xg as KycIndividualReportFieldType,
  Yg as LienReviewState,
  $g as LienStatus,
  zg as LienType,
  Vg as LoanBusinessTableRowStatus,
  Hg as ManualReviewReason,
  Wg as MarketingEmailType,
  Lg as McaBusinessTableRowStatus,
  Qg as MerchantBankAccountType,
  wg as MerchantBankApplicationState,
  Ig as MerchantBankCardDisputeReason,
  Tg as MerchantBankCardDisputeState,
  Gg as MerchantBankCardPaymentPreference,
  Ng as MerchantBankCardReplacementReason,
  Ug as MerchantBankCardType,
  Dg as MerchantBankExternalAccountState,
  Ag as MerchantBankExternalBankAccountType,
  vg as MerchantBankOfferProductType,
  Sg as MerchantBankReceivedTransferType,
  Eg as MerchantBankRecipientPaymentType,
  Cg as MerchantBankRepaymentType,
  Bg as MerchantBankTransactionDestinationAccountType,
  Rg as MerchantBankTransactionPaymentDirection,
  Fg as MerchantBankTransactionState,
  Mg as MerchantBankTransactionType,
  xg as MerchantBankTransferPaymentType,
  kg as MicroDepositVerificationState,
  bg as MinimumRepaymentAmountDueReason,
  Pg as NetSettlementState,
  Kg as NoCodePilotStatus,
  hg as NotificationType,
  fg as OnboardingAction,
  qg as OnboardingProductType,
  gg as OnboardingState,
  lg as OneOffActionType,
  mg as OptInState,
  _g as OrderStatus,
  pg as ParafinProduct,
  dg as PartnerEventType,
  yg as PersonaInquiryType,
  cg as PhoneNumberCountryCode,
  ig as PhoneNumberType,
  ug as PlaidInvestigationDeniedReason,
  og as PlaidLinkageWithInvestigationSerializerState,
  ag as RepaymentDetailsRepaymentPlanType,
  sg as RepaymentDisbursementType,
  rg as RepaymentPauseSerializerPauseType,
  ng as RepaymentSchedule,
  eg as RepaymentSerializer1RepaymentType,
  tg as RepaymentSerializer1State,
  Jl as RepaymentsPatchDeserializerState,
  jl as RepaymentsPostDeserializerRepaymentType,
  Zl as RepaymentsPostDeserializerState,
  Xl as ReplacementDetailsReplacementReason,
  Yl as RestrictionExternalReason,
  $l as RestrictionReason,
  zl as RestrictionSource,
  Vl as RestrictionType,
  Hl as ReturnCode,
  Wl as ReversalReason,
  Ll as RutterConnectionState,
  Ql as RutterIntegrationType,
  wl as RutterPlatform,
  Il as S3PartnerDashboardCsvStatus,
  Tl as S3PartnerDashboardCsvTableType,
  Gl as SalesDataShare,
  Nl as SardineCustomerScoreState,
  Ul as SardineFlowName,
  Dl as SardineLevel,
  Al as Schedule,
  vl as SessionType,
  Sl as State,
  El as StripeSaleRecordIngestionType,
  Cl as TermsOfServiceLinkType,
  Bl as Timezone,
  Rl as UnderwriteIntroductoryOfferAttemptFailureReason,
  Fl as UnderwriteIntroductoryOfferAttemptStatus,
  Ml as UnderwritingDesiredOutputState,
  xl as UnifiedRealtimeUnderwritingAttemptFailureReason,
  kl as UnifiedRealtimeUnderwritingAttemptStatus,
  bl as UserRole,
  Pl as ValidationStatus,
  Kl as VerificationStatus,
  hl as WebNotificationState,
  Ol as WebSubscriptionState,
  fl as WebhookDeliveryAttemptState,
  ql as WebhookEndpointStatus,
  gl as WebhookEventDeliveryState,
  ll as WebhookEventMode,
  ml as WebhookEventType,
  Pd as attemptSync,
  Ry as batchCreatePdPartnerEventMarkAllNotificationsRead,
  cd as batchResendWebhookAttempt,
  Vr as benchmarkGetCapitalProductOwnership,
  Vc as countPdBusinessTableRows,
  sy as countPdCapitalProductApplicationRow,
  yy as countPdCapitalProductOfferRow,
  jc as countPdCapitalProductRow,
  yl as createAttemptToUnderwriteBnplOrder,
  T_ as createAttemptToUnderwriteIntroductoryOffers,
  Cs as createBankAccountInfo,
  rs as createCapitalProductIntents,
  ps as createCapitalProductSuperApplicationActions,
  ys as createCapitalProductSuperApplications,
  fr as createCapitalProducts,
  jr as createOfferedCapitalProductFeeDiscount,
  it as createOrganization,
  rt as createPartnerApiKey,
  Cy as createPdPartnerEventMarkNotificationRead,
  Sd as createPdWebhookEndpoint,
  Qs as createPlaidInvestigation,
  rc as createS3OfferCsv,
  Wd as createS3PartnerDashboardTableCsv,
  C_ as deleteAuthManagementUseridSamlSamlconfigid,
  Qe as deleteBeneficialOwnersId,
  fn as deleteBusinessOfficersId,
  A as deleteCashAdvancePausesP1,
  Be as deleteMarketingIntegrationsVendor,
  di as deleteMerchantBankExternalAccountsId,
  iu as deleteMerchantBankRecipientsId,
  Et as deleteOrganizationDeleteUsers,
  Bt as deletePartnerDeleteUsers,
  Vt as deletePartnerSyncWebhooksId,
  Ad as deletePdWebhookEndpoint,
  ht as deleteSlackChannelsId,
  zy as experimentalCapitalProductOfferClosedSandbox,
  Hy as experimentalCapitalProductOfferCreatedSandbox,
  Ui as getAccountingInfo,
  Op as getAmazonFundingAccountLock,
  $i as getAnalyticsCubeToken,
  bd as getAttemptSyncMutationOptions,
  x_ as getAuthManagementP1Saml,
  Fs as getBankAccountInfos,
  Ze as getBanks,
  By as getBatchCreatePdPartnerEventMarkAllNotificationsReadMutationOptions,
  yd as getBatchResendWebhookAttemptMutationOptions,
  zr as getBenchmarkGetCapitalProductOwnershipQueryKey,
  $r as getBenchmarkGetCapitalProductOwnershipQueryOptions,
  Ne as getBeneficialOwners,
  g as getBusinessAnnotations,
  d as getBusinessCores,
  ra as getBusinessIdentities,
  _n as getBusinessOfficers,
  yn as getBusinessTableRowsCard,
  on as getBusinessTableRowsLoan,
  rn as getBusinessTableRowsMca,
  tn as getBusinessTableRowsProductAgnostic,
  yo as getBusinessesDailySalesRecords,
  lc as getBusinessesNotifications,
  Ua as getBusinessesProducts,
  zm as getBusinessesShowTopUpOffer,
  Fr as getCapitalProductActivities,
  Dr as getCapitalProductApplicationSnapshots,
  Cr as getCapitalProductApplications,
  ts as getCapitalProductIntents,
  Ir as getCapitalProductNetTopUpItemization,
  Pr as getCapitalProductOfferCollection,
  pr as getCapitalProductProspectiveTermsOfService,
  Yr as getCapitalProductRepaymentSchedule,
  Lr as getCapitalProductStateDisclosure,
  lr as getCapitalProducts,
  Y_ as getCardAccessToken,
  w_ as getCardBalances,
  j_ as getCardLifecycleStates,
  sm as getCardPendingTransactions,
  em as getCardTransactions,
  W_ as getCardholders,
  Ea as getCards,
  K as getCashAdvanceChargeOffs,
  B as getCashAdvancePauses,
  zc as getCountPdBusinessTableRowsQueryKey,
  $c as getCountPdBusinessTableRowsQueryOptions,
  ay as getCountPdCapitalProductApplicationRowQueryKey,
  oy as getCountPdCapitalProductApplicationRowQueryOptions,
  dy as getCountPdCapitalProductOfferRowQueryKey,
  py as getCountPdCapitalProductOfferRowQueryOptions,
  Jc as getCountPdCapitalProductRowQueryKey,
  ty as getCountPdCapitalProductRowQueryOptions,
  us as getCounteroffer,
  dl as getCreateAttemptToUnderwriteBnplOrderMutationOptions,
  I_ as getCreateAttemptToUnderwriteIntroductoryOffersMutationOptions,
  Es as getCreateBankAccountInfoMutationOptions,
  ss as getCreateCapitalProductIntentsMutationOptions,
  _s as getCreateCapitalProductSuperApplicationActionsMutationOptions,
  ds as getCreateCapitalProductSuperApplicationsMutationOptions,
  Or as getCreateCapitalProductsMutationOptions,
  Jr as getCreateOfferedCapitalProductFeeDiscountMutationOptions,
  ct as getCreateOrganizationMutationOptions,
  st as getCreatePartnerApiKeyMutationOptions,
  Ey as getCreatePdPartnerEventMarkNotificationReadMutationOptions,
  vd as getCreatePdWebhookEndpointMutationOptions,
  Ls as getCreatePlaidInvestigationMutationOptions,
  sc as getCreateS3OfferCsvMutationOptions,
  Hd as getCreateS3PartnerDashboardTableCsvMutationOptions,
  Vn as getDataingestionS3DatasetIngestionConfigs,
  Un as getDataingestionS3DatasetIntegrations,
  vn as getDataingestionS3DatasetUploadValidationResults,
  Cn as getDataingestionS3DatasetUploads,
  wn as getDataingestionS3OrgDatasetIntegrations,
  nr as getDataingestionStripeIngestions,
  Mi as getDebtChecks,
  Oi as getDebts,
  E_ as getDeleteAuthManagementUseridSamlSamlconfigidMutationOptions,
  Le as getDeleteBeneficialOwnersIdMutationOptions,
  On as getDeleteBusinessOfficersIdMutationOptions,
  D as getDeleteCashAdvancePausesP1MutationOptions,
  Ce as getDeleteMarketingIntegrationsVendorMutationOptions,
  pi as getDeleteMerchantBankExternalAccountsIdMutationOptions,
  cu as getDeleteMerchantBankRecipientsIdMutationOptions,
  St as getDeleteOrganizationDeleteUsersMutationOptions,
  Ct as getDeletePartnerDeleteUsersMutationOptions,
  zt as getDeletePartnerSyncWebhooksIdMutationOptions,
  Dd as getDeletePdWebhookEndpointMutationOptions,
  Kt as getDeleteSlackChannelsIdMutationOptions,
  wa as getDocumentUploadGroup,
  Va as getDocumentUploadList,
  nl as getEarlyManualRepaymentWhitelist,
  $y as getExperimentalCapitalProductOfferClosedSandboxMutationOptions,
  Vy as getExperimentalCapitalProductOfferCreatedSandboxMutationOptions,
  Ei as getFinancialReviews,
  ms as getFlexLoanMinimumRepaymentSchedule,
  As as getFundingAccounts,
  Xm as getFundingPlaidLinkToken,
  w as getFutureActivities,
  Ni as getGetAccountingInfoQueryKey,
  Gi as getGetAccountingInfoQueryOptions,
  hp as getGetAmazonFundingAccountLockQueryKey,
  Kp as getGetAmazonFundingAccountLockQueryOptions,
  Yi as getGetAnalyticsCubeTokenQueryKey,
  Xi as getGetAnalyticsCubeTokenQueryOptions,
  M_ as getGetAuthManagementP1SamlQueryKey,
  F_ as getGetAuthManagementP1SamlQueryOptions,
  Rs as getGetBankAccountInfosQueryKey,
  Bs as getGetBankAccountInfosQueryOptions,
  je as getGetBanksQueryKey,
  Je as getGetBanksQueryOptions,
  Ge as getGetBeneficialOwnersQueryKey,
  Te as getGetBeneficialOwnersQueryOptions,
  q as getGetBusinessAnnotationsQueryKey,
  f as getGetBusinessAnnotationsQueryOptions,
  p as getGetBusinessCoresQueryKey,
  _ as getGetBusinessCoresQueryOptions,
  sa as getGetBusinessIdentitiesQueryKey,
  aa as getGetBusinessIdentitiesQueryOptions,
  mn as getGetBusinessOfficersQueryKey,
  ln as getGetBusinessOfficersQueryOptions,
  dn as getGetBusinessTableRowsCardQueryKey,
  pn as getGetBusinessTableRowsCardQueryOptions,
  un as getGetBusinessTableRowsLoanQueryKey,
  cn as getGetBusinessTableRowsLoanQueryOptions,
  sn as getGetBusinessTableRowsMcaQueryKey,
  an as getGetBusinessTableRowsMcaQueryOptions,
  en as getGetBusinessTableRowsProductAgnosticQueryKey,
  nn as getGetBusinessTableRowsProductAgnosticQueryOptions,
  po as getGetBusinessesDailySalesRecordsQueryKey,
  _o as getGetBusinessesDailySalesRecordsQueryOptions,
  gc as getGetBusinessesNotificationsQueryKey,
  qc as getGetBusinessesNotificationsQueryOptions,
  Na as getGetBusinessesProductsQueryKey,
  Ga as getGetBusinessesProductsQueryOptions,
  $m as getGetBusinessesShowTopUpOfferQueryKey,
  Ym as getGetBusinessesShowTopUpOfferQueryOptions,
  Rr as getGetCapitalProductActivitiesQueryKey,
  Br as getGetCapitalProductActivitiesQueryOptions,
  Ur as getGetCapitalProductApplicationSnapshotsQueryKey,
  Nr as getGetCapitalProductApplicationSnapshotsQueryOptions,
  Er as getGetCapitalProductApplicationsQueryKey,
  Sr as getGetCapitalProductApplicationsQueryOptions,
  es as getGetCapitalProductIntentsQueryKey,
  ns as getGetCapitalProductIntentsQueryOptions,
  wr as getGetCapitalProductNetTopUpItemizationQueryKey,
  Qr as getGetCapitalProductNetTopUpItemizationQueryOptions,
  br as getGetCapitalProductOfferCollectionQueryKey,
  kr as getGetCapitalProductOfferCollectionQueryOptions,
  _r as getGetCapitalProductProspectiveTermsOfServiceQueryKey,
  mr as getGetCapitalProductProspectiveTermsOfServiceQueryOptions,
  Xr as getGetCapitalProductRepaymentScheduleQueryKey,
  Zr as getGetCapitalProductRepaymentScheduleQueryOptions,
  Wr as getGetCapitalProductStateDisclosureQueryKey,
  Hr as getGetCapitalProductStateDisclosureQueryOptions,
  gr as getGetCapitalProductsQueryKey,
  qr as getGetCapitalProductsQueryOptions,
  X_ as getGetCardAccessTokenQueryKey,
  Z_ as getGetCardAccessTokenQueryOptions,
  Q_ as getGetCardBalancesQueryKey,
  L_ as getGetCardBalancesQueryOptions,
  J_ as getGetCardLifecycleStatesQueryKey,
  tm as getGetCardLifecycleStatesQueryOptions,
  am as getGetCardPendingTransactionsQueryKey,
  om as getGetCardPendingTransactionsQueryOptions,
  nm as getGetCardTransactionsQueryKey,
  rm as getGetCardTransactionsQueryOptions,
  H_ as getGetCardholdersQueryKey,
  V_ as getGetCardholdersQueryOptions,
  Sa as getGetCardsQueryKey,
  va as getGetCardsQueryOptions,
  P as getGetCashAdvanceChargeOffsQueryKey,
  b as getGetCashAdvanceChargeOffsQueryOptions,
  C as getGetCashAdvancePausesQueryKey,
  E as getGetCashAdvancePausesQueryOptions,
  is as getGetCounterofferQueryKey,
  cs as getGetCounterofferQueryOptions,
  zn as getGetDataingestionS3DatasetIngestionConfigsQueryKey,
  $n as getGetDataingestionS3DatasetIngestionConfigsQueryOptions,
  Nn as getGetDataingestionS3DatasetIntegrationsQueryKey,
  Gn as getGetDataingestionS3DatasetIntegrationsQueryOptions,
  An as getGetDataingestionS3DatasetUploadValidationResultsQueryKey,
  Dn as getGetDataingestionS3DatasetUploadValidationResultsQueryOptions,
  En as getGetDataingestionS3DatasetUploadsQueryKey,
  Sn as getGetDataingestionS3DatasetUploadsQueryOptions,
  Qn as getGetDataingestionS3OrgDatasetIntegrationsQueryKey,
  Ln as getGetDataingestionS3OrgDatasetIntegrationsQueryOptions,
  rr as getGetDataingestionStripeIngestionsQueryKey,
  sr as getGetDataingestionStripeIngestionsQueryOptions,
  Fi as getGetDebtChecksQueryKey,
  Ri as getGetDebtChecksQueryOptions,
  hi as getGetDebtsQueryKey,
  Ki as getGetDebtsQueryOptions,
  Qa as getGetDocumentUploadGroupQueryKey,
  La as getGetDocumentUploadGroupQueryOptions,
  za as getGetDocumentUploadListQueryKey,
  $a as getGetDocumentUploadListQueryOptions,
  rl as getGetEarlyManualRepaymentWhitelistQueryKey,
  sl as getGetEarlyManualRepaymentWhitelistQueryOptions,
  Si as getGetFinancialReviewsQueryKey,
  vi as getGetFinancialReviewsQueryOptions,
  ls as getGetFlexLoanMinimumRepaymentScheduleQueryKey,
  gs as getGetFlexLoanMinimumRepaymentScheduleQueryOptions,
  Ds as getGetFundingAccountsQueryKey,
  Us as getGetFundingAccountsQueryOptions,
  Zm as getGetFundingPlaidLinkTokenQueryKey,
  jm as getGetFundingPlaidLinkTokenQueryOptions,
  Q as getGetFutureActivitiesQueryKey,
  L as getGetFutureActivitiesQueryOptions,
  re as getGetHackathonGetPilotQueryKey,
  se as getGetHackathonGetPilotQueryOptions,
  zs as getGetKycRetryFieldsQueryKey,
  $s as getGetKycRetryFieldsQueryOptions,
  N_ as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryKey,
  G_ as getGetLatestAttemptToUnderwriteIntroductoryOffersQueryOptions,
  so as getGetLiensQueryKey,
  ao as getGetLiensQueryOptions,
  Se as getGetMarketingGtmToolkitQueryKey,
  ve as getGetMarketingGtmToolkitQueryOptions,
  xe as getGetMarketingIntegrationsQueryKey,
  Me as getGetMarketingIntegrationsQueryOptions,
  De as getGetMarketingParagonTokenQueryKey,
  Ue as getGetMarketingParagonTokenQueryOptions,
  Co as getGetMerchantBankAccountsIdBalancesQueryKey,
  Eo as getGetMerchantBankAccountsIdBalancesQueryOptions,
  Fo as getGetMerchantBankAccountsIdQueryKey,
  Ro as getGetMerchantBankAccountsIdQueryOptions,
  vo as getGetMerchantBankAccountsQueryKey,
  Ao as getGetMerchantBankAccountsQueryOptions,
  yp as getGetMerchantBankApplicationsIdQueryKey,
  dp as getGetMerchantBankApplicationsIdQueryOptions,
  _p as getGetMerchantBankApplicationsQueryKey,
  mp as getGetMerchantBankApplicationsQueryOptions,
  Eu as getGetMerchantBankCardAccessTokenQueryKey,
  Su as getGetMerchantBankCardAccessTokenQueryOptions,
  Uu as getGetMerchantBankCardCardholderAccessTokenQueryKey,
  Nu as getGetMerchantBankCardCardholderAccessTokenQueryOptions,
  Mu as getGetMerchantBankCardCardholdersQueryKey,
  Fu as getGetMerchantBankCardCardholdersQueryOptions,
  Lu as getGetMerchantBankCardDisputesQueryKey,
  Wu as getGetMerchantBankCardDisputesQueryOptions,
  Ou as getGetMerchantBankCardsQueryKey,
  hu as getGetMerchantBankCardsQueryOptions,
  ei as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryKey,
  ni as getGetMerchantBankExternalAccountsIdPlaidLinkTokenQueryOptions,
  ci as getGetMerchantBankExternalAccountsIdQueryKey,
  yi as getGetMerchantBankExternalAccountsIdQueryOptions,
  ju as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryKey,
  Ju as getGetMerchantBankExternalAccountsPlaidLinkTokenQueryOptions,
  si as getGetMerchantBankExternalAccountsQueryKey,
  ai as getGetMerchantBankExternalAccountsQueryOptions,
  jo as getGetMerchantBankInterestDetailsQueryKey,
  Jo as getGetMerchantBankInterestDetailsQueryOptions,
  y_ as getGetMerchantBankOffersQueryKey,
  d_ as getGetMerchantBankOffersQueryOptions,
  ou as getGetMerchantBankRecipientsIdQueryKey,
  uu as getGetMerchantBankRecipientsIdQueryOptions,
  eu as getGetMerchantBankRecipientsQueryKey,
  nu as getGetMerchantBankRecipientsQueryOptions,
  Io as getGetMerchantBankTransactionsIdQueryKey,
  wo as getGetMerchantBankTransactionsIdQueryOptions,
  Lo as getGetMerchantBankTransactionsQueryKey,
  Wo as getGetMerchantBankTransactionsQueryOptions,
  Vo as getGetMerchantBankTransferFeesQueryKey,
  zo as getGetMerchantBankTransferFeesQueryOptions,
  Yo as getGetMerchantBankTransferLimitsQueryKey,
  Xo as getGetMerchantBankTransferLimitsQueryOptions,
  bp as getGetNaicsQueryKey,
  kp as getGetNaicsQueryOptions,
  Wi as getGetOfficerSubsidiaryRecordsQueryKey,
  Hi as getGetOfficerSubsidiaryRecordsQueryOptions,
  yr as getGetOnboardingBusinessRequirementsQueryKey,
  dr as getGetOnboardingBusinessRequirementsQueryOptions,
  ea as getGetOnboardingInfoNeededQueryKey,
  na as getGetOnboardingInfoNeededQueryOptions,
  js as getGetOnboardingStatesQueryKey,
  Js as getGetOnboardingStatesQueryOptions,
  dm as getGetOptInsQueryKey,
  pm as getGetOptInsQueryOptions,
  fm as getGetOptOutEligibilityQueryKey,
  Om as getGetOptOutEligibilityQueryOptions,
  im as getGetOrderQueryKey,
  cm as getGetOrderQueryOptions,
  _t as getGetOrganizationQueryKey,
  mt as getGetOrganizationQueryOptions,
  Ly as getGetOwnerAuthQueryKey,
  Wy as getGetOwnerAuthQueryOptions,
  ze as getGetOwnerIsBeneficialOwnerQueryKey,
  $e as getGetOwnerIsBeneficialOwnerQueryOptions,
  ca as getGetOwnersQueryKey,
  ya as getGetOwnersQueryOptions,
  Cp as getGetPADAgreementQueryKey,
  Ep as getGetPADAgreementQueryOptions,
  fo as getGetParafinderBusinessTypesQueryKey,
  Oo as getGetParafinderBusinessTypesQueryOptions,
  ko as getGetParafinderCapitalInfoQueryKey,
  xo as getGetParafinderCapitalInfoQueryOptions,
  lo as getGetParafinderQueryKey,
  go as getGetParafinderQueryOptions,
  Ko as getGetParafinderSummaryQueryKey,
  Po as getGetParafinderSummaryQueryOptions,
  et as getGetPartnerApiKeyQueryKey,
  nt as getGetPartnerApiKeyQueryOptions,
  Nt as getGetPartnerConfigsQueryKey,
  Gt as getGetPartnerConfigsQueryOptions,
  At as getGetPartnerPublicConfigsQueryKey,
  Dt as getGetPartnerPublicConfigsQueryOptions,
  Qt as getGetPartnerSyncWebhooksQueryKey,
  Lt as getGetPartnerSyncWebhooksQueryOptions,
  bt as getGetPartnerUsersQueryKey,
  kt as getGetPartnerUsersQueryOptions,
  ap as getGetPartnerWidgetConfigsQueryKey,
  op as getGetPartnerWidgetConfigsQueryOptions,
  F as getGetPartnersDoordashAdminUsersQueryKey,
  R as getGetPartnersDoordashAdminUsersQueryOptions,
  H as getGetPartnersQueryKey,
  V as getGetPartnersQueryOptions,
  X as getGetPartnersUnderwritingQueryKey,
  Z as getGetPartnersUnderwritingQueryOptions,
  hy as getGetPdOfferUrlQueryKey,
  Ky as getGetPdOfferUrlQueryOptions,
  Iy as getGetPdUtilityInfoQueryKey,
  wy as getGetPdUtilityInfoQueryOptions,
  Is as getGetPlaidInvestigationsQueryKey,
  ws as getGetPlaidInvestigationsQueryOptions,
  T as getGetRepaymentPlanParametersQueryKey,
  I as getGetRepaymentPlanParametersQueryOptions,
  Hm as getGetRepaymentsQueryKey,
  Vm as getGetRepaymentsQueryOptions,
  Xa as getGetRestrictionQueryKey,
  Za as getGetRestrictionQueryOptions,
  eo as getGetRestrictionsDetailsQueryKey,
  no as getGetRestrictionsDetailsQueryOptions,
  hs as getGetRunOfacScreeningResultsQueryKey,
  Ks as getGetRunOfacScreeningResultsQueryOptions,
  zd as getGetRutterConnectionsQueryKey,
  $d as getGetRutterConnectionsQueryOptions,
  Td as getGetS3PartnerDashboardTableCsvQueryKey,
  Id as getGetS3PartnerDashboardTableCsvQueryOptions,
  tp as getGetSardineSessionIdQueryKey,
  ep as getGetSardineSessionIdQueryOptions,
  ft as getGetSlackChannelsQueryKey,
  Ot as getGetSlackChannelsQueryOptions,
  tl as getGetStatementsQueryKey,
  el as getGetStatementsQueryOptions,
  he as getGetStatementsV2QueryKey,
  Ke as getGetStatementsV2QueryOptions,
  Rd as getGetSyncAndMaybeMarkFailedMutationOptions,
  xd as getGetSyncQueryKey,
  Md as getGetSyncQueryOptions,
  Qp as getGetTemplateMockQueryKey,
  Lp as getGetTemplateMockQueryOptions,
  $p as getGetTemplateSnapshotQueryKey,
  Yp as getGetTemplateSnapshotQueryOptions,
  Hp as getGetTemplateSnapshotsQueryKey,
  Vp as getGetTemplateSnapshotsQueryOptions,
  Zp as getGetTemplateTypesQueryKey,
  jp as getGetTemplateTypesQueryOptions,
  Dp as getGetTemplatesQueryKey,
  Up as getGetTemplatesQueryOptions,
  xs as getGetTermLoanProspectiveRepaymentsQueryKey,
  Ms as getGetTermLoanProspectiveRepaymentsQueryOptions,
  il as getGetUnderwritingStatusForOrderQueryKey,
  cl as getGetUnderwritingStatusForOrderQueryOptions,
  ne as getHackathonGetPilot,
  Vs as getKycRetryFields,
  U_ as getLatestAttemptToUnderwriteIntroductoryOffers,
  ro as getLiens,
  dt as getLinkPartnerMutationOptions,
  ji as getListApiRequestsQueryKey,
  Ji as getListApiRequestsQueryOptions,
  xn as getListDatasetUploadsQueryKey,
  Mn as getListDatasetUploadsQueryOptions,
  Rn as getListManualDatasetUploadValidationResultsQueryKey,
  Bn as getListManualDatasetUploadValidationResultsQueryOptions,
  ot as getListOrganizationsQueryKey,
  ut as getListOrganizationsQueryOptions,
  dc as getListPartnerScheduledMarketingEmailConfigsQueryKey,
  pc as getListPartnerScheduledMarketingEmailConfigsQueryOptions,
  bc as getListPdBusinessDetailsApplicationsQueryKey,
  kc as getListPdBusinessDetailsApplicationsQueryOptions,
  Mc as getListPdBusinessDetailsBankInfoQueryKey,
  Fc as getListPdBusinessDetailsBankInfoQueryOptions,
  Bc as getListPdBusinessDetailsBusinessInfoQueryKey,
  Cc as getListPdBusinessDetailsBusinessInfoQueryOptions,
  Dc as getListPdBusinessDetailsFundedProductDetailsQueryKey,
  Uc as getListPdBusinessDetailsFundedProductDetailsQueryOptions,
  Gc as getListPdBusinessDetailsFundedProductPaymentProgressQueryKey,
  Tc as getListPdBusinessDetailsFundedProductPaymentProgressQueryOptions,
  Sc as getListPdBusinessDetailsFundedProductsQueryKey,
  vc as getListPdBusinessDetailsFundedProductsQueryOptions,
  wc as getListPdBusinessDetailsOffersQueryKey,
  Qc as getListPdBusinessDetailsOffersQueryOptions,
  Wc as getListPdBusinessDetailsOwnerInfoQueryKey,
  Hc as getListPdBusinessDetailsOwnerInfoQueryOptions,
  Xc as getListPdBusinessTableRowsQueryKey,
  Zc as getListPdBusinessTableRowsQueryOptions,
  iy as getListPdCapitalProductApplicationRowsQueryKey,
  cy as getListPdCapitalProductApplicationRowsQueryOptions,
  my as getListPdCapitalProductOfferRowsQueryKey,
  ly as getListPdCapitalProductOfferRowsQueryOptions,
  ny as getListPdCapitalProductRowsQueryKey,
  ry as getListPdCapitalProductRowsQueryOptions,
  ld as getListPdCrmDeliveryAttemptsQueryKey,
  gd as getListPdCrmDeliveryAttemptsQueryOptions,
  hd as getListPdCrmEventInfoQueryKey,
  Kd as getListPdCrmEventInfoQueryOptions,
  pd as getListPdCrmEventsQueryKey,
  _d as getListPdCrmEventsQueryOptions,
  Ny as getListPdCrmIntegrationsQueryKey,
  Gy as getListPdCrmIntegrationsQueryOptions,
  by as getListPdPartnerEventListUserSubscriptionsQueryKey,
  ky as getListPdPartnerEventListUserSubscriptionsQueryOptions,
  My as getListPdPartnerEventListWebNotificationsQueryKey,
  Fy as getListPdPartnerEventListWebNotificationsQueryOptions,
  qy as getListPdSupportInfoQueryKey,
  fy as getListPdSupportInfoQueryOptions,
  Cd as getListPdWebhookEndpointsQueryKey,
  Ed as getListPdWebhookEndpointsQueryOptions,
  nd as getListPdWebhookLogsDeliveryAttemptsQueryKey,
  rd as getListPdWebhookLogsDeliveryAttemptsQueryOptions,
  Jy as getListPdWebhookLogsEventInfoQueryKey,
  td as getListPdWebhookLogsEventInfoQueryOptions,
  Xy as getListPdWebhookLogsEventsQueryKey,
  Zy as getListPdWebhookLogsEventsQueryOptions,
  ec as getListS3OfferCsvsQueryKey,
  nc as getListS3OfferCsvsQueryOptions,
  Qd as getListS3PartnerDashboardTableCsvsQueryKey,
  Ld as getListS3PartnerDashboardTableCsvsQueryOptions,
  l_ as getListSardineDetailsQueryKey,
  g_ as getListSardineDetailsQueryOptions,
  oc as getListSendMarketingEmailBatchesQueryKey,
  uc as getListSendMarketingEmailBatchesQueryOptions,
  tr as getListVersionedSchemasQueryKey,
  er as getListVersionedSchemasQueryOptions,
  vp as getMarkPADAgreementSignedMutationOptions,
  Ee as getMarketingGtmToolkit,
  ke as getMarketingIntegrations,
  Ae as getMarketingParagonToken,
  So as getMerchantBankAccounts,
  Mo as getMerchantBankAccountsId,
  Bo as getMerchantBankAccountsIdBalances,
  pp as getMerchantBankApplications,
  cp as getMerchantBankApplicationsId,
  Cu as getMerchantBankCardAccessToken,
  Du as getMerchantBankCardCardholderAccessToken,
  xu as getMerchantBankCardCardholders,
  Qu as getMerchantBankCardDisputes,
  fu as getMerchantBankCards,
  ri as getMerchantBankExternalAccounts,
  ii as getMerchantBankExternalAccountsId,
  ti as getMerchantBankExternalAccountsIdPlaidLinkToken,
  Zu as getMerchantBankExternalAccountsPlaidLinkToken,
  Zo as getMerchantBankInterestDetails,
  c_ as getMerchantBankOffers,
  tu as getMerchantBankRecipients,
  au as getMerchantBankRecipientsId,
  Qo as getMerchantBankTransactions,
  To as getMerchantBankTransactionsId,
  Ho as getMerchantBankTransferFees,
  $o as getMerchantBankTransferLimits,
  ad as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryKey,
  od as getMultiWebhookPdWebhookLogsDeliveryAttemptsQueryOptions,
  Pp as getNaics,
  Li as getOfficerSubsidiaryRecords,
  cr as getOnboardingBusinessRequirements,
  ta as getOnboardingInfoNeeded,
  Zs as getOnboardingStates,
  ym as getOptIns,
  qm as getOptOutEligibility,
  um as getOrder,
  pt as getOrganization,
  Qy as getOwnerAuth,
  Ve as getOwnerIsBeneficialOwner,
  ia as getOwners,
  Bp as getPADAgreement,
  mo as getParafinder,
  qo as getParafinderBusinessTypes,
  bo as getParafinderCapitalInfo,
  ho as getParafinderSummary,
  tt as getPartnerApiKey,
  Ut as getPartnerConfigs,
  vt as getPartnerPublicConfigs,
  mc as getPartnerScheduledMarketingEmailConfigMutationOptions,
  wt as getPartnerSyncWebhooks,
  Pt as getPartnerUsers,
  sp as getPartnerWidgetConfigs,
  W as getPartners,
  M as getPartnersDoordashAdminUsers,
  Y as getPartnersUnderwriting,
  v_ as getPatchAuthManagementUseridSamlSamlconfigidMutationOptions,
  He as getPatchBeneficialOwnersIdMutationOptions,
  ua as getPatchBusinessIdentitiesIdMutationOptions,
  Kn as getPatchBusinessOfficersIdMutationOptions,
  os as getPatchCapitalProductIntentsMutationOptions,
  Kr as getPatchCapitalProductsMutationOptions,
  $_ as getPatchCardholdersCardholderidMutationOptions,
  N as getPatchCashAdvancePausesP1MutationOptions,
  bs as getPatchClearWatchlistHitsIdMutationOptions,
  ir as getPatchDataingestionStripeIngestionsMutationOptions,
  ka as getPatchFlexLoanAgeMutationOptions,
  Ra as getPatchFlexLoanFundMutationOptions,
  Ma as getPatchFlexLoanOwnershipMutationOptions,
  uo as getPatchLiensIdMutationOptions,
  ku as getPatchMerchantBankCardCardholdersIdMutationOptions,
  mi as getPatchMerchantBankExternalAccountsIdMutationOptions,
  fi as getPatchMerchantBankExternalBankTransfersIdMutationOptions,
  f_ as getPatchMerchantBankMerchantConfigMutationOptions,
  qu as getPatchMerchantBankRecipientTransfersIdMutationOptions,
  du as getPatchMerchantBankRecipientsIdMutationOptions,
  i_ as getPatchMfaExtendSessionMutationOptions,
  Xe as getPatchOwnerIsBeneficialOwnerMutationOptions,
  pa as getPatchOwnersIdMutationOptions,
  It as getPatchPartnerConfigsIdMutationOptions,
  Yt as getPatchPartnerSyncWebhooksIdMutationOptions,
  Rt as getPatchPartnerUsersMutationOptions,
  ip as getPatchPartnerWidgetConfigsMutationOptions,
  J as getPatchPartnersIdMutationOptions,
  Hs as getPatchPersonaFallbackInquiryMutationOptions,
  ol as getPatchRootMutationOptions,
  xm as getPatchSandboxCapitalProductFundMutationOptions,
  Fm as getPatchSandboxCapitalProductFundingChecklistMutationOptions,
  Bm as getPatchSandboxCapitalProductTopUpMutationOptions,
  Tm as getPatchSandboxRepaymentsMutationOptions,
  Ip as getPatchTemplatesMutationOptions,
  Oy as getPdOfferUrl,
  Ty as getPdUtilityInfo,
  Ts as getPlaidInvestigations,
  Ii as getPostAccountingInfoMutationOptions,
  fa as getPostAmazonRedirectUrlMutationOptions,
  D_ as getPostAuthEmailOtpVerifyMutationOptions,
  P_ as getPostAuthLoginStartMutationOptions,
  h_ as getPostAuthLoginValidateOtpMutationOptions,
  B_ as getPostAuthManagementUseridSamlMutationOptions,
  k_ as getPostAuthTokenExchangeMutationOptions,
  Zt as getPostAuthTokensMutationOptions,
  we as getPostBeneficialOwnersMutationOptions,
  gm as getPostBulkOptInMutationOptions,
  h as getPostBusinessAnnotationsMutationOptions,
  Ia as getPostBusinessAuthLinkMutationOptions,
  l as getPostBusinessCoresMutationOptions,
  ma as getPostBusinessMutationOptions,
  qn as getPostBusinessOfficersMutationOptions,
  Oc as getPostBusinessesNotificationsMutationOptions,
  Ar as getPostCapitalProductApplicationMutationOptions,
  Pa as getPostCapitalProductBusinessMutationOptions,
  Tr as getPostCapitalProductNetTopUpMutationOptions,
  Mr as getPostCapitalProductOfferCollectionMutationOptions,
  Ci as getPostCardCloseAccountMutationOptions,
  Da as getPostCardsMutationOptions,
  x as getPostCashAdvanceChargeOffsMutationOptions,
  v as getPostCashAdvancePausesMutationOptions,
  bn as getPostDataingestionOneschemaGenerateJwtMutationOptions,
  In as getPostDataingestionS3DatasetIntegrationsMutationOptions,
  Hn as getPostDataingestionS3OrgDatasetIntegrationsMutationOptions,
  jn as getPostDataingestionS3RunDatasetIngestionMutationOptions,
  or as getPostDataingestionStripeIngestionsMutationOptions,
  xi as getPostDebtsCheckCompleteMutationOptions,
  bi as getPostDebtsMutationOptions,
  Ha as getPostDocumentUploadGroupMutationOptions,
  Di as getPostFinancialReviewsMutationOptions,
  s_ as getPostForgiveCapitalFeeMutationOptions,
  Gs as getPostFundingAccountsMutationOptions,
  ie as getPostHackathonAwaitParafinRepresentativeMutationOptions,
  ee as getPostHackathonCreatePilotMutationOptions,
  oe as getPostHackathonGenerateOffersMutationOptions,
  pe as getPostHackathonGmvMutationOptions,
  ge as getPostHackathonMapOffersToEmailsMutationOptions,
  Jt as getPostHackathonPartnersMutationOptions,
  me as getPostHackathonResetMutationOptions,
  ye as getPostHackathonSendOffersMutationOptions,
  fe as getPostHackathonUploadSalesCsvMutationOptions,
  Xs as getPostKycRetryMutationOptions,
  n_ as getPostManualRepaymentMutationOptions,
  jd as getPostMarkAccountingIntegrationSeenMutationOptions,
  Re as getPostMarketingIntegrationsMutationOptions,
  be as getPostMarketingReviewMutationOptions,
  Uo as getPostMerchantBankAccountsMutationOptions,
  gp as getPostMerchantBankApplicationsMutationOptions,
  ga as getPostMerchantBankBusinessMutationOptions,
  Bu as getPostMerchantBankCardActivateMutationOptions,
  Au as getPostMerchantBankCardApplePayMutationOptions,
  Xu as getPostMerchantBankCardDisputeChargebackCreditMutationOptions,
  $u as getPostMerchantBankCardDisputeStateTransitionMutationOptions,
  Vu as getPostMerchantBankCardDisputesMutationOptions,
  wu as getPostMerchantBankCardGooglePayMutationOptions,
  Tu as getPostMerchantBankCardStateTransitionMutationOptions,
  Pu as getPostMerchantBankCardsMutationOptions,
  ui as getPostMerchantBankExternalAccountsMutationOptions,
  gi as getPostMerchantBankExternalBankTransfersMutationOptions,
  Go as getPostMerchantBankInnerTransfersMutationOptions,
  __ as getPostMerchantBankOffersMutationOptions,
  lu as getPostMerchantBankRecipientTransfersMutationOptions,
  _u as getPostMerchantBankRecipientsIdPaymentRailsMutationOptions,
  su as getPostMerchantBankRecipientsMutationOptions,
  Mp as getPostMfaOtpSendPersonidPhonenumbertypeMutationOptions,
  Rp as getPostMfaOtpVerifyPersonidPhonenumbertypeMutationOptions,
  o_ as getPostMfaVerifyAccountDetailsMutationOptions,
  zi as getPostOfficerSubsidiaryRecordsMutationOptions,
  co as getPostOptInMutationOptions,
  mm as getPostOptInsMutationOptions,
  Km as getPostOptOutMutationOptions,
  Ht as getPostPartnerSyncWebhooksMutationOptions,
  Mt as getPostPartnerUsersMutationOptions,
  $ as getPostPartnersMutationOptions,
  Ja as getPostRestrictionMutationOptions,
  Xd as getPostRutterConnectionsMutationOptions,
  vm as getPostSandboxAmazonLockBankAccountMutationOptions,
  Dm as getPostSandboxBreakBankAccountMutationOptions,
  Em as getPostSandboxCapitalProductPayOffMutationOptions,
  wm as getPostSandboxMerchantBankSetupBusinessMutationOptions,
  Nm as getPostSandboxRepaymentsMutationOptions,
  rp as getPostSardineSessionIdMutationOptions,
  Ca as getPostSetupAmazonTopUpMutationOptions,
  gt as getPostSlackAuthorizeMutationOptions,
  ha as getPostSpdMutationOptions,
  Qi as getPostTaxRecordsMutationOptions,
  t_ as getPostTemplateTypesMutationOptions,
  Gp as getPostTemplatesMutationOptions,
  Kc as getPostTermsOfServiceMarkShownMutationOptions,
  fs as getPostTriggerRunCelticsChecklistMutationOptions,
  fp as getPostWebhooksPlaidGeneralMutationOptions,
  Xn as getPutDataingestionS3DatasetIngestionConfigsMutationOptions,
  bm as getPutSandboxCapitalProductTestOfferMutationOptions,
  Lm as getPutSandboxFeeDiscountMutationOptions,
  G as getRepaymentPlanParameters,
  Wm as getRepayments,
  fd as getResendCrmAttemptMutationOptions,
  id as getResendWebhookAttemptMutationOptions,
  Ya as getRestriction,
  to as getRestrictionsDetails,
  Os as getRunOfacScreeningResults,
  Vd as getRutterConnections,
  Gd as getS3PartnerDashboardTableCsv,
  Jd as getSardineSessionId,
  cc as getSendMarketingEmailsMutationOptions,
  qt as getSlackChannels,
  Jm as getStatements,
  Oe as getStatementsV2,
  kd as getSync,
  Fd as getSyncAndMaybeMarkFailed,
  wp as getTemplateMock,
  zp as getTemplateSnapshot,
  Wp as getTemplateSnapshots,
  Xp as getTemplateTypes,
  Ap as getTemplates,
  ks as getTermLoanProspectiveRepayments,
  ul as getUnderwritingStatusForOrder,
  vs as getUpdateBankAccountInfoMutationOptions,
  vy as getUpdatePdPartnerEventUpdateUserSubscriptionMutationOptions,
  Dy as getUpdatePdPartnerEventUpdateUserSubscriptionsForEventTypeMutationOptions,
  Nd as getUpdatePdWebhookEndpointMutationOptions,
  yt as linkPartner,
  Zi as listApiRequests,
  kn as listDatasetUploads,
  Fn as listManualDatasetUploadValidationResults,
  at as listOrganizations,
  yc as listPartnerScheduledMarketingEmailConfigs,
  Pc as listPdBusinessDetailsApplications,
  xc as listPdBusinessDetailsBankInfo,
  Rc as listPdBusinessDetailsBusinessInfo,
  Ac as listPdBusinessDetailsFundedProductDetails,
  Nc as listPdBusinessDetailsFundedProductPaymentProgress,
  Ec as listPdBusinessDetailsFundedProducts,
  Ic as listPdBusinessDetailsOffers,
  Lc as listPdBusinessDetailsOwnerInfo,
  Yc as listPdBusinessTableRows,
  uy as listPdCapitalProductApplicationRows,
  _y as listPdCapitalProductOfferRows,
  ey as listPdCapitalProductRows,
  md as listPdCrmDeliveryAttempts,
  Od as listPdCrmEventInfo,
  dd as listPdCrmEvents,
  Uy as listPdCrmIntegrations,
  Py as listPdPartnerEventListUserSubscriptions,
  xy as listPdPartnerEventListWebNotifications,
  gy as listPdSupportInfo,
  Bd as listPdWebhookEndpoints,
  ed as listPdWebhookLogsDeliveryAttempts,
  jy as listPdWebhookLogsEventInfo,
  Yy as listPdWebhookLogsEvents,
  tc as listS3OfferCsvs,
  wd as listS3PartnerDashboardTableCsvs,
  m_ as listSardineDetails,
  ac as listSendMarketingEmailBatches,
  Jn as listVersionedSchemas,
  Sp as markPADAgreementSigned,
  sd as multiWebhookPdWebhookLogsDeliveryAttempts,
  _c as partnerScheduledMarketingEmailConfig,
  S_ as patchAuthManagementUseridSamlSamlconfigid,
  We as patchBeneficialOwnersId,
  oa as patchBusinessIdentitiesId,
  hn as patchBusinessOfficersId,
  as as patchCapitalProductIntents,
  hr as patchCapitalProducts,
  z_ as patchCardholdersCardholderid,
  U as patchCashAdvancePausesP1,
  Ps as patchClearWatchlistHitsId,
  ur as patchDataingestionStripeIngestions,
  ba as patchFlexLoanAge,
  Fa as patchFlexLoanFund,
  xa as patchFlexLoanOwnership,
  oo as patchLiensId,
  bu as patchMerchantBankCardCardholdersId,
  _i as patchMerchantBankExternalAccountsId,
  qi as patchMerchantBankExternalBankTransfersId,
  q_ as patchMerchantBankMerchantConfig,
  gu as patchMerchantBankRecipientTransfersId,
  yu as patchMerchantBankRecipientsId,
  u_ as patchMfaExtendSession,
  Ye as patchOwnerIsBeneficialOwner,
  da as patchOwnersId,
  Tt as patchPartnerConfigsId,
  $t as patchPartnerSyncWebhooksId,
  Ft as patchPartnerUsers,
  up as patchPartnerWidgetConfigs,
  j as patchPartnersId,
  Ws as patchPersonaFallbackInquiry,
  al as patchRoot,
  km as patchSandboxCapitalProductFund,
  Mm as patchSandboxCapitalProductFundingChecklist,
  Rm as patchSandboxCapitalProductTopUp,
  Gm as patchSandboxRepayments,
  Tp as patchTemplates,
  Ti as postAccountingInfo,
  qa as postAmazonRedirectUrl,
  A_ as postAuthEmailOtpVerify,
  K_ as postAuthLoginStart,
  O_ as postAuthLoginValidateOtp,
  R_ as postAuthManagementUseridSaml,
  b_ as postAuthTokenExchange,
  Xt as postAuthTokens,
  Ie as postBeneficialOwners,
  lm as postBulkOptIn,
  _a as postBusiness,
  O as postBusinessAnnotations,
  Ta as postBusinessAuthLink,
  m as postBusinessCores,
  gn as postBusinessOfficers,
  fc as postBusinessesNotifications,
  vr as postCapitalProductApplication,
  Ka as postCapitalProductBusiness,
  Gr as postCapitalProductNetTopUp,
  xr as postCapitalProductOfferCollection,
  Bi as postCardCloseAccount,
  Aa as postCards,
  k as postCashAdvanceChargeOffs,
  S as postCashAdvancePauses,
  Pn as postDataingestionOneschemaGenerateJwt,
  Tn as postDataingestionS3DatasetIntegrations,
  Wn as postDataingestionS3OrgDatasetIntegrations,
  Zn as postDataingestionS3RunDatasetIngestion,
  ar as postDataingestionStripeIngestions,
  Pi as postDebts,
  ki as postDebtsCheckComplete,
  Wa as postDocumentUploadGroup,
  Ai as postFinancialReviews,
  r_ as postForgiveCapitalFee,
  Ns as postFundingAccounts,
  ue as postHackathonAwaitParafinRepresentative,
  te as postHackathonCreatePilot,
  ae as postHackathonGenerateOffers,
  de as postHackathonGmv,
  le as postHackathonMapOffersToEmails,
  jt as postHackathonPartners,
  _e as postHackathonReset,
  ce as postHackathonSendOffers,
  qe as postHackathonUploadSalesCsv,
  Ys as postKycRetry,
  e_ as postManualRepayment,
  Zd as postMarkAccountingIntegrationSeen,
  Fe as postMarketingIntegrations,
  Pe as postMarketingReview,
  Do as postMerchantBankAccounts,
  lp as postMerchantBankApplications,
  la as postMerchantBankBusiness,
  Ru as postMerchantBankCardActivate,
  vu as postMerchantBankCardApplePay,
  Yu as postMerchantBankCardDisputeChargebackCredit,
  zu as postMerchantBankCardDisputeStateTransition,
  Hu as postMerchantBankCardDisputes,
  Iu as postMerchantBankCardGooglePay,
  Gu as postMerchantBankCardStateTransition,
  Ku as postMerchantBankCards,
  oi as postMerchantBankExternalAccounts,
  li as postMerchantBankExternalBankTransfers,
  No as postMerchantBankInnerTransfers,
  p_ as postMerchantBankOffers,
  mu as postMerchantBankRecipientTransfers,
  ru as postMerchantBankRecipients,
  pu as postMerchantBankRecipientsIdPaymentRails,
  xp as postMfaOtpSendPersonidPhonenumbertype,
  Fp as postMfaOtpVerifyPersonidPhonenumbertype,
  a_ as postMfaVerifyAccountDetails,
  Vi as postOfficerSubsidiaryRecords,
  io as postOptIn,
  _m as postOptIns,
  hm as postOptOut,
  Wt as postPartnerSyncWebhooks,
  xt as postPartnerUsers,
  z as postPartners,
  ja as postRestriction,
  Yd as postRutterConnections,
  Sm as postSandboxAmazonLockBankAccount,
  Am as postSandboxBreakBankAccount,
  Cm as postSandboxCapitalProductPayOff,
  Im as postSandboxMerchantBankSetupBusiness,
  Um as postSandboxRepayments,
  np as postSardineSessionId,
  Ba as postSetupAmazonTopUp,
  lt as postSlackAuthorize,
  Oa as postSpd,
  wi as postTaxRecords,
  Jp as postTemplateTypes,
  Np as postTemplates,
  hc as postTermsOfServiceMarkShown,
  qs as postTriggerRunCelticsChecklist,
  qp as postWebhooksPlaidGeneral,
  jk as publicApiPaths,
  Yn as putDataingestionS3DatasetIngestionConfigs,
  Pm as putSandboxCapitalProductTestOffer,
  Qm as putSandboxFeeDiscount,
  qd as resendCrmAttempt,
  ud as resendWebhookAttempt,
  ic as sendMarketingEmails,
  Ss as updateBankAccountInfo,
  Sy as updatePdPartnerEventUpdateUserSubscription,
  Ay as updatePdPartnerEventUpdateUserSubscriptionsForEventType,
  Ud as updatePdWebhookEndpoint,
  Hk as useAmazonRPCPreLogin,
  Yk as useAttemptAmazonBankVerification,
  ob as useAttemptSync,
  wP as useBatchCreatePdPartnerEventMarkAllNotificationsRead,
  eb as useBatchResendWebhookAttempt,
  ih as useBenchmarkGetCapitalProductOwnership,
  BP as useCountPdBusinessTableRows,
  vP as useCountPdCapitalProductApplicationRow,
  DP as useCountPdCapitalProductOfferRow,
  EP as useCountPdCapitalProductRow,
  Uk as useCreateAttemptToUnderwriteBnplOrder,
  sk as useCreateAttemptToUnderwriteIntroductoryOffers,
  bh as useCreateBankAccountInfo,
  Nk as useCreateBeneficialOwner,
  ph as useCreateCapitalProductIntents,
  gh as useCreateCapitalProductSuperApplicationActions,
  lh as useCreateCapitalProductSuperApplications,
  ZO as useCreateCapitalProducts,
  yh as useCreateOfferedCapitalProductFeeDiscount,
  Df as useCreateOrganization,
  vf as useCreatePartnerApiKey,
  QP as useCreatePdPartnerEventMarkNotificationRead,
  yb as useCreatePdWebhookEndpoint,
  Rh as useCreatePlaidInvestigation,
  Xk as useCreatePlaidLinkage,
  _P as useCreateS3OfferCsv,
  lb as useCreateS3PartnerDashboardTableCsv,
  Wk as useCreateUnderwritingAttempt,
  Qk as useCreateUnverifiedAmazonFundingAccount,
  wk as useCreateUnverifiedFundingAccount,
  $k as useCreateUnverifiedFundingAccountFromPlaidLinkage,
  Zk as useCreateZendeskTicket,
  tk as useDeleteAuthManagementUseridSamlSamlconfigid,
  Tk as useDeleteBeneficialOwner,
  OO as useDeleteBeneficialOwnersId,
  CO as useDeleteBusinessOfficersId,
  kf as useDeleteCashAdvancePausesP1,
  mO as useDeleteMarketingIntegrationsVendor,
  $K as useDeleteMerchantBankExternalAccountsId,
  kK as useDeleteMerchantBankRecipientsId,
  Hf as useDeleteOrganizationDeleteUsers,
  Wf as useDeletePartnerDeleteUsers,
  Zf as useDeletePartnerSyncWebhooksId,
  db as useDeletePdWebhookEndpoint,
  If as useDeleteSlackChannelsId,
  zk as useDisputeTransaction,
  YP as useExperimentalCapitalProductOfferClosedSandbox,
  $P as useExperimentalCapitalProductOfferCreatedSandbox,
  aP as useGetAccountingInfo,
  Fb as useGetAmazonFundingAccountLock,
  yP as useGetAnalyticsCubeToken,
  jb as useGetAuthManagementP1Saml,
  Ph as useGetBankAccountInfos,
  bO as useGetBanks,
  qO as useGetBeneficialOwners,
  qf as useGetBusinessAnnotations,
  lf as useGetBusinessCores,
  Ah as useGetBusinessIdentities,
  RO as useGetBusinessOfficers,
  FO as useGetBusinessTableRowsCard,
  MO as useGetBusinessTableRowsLoan,
  xO as useGetBusinessTableRowsMca,
  kO as useGetBusinessTableRowsProductAgnostic,
  oK as useGetBusinessesDailySalesRecords,
  fP as useGetBusinessesNotifications,
  Yh as useGetBusinessesProducts,
  Ck as useGetBusinessesShowTopUpOffer,
  eh as useGetCapitalProductActivities,
  sh as useGetCapitalProductApplicationSnapshots,
  nh as useGetCapitalProductApplications,
  dh as useGetCapitalProductIntents,
  oh as useGetCapitalProductNetTopUpItemization,
  JO as useGetCapitalProductOfferCollection,
  YO as useGetCapitalProductProspectiveTermsOfService,
  ch as useGetCapitalProductRepaymentSchedule,
  uh as useGetCapitalProductStateDisclosure,
  XO as useGetCapitalProducts,
  ik as useGetCardAccessToken,
  ak as useGetCardBalances,
  ck as useGetCardLifecycleStates,
  dk as useGetCardPendingTransactions,
  yk as useGetCardTransactions,
  ok as useGetCardholders,
  zh as useGetCards,
  Of as useGetCashAdvanceChargeOffs,
  Pf as useGetCashAdvancePauses,
  mh as useGetCounteroffer,
  wO as useGetDataingestionS3DatasetIngestionConfigs,
  NO as useGetDataingestionS3DatasetIntegrations,
  UO as useGetDataingestionS3DatasetUploadValidationResults,
  DO as useGetDataingestionS3DatasetUploads,
  TO as useGetDataingestionS3OrgDatasetIntegrations,
  HO as useGetDataingestionStripeIngestions,
  eP as useGetDebtChecks,
  jK as useGetDebts,
  Zh as useGetDocumentUploadGroup,
  Jh as useGetDocumentUploadList,
  vk as useGetEarlyManualRepaymentWhitelist,
  rP as useGetFinancialReviews,
  qh as useGetFlexLoanMinimumRepaymentSchedule,
  xh as useGetFundingAccounts,
  Ek as useGetFundingPlaidLinkToken,
  Ff as useGetFutureActivities,
  nO as useGetHackathonGetPilot,
  Ch as useGetKycRetryFields,
  rk as useGetLatestAttemptToUnderwriteIntroductoryOffers,
  rK as useGetLiens,
  lO as useGetMarketingGtmToolkit,
  pO as useGetMarketingIntegrations,
  gO as useGetMarketingParagonToken,
  _K as useGetMerchantBankAccounts,
  dK as useGetMerchantBankAccountsId,
  pK as useGetMerchantBankAccountsIdBalances,
  kb as useGetMerchantBankApplications,
  bb as useGetMerchantBankApplicationsId,
  AK as useGetMerchantBankCardAccessToken,
  UK as useGetMerchantBankCardCardholderAccessToken,
  SK as useGetMerchantBankCardCardholders,
  TK as useGetMerchantBankCardDisputes,
  BK as useGetMerchantBankCards,
  HK as useGetMerchantBankExternalAccounts,
  zK as useGetMerchantBankExternalAccountsId,
  WK as useGetMerchantBankExternalAccountsIdPlaidLinkToken,
  LK as useGetMerchantBankExternalAccountsPlaidLinkToken,
  hK as useGetMerchantBankInterestDetails,
  Hb as useGetMerchantBankOffers,
  KK as useGetMerchantBankRecipients,
  bK as useGetMerchantBankRecipientsId,
  qK as useGetMerchantBankTransactions,
  gK as useGetMerchantBankTransactionsId,
  fK as useGetMerchantBankTransferFees,
  OK as useGetMerchantBankTransferLimits,
  Rb as useGetNaics,
  iP as useGetOfficerSubsidiaryRecords,
  $O as useGetOnboardingBusinessRequirements,
  vh as useGetOnboardingInfoNeeded,
  Sh as useGetOnboardingStates,
  _k as useGetOptIns,
  gk as useGetOptOutEligibility,
  pk as useGetOrder,
  Nf as useGetOrganization,
  zP as useGetOwnerAuth,
  KO as useGetOwnerIsBeneficialOwner,
  Uh as useGetOwners,
  Eb as useGetPADAgreement,
  uK as useGetParafinder,
  iK as useGetParafinderBusinessTypes,
  yK as useGetParafinderCapitalInfo,
  cK as useGetParafinderSummary,
  Sf as useGetPartnerApiKey,
  zf as useGetPartnerConfigs,
  Vf as useGetPartnerPublicConfigs,
  Yf as useGetPartnerSyncWebhooks,
  wf as useGetPartnerUsers,
  Kb as useGetPartnerWidgetConfigs,
  Rf as useGetPartners,
  Kf as useGetPartnersDoordashAdminUsers,
  Cf as useGetPartnersUnderwriting,
  GP as useGetPdOfferUrl,
  VP as useGetPdUtilityInfo,
  Fh as useGetPlaidInvestigations,
  Mf as useGetRepaymentPlanParameters,
  Bk as useGetRepayments,
  tK as useGetRestriction,
  nK as useGetRestrictionsDetails,
  Oh as useGetRunOfacScreeningResults,
  gb as useGetRutterConnections,
  _b as useGetS3PartnerDashboardTableCsv,
  Ob as useGetSardineSessionId,
  Tf as useGetSlackChannels,
  Sk as useGetStatements,
  yO as useGetStatementsV2,
  ub as useGetSync,
  ib as useGetSyncAndMaybeMarkFailed,
  Ub as useGetTemplateMock,
  Gb as useGetTemplateSnapshot,
  Nb as useGetTemplateSnapshots,
  Tb as useGetTemplateTypes,
  vb as useGetTemplates,
  Kh as useGetTermLoanProspectiveRepayments,
  Dk as useGetUnderwritingStatusForOrder,
  Uf as useLinkPartner,
  dP as useListApiRequests,
  vO as useListDatasetUploads,
  AO as useListManualDatasetUploadValidationResults,
  Af as useListOrganizations,
  gP as useListPartnerScheduledMarketingEmailConfigs,
  KP as useListPdBusinessDetailsApplications,
  PP as useListPdBusinessDetailsBankInfo,
  bP as useListPdBusinessDetailsBusinessInfo,
  xP as useListPdBusinessDetailsFundedProductDetails,
  MP as useListPdBusinessDetailsFundedProductPaymentProgress,
  kP as useListPdBusinessDetailsFundedProducts,
  FP as useListPdBusinessDetailsOffers,
  RP as useListPdBusinessDetailsOwnerInfo,
  CP as useListPdBusinessTableRows,
  AP as useListPdCapitalProductApplicationRows,
  UP as useListPdCapitalProductOfferRows,
  SP as useListPdCapitalProductRows,
  rb as useListPdCrmDeliveryAttempts,
  ab as useListPdCrmEventInfo,
  nb as useListPdCrmEvents,
  HP as useListPdCrmIntegrations,
  TP as useListPdPartnerEventListUserSubscriptions,
  IP as useListPdPartnerEventListWebNotifications,
  NP as useListPdSupportInfo,
  cb as useListPdWebhookEndpoints,
  jP as useListPdWebhookLogsDeliveryAttempts,
  ZP as useListPdWebhookLogsEventInfo,
  XP as useListPdWebhookLogsEvents,
  pP as useListS3OfferCsvs,
  mb as useListS3PartnerDashboardTableCsvs,
  zb as useListSardineDetails,
  mP as useListSendMarketingEmailBatches,
  WO as useListVersionedSchemas,
  Sb as useMarkPADAgreementSigned,
  JP as useMultiWebhookPdWebhookLogsDeliveryAttempts,
  qP as usePartnerScheduledMarketingEmailConfig,
  ek as usePatchAuthManagementUseridSamlSamlconfigid,
  Gk as usePatchBeneficialOwner,
  hO as usePatchBeneficialOwnersId,
  Dh as usePatchBusinessIdentitiesId,
  EO as usePatchBusinessOfficersId,
  _h as usePatchCapitalProductIntents,
  jO as usePatchCapitalProducts,
  uk as usePatchCardholdersCardholderid,
  xf as usePatchCashAdvancePausesP1,
  hh as usePatchClearWatchlistHitsId,
  zO as usePatchDataingestionStripeIngestions,
  Lh as usePatchFlexLoanAge,
  Hh as usePatchFlexLoanFund,
  Wh as usePatchFlexLoanOwnership,
  sK as usePatchLiensId,
  EK as usePatchMerchantBankCardCardholdersId,
  YK as usePatchMerchantBankExternalAccountsId,
  ZK as usePatchMerchantBankExternalBankTransfersId,
  $b as usePatchMerchantBankMerchantConfig,
  RK as usePatchMerchantBankRecipientTransfersId,
  xK as usePatchMerchantBankRecipientsId,
  Wb as usePatchMfaExtendSession,
  PO as usePatchOwnerIsBeneficialOwner,
  Nh as usePatchOwnersId,
  $f as usePatchPartnerConfigsId,
  jf as usePatchPartnerSyncWebhooksId,
  Lf as usePatchPartnerUsers,
  Pb as usePatchPartnerWidgetConfigs,
  Ef as usePatchPartnersId,
  Bh as usePatchPersonaFallbackInquiry,
  Ak as usePatchRoot,
  Ok as usePatchSandboxCapitalProductFund,
  hk as usePatchSandboxCapitalProductFundingChecklist,
  Kk as usePatchSandboxCapitalProductTopUp,
  Mk as usePatchSandboxRepayments,
  Db as usePatchTemplates,
  oP as usePostAccountingInfo,
  Ih as usePostAmazonRedirectUrl,
  nk as usePostAuthEmailOtpVerify,
  Xb as usePostAuthLoginStart,
  Yb as usePostAuthLoginValidateOtp,
  Jb as usePostAuthManagementUseridSaml,
  Zb as usePostAuthTokenExchange,
  Jf as usePostAuthTokens,
  fO as usePostBeneficialOwners,
  lk as usePostBulkOptIn,
  Gh as usePostBusiness,
  ff as usePostBusinessAnnotations,
  Xh as usePostBusinessAuthLink,
  gf as usePostBusinessCores,
  BO as usePostBusinessOfficers,
  OP as usePostBusinessesNotifications,
  rh as usePostCapitalProductApplication,
  Qh as usePostCapitalProductBusiness,
  ah as usePostCapitalProductNetTopUp,
  th as usePostCapitalProductOfferCollection,
  nP as usePostCardCloseAccount,
  $h as usePostCards,
  hf as usePostCashAdvanceChargeOffs,
  bf as usePostCashAdvancePauses,
  SO as usePostDataingestionOneschemaGenerateJwt,
  GO as usePostDataingestionS3DatasetIntegrations,
  IO as usePostDataingestionS3OrgDatasetIntegrations,
  LO as usePostDataingestionS3RunDatasetIngestion,
  VO as usePostDataingestionStripeIngestions,
  JK as usePostDebts,
  tP as usePostDebtsCheckComplete,
  jh as usePostDocumentUploadGroup,
  sP as usePostFinancialReviews,
  Qb as usePostForgiveCapitalFee,
  Mh as usePostFundingAccounts,
  sO as usePostHackathonAwaitParafinRepresentative,
  eO as usePostHackathonCreatePilot,
  rO as usePostHackathonGenerateOffers,
  oO as usePostHackathonGmv,
  iO as usePostHackathonMapOffersToEmails,
  tO as usePostHackathonPartners,
  uO as usePostHackathonReset,
  aO as usePostHackathonSendOffers,
  cO as usePostHackathonUploadSalesCsv,
  Eh as usePostKycRetry,
  wb as usePostManualRepayment,
  fb as usePostMarkAccountingIntegrationSeen,
  _O as usePostMarketingIntegrations,
  dO as usePostMarketingReview,
  mK as usePostMerchantBankAccounts,
  xb as usePostMerchantBankApplications,
  Th as usePostMerchantBankBusiness,
  vK as usePostMerchantBankCardActivate,
  DK as usePostMerchantBankCardApplePay,
  QK as usePostMerchantBankCardDisputeChargebackCredit,
  wK as usePostMerchantBankCardDisputeStateTransition,
  IK as usePostMerchantBankCardDisputes,
  GK as usePostMerchantBankCardGooglePay,
  NK as usePostMerchantBankCardStateTransition,
  CK as usePostMerchantBankCards,
  VK as usePostMerchantBankExternalAccounts,
  XK as usePostMerchantBankExternalBankTransfers,
  lK as usePostMerchantBankInnerTransfers,
  Vb as usePostMerchantBankOffers,
  FK as usePostMerchantBankRecipientTransfers,
  PK as usePostMerchantBankRecipients,
  MK as usePostMerchantBankRecipientsIdPaymentRails,
  Bb as usePostMfaOtpSendPersonidPhonenumbertype,
  Cb as usePostMfaOtpVerifyPersonidPhonenumbertype,
  Lb as usePostMfaVerifyAccountDetails,
  cP as usePostOfficerSubsidiaryRecords,
  aK as usePostOptIn,
  mk as usePostOptIns,
  qk as usePostOptOut,
  Xf as usePostPartnerSyncWebhooks,
  Qf as usePostPartnerUsers,
  Bf as usePostPartners,
  eK as usePostRestriction,
  qb as usePostRutterConnections,
  bk as usePostSandboxAmazonLockBankAccount,
  kk as usePostSandboxBreakBankAccount,
  Pk as usePostSandboxCapitalProductPayOff,
  Fk as usePostSandboxMerchantBankSetupBusiness,
  xk as usePostSandboxRepayments,
  hb as usePostSardineSessionId,
  Vh as usePostSetupAmazonTopUp,
  Gf as usePostSlackAuthorize,
  wh as usePostSpd,
  uP as usePostTaxRecords,
  Ib as usePostTemplateTypes,
  Ab as usePostTemplates,
  hP as usePostTermsOfServiceMarkShown,
  fh as usePostTriggerRunCelticsChecklist,
  Mb as usePostWebhooksPlaidGeneral,
  QO as usePutDataingestionS3DatasetIngestionConfigs,
  fk as usePutSandboxCapitalProductTestOffer,
  Rk as usePutSandboxFeeDiscount,
  sb as useResendCrmAttempt,
  tb as useResendWebhookAttempt,
  lP as useSendMarketingEmails,
  Ik as useSubmitKYC,
  kh as useUpdateBankAccountInfo,
  Vk as useUpdateBoostCompletion,
  LP as useUpdatePdPartnerEventUpdateUserSubscription,
  WP as useUpdatePdPartnerEventUpdateUserSubscriptionsForEventType,
  pb as useUpdatePdWebhookEndpoint,
  Lk as useUploadBankStatements
};
