import { ReactNode } from 'react'
import styled from 'styled-components'
import useTheme from '@/src/ui/theme'
import { Typography } from '../../ui/components/Typography'

type BadgeSize = 'normal' | 'small'

type BadgeProps = {
  variant: 'green' | 'yellow' | 'red' | 'gray'
  disabled?: boolean
  size?: BadgeSize
  children: ReactNode
}

export const Badge = ({
  variant,
  disabled,
  children,
  size = 'normal',
}: BadgeProps) => {
  const { theme } = useTheme()

  const getBackgroundColor = () => {
    if (variant === 'green') return theme.colors.accent200
    if (variant === 'yellow') return theme.colors.accent700
    if (variant === 'red') return theme.colors.negative100
    return theme.colors.mono200
  }

  const getTextColor = () => {
    if (variant === 'green') return theme.colors.accent100
    if (variant === 'yellow') return theme.colors.accent600
    if (variant === 'red') return theme.colors.accent500
    return theme.colors.black
  }

  return (
    <BadgeWrapper
      backgroundColor={getBackgroundColor()}
      textColor={getTextColor()}
      size={size}
      disabled={disabled}
    >
      {children}
    </BadgeWrapper>
  )
}

type BadgeWrapperProps = {
  backgroundColor: string
  textColor: string
  size: BadgeSize
  disabled?: boolean
}

const BadgeWrapper = styled(Typography)<BadgeWrapperProps>`
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 8px;
  padding: 4px 12px;
  padding: ${({ size }) => (size === 'small' ? '2px 8px' : '4px 12px')};
  ${({ disabled }) => disabled && `opacity: 0.5;`}
`
